import Deadline from 'Components/UtilityComponents/Deadline/Deadline';
import React, { useState } from 'react';
import closeIcon from 'Assets/Icons/close_square.svg'
import OpenButtons from '../OpenButtons/OpenButtons';

import styles from './InstructionsModal.module.scss';
import Instructions from '../OpenContent/Instructions/Instructions';
import EducatorComments from '../OpenContent/EducatorComments/EducatorComments';
import BreakoutRooms from '../OpenContent/BreakoutRooms/BreakoutRooms';
import GrammarBot from '../OpenContent/GrammarBot/GrammarBot';

const InstructionsModal = ({ synod, closeModal, activeSection, setActiveSection, activity, setActivity, commentsHaveCascaded, inputHTML, initRadval }) => {

  const [ radVal, setRadVal ] = useState(initRadval)

  return (
    <div className={styles.container}>
      <img className={styles.close} onClick={closeModal} src={closeIcon}   alt="close" />

      {
        !synod ?
          <div className={styles.topBar}>
            <Deadline open={true} endDate={activeSection.endDate == "open" ? "open" : new Date(activeSection.endDate)} />
            <div className={styles.buttonsDiv}>
              <OpenButtons
                noBorder
                isModal
                activeSection={activeSection}
                setRadVal={setRadVal}
                open={true}
                radVal={radVal}
                inputName={"modalOption"} />
            </div>
          </div>

        :
          <h4 className={styles.synodHeading}>Instructions</h4>

      }

      <div className={styles.mainSwitch}>
        {
          radVal === "instructions" ?
            <Instructions synod={synod} isModal activeSection={activeSection} inputHTML={activeSection.entryHTML} />
          
          : radVal === "comments" ? 
            <EducatorComments
              activityId={activity.activityId}
              activity={activeSection}
              setActivity={setActivity}
              comments={activeSection.comments}
              activeSection={activeSection}
              commentsHaveCascaded={commentsHaveCascaded}
              setCommentsHaveCascaded={()=>console.log('')} />
          
          : radVal === "friend" && activeSection.breakoutRooms ? 
            <BreakoutRooms  
              breakoutRooms={activeSection.breakoutRooms}
              activity={activity}
              setActivity={setActivity} 
              setActiveSection={setActiveSection}
              activeSection={activeSection} />
          
          : radVal === "grammar" &&  activeSection.grammarReport?.report?.length ? 
            <GrammarBot reportData={activeSection.grammarReport} />

          : null
        }
      </div>
    </div>
  )

}

export default InstructionsModal;