import React,   { useState } from 'react';
import { sendToApi } from 'API/API.service';
import ItemRadio from '../ItemRadio';

import styles from './VariantSelector.module.scss';
import boomer from 'Assets/Boomers/boomer_boy_blue.png'
import coins from 'Assets/Icons/coins.svg'
import tick from 'Assets/Icons/tick_green_circle.svg'
import sadBoomer from 'Assets/Boomers/sadboomer.png'
import face from 'Assets/Avatars/face_shocked.svg'
import close from 'Assets/Icons/close_square.svg'
import store from 'storeConfig';
import { updatePostPurchase } from 'Actions/dashDataActions';
import { useEffect } from 'react';

const VariantSelector = ({ item, setVariants, returnFileName, localConfig, setLocalConfig, avatarItems, itemFilter, changeItem, purchase, boomerBucks, storeData, setStoreData, setFallback }) => {

  const [ files, setFiles ] = useState(item.url.length)
  const [ postPurchase, setPostPurchase ] = useState(null)


  const handleClick = (type, item, tile) => {
    console.log(item)
    changeItem(type, item, tile)
  }

  const handlePurchase = async () => {

    if(item.price<=boomerBucks){

      setPostPurchase('success') //Component UI state change
      setFallback(null) //delete fallback data so 'save' button shows

      //add redux key to stop reversion to initial state in profile.jsx useEffect upon updating dashData state
      store.dispatch(updatePostPurchase(true))
      setTimeout(()=>{store.dispatch(updatePostPurchase(false))},2500) //revert to false after 2.5s once it has served its purpose

      //call API, update storeData in profile.jsx
      const data = await sendToApi(`purchase_item?xmlId=${item.xmlId}&type=${item.type}`)
      setStoreData({...storeData, boomerBucks:boomerBucks-item.price, ownedAvatarItems: data})

    } else {
      setPostPurchase('failure')
      
    }
  }

  useEffect(()=>{

    const scrollContainer = document.getElementById("edit_container")
    if(purchase&&scrollContainer){
      scrollContainer.scrollTop = 100
    }
    
  },[purchase])

  return !files ? null : (
    <div className={`${styles.variantSelector} ${postPurchase && styles.postPurchase}`}>

      <img src={close} className={styles.close} alt="close" onClick={()=>setVariants(null)} />

     {!purchase &&
       <div className={styles.top}>
          {/* <img className={styles.boomer} src={boomer} alt="boomer" /> */}
          <h2>Pick your favorite</h2>
       </div>
      }

      {
        !postPurchase ? //regular view

          <>

            {
              purchase &&
                <>
                  <div className={styles.purchaseText}>
                    <h4>Are you sure you want to purchase this collection for  <span><img src={coins} /></span> {item.price}?</h4>
                  </div>
                  <div className={styles.buttons}>
                    <div onClick={() => setVariants(null)} className={styles.cancelBtn}>
                      Cancel
                    </div>
                    <div onClick={handlePurchase} className={styles.confirmBtn}>
                      Purchase Now
                    </div>
                  </div>
                </>
            }

            {
              [...Array(files)].map((file, idx) =>
                <ItemRadio
                  variantSelector
                  // noClick={purchase}
                  noPrice
                  tile={idx+1}
                  localConfig={localConfig}
                  count={avatarItems?.filter(item => item.type === itemFilter.value).length}
                  returnFileName={returnFileName}
                  idx={idx}
                  selected={false}
                  itemName={`${itemFilter.value}Radio`}
                  clickHandler={() => { handleClick(item.type, item, idx + 1) }}
                  item={item} />
              )
            }



          </>

        : //view after clicking 'purchase now'

            <div className={`${styles.postPurchase} ${postPurchase === 'failure' && styles.failure}`}>

              {

                postPurchase === 'failure' ?
                  
                  <>

                    <img className={styles.face} src={face} alt="boomer" />
                    <h1>Oh no!</h1>
                    <h3>You don't have enough coins fot this item!</h3>

                    <div onClick={()=>setVariants(null)} className={styles.okBtn}>Ok</div>

                  </>

                :

                  <>
                    <h3>Collection purchased!</h3>
                    <img className={styles.tick} src={tick} alt="tick" />

                  </>
              }

            </div>
        
            
          

      }

    </div>
  )

}

export default VariantSelector;