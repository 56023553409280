import React, { useEffect, useRef, useState } from 'react';
import { updateModalContent } from 'Actions/modalContentActions';
import CarouselModal from 'Components/UtilityComponents/CarouselModal/CarouselModal';
import store from 'storeConfig';

import styles from './Instructions.module.scss';

import imagesIcon from 'Assets/Icons/images.svg'
import filesIcon from 'Assets/Icons/file_blue.svg'
import paperclip from 'Assets/Icons/paperclip.svg'
import ReactQuill from 'react-quill';
import { openInNewTab } from 'Utils/UI_elements/openInNEwTab';
import WordbankItem from './WordbankItem';

import InlineHtml from 'Components/UtilityComponents/InlineHtml/InlineHtml';
import InlinePdfs from 'Components/UtilityComponents/InlinePdfs/InlinePdfs'

const Instructions = ({ isModal, activeSection, activeSection: { instructions, files, images, pdfs, wordBank }, mobile }) => {

  const contRef = useRef()
  const handleImg = (idx) => {
    store.dispatch(updateModalContent({ vis: true, component: <CarouselModal images={images} idx={idx} /> }))
  }

  useEffect(() => {
    setTimeout(() => { contRef.current?.classList.add(styles.display) }, 100)
  }, [])

  return (
    <div ref={contRef} className={`${styles.container} ${isModal && styles.isModal} ${mobile && styles.mobile}`}>
      
      <InlineHtml rawHtml={instructions} />
      {pdfs?.length > 0 && <InlinePdfs key={"attached_pdfs"} files={pdfs} />}
      
      <div className={styles.bottom}>
        {
          wordBank && wordBank.length > 0 &&
          <div className={styles.wordBankWrapper}>
            <div className={styles.titleRow}>
              <div className={styles.icon} />
              <h5>Word bank</h5>
              <div className={styles.tip}>
                <div className={styles.tooltip}>
                  Required words to be used on your assignment.
                </div>
              </div>
            </div>
            <div className={styles.words}>
              {wordBank.map((i, idx) =>
                <WordbankItem key={idx} word={i} inputHTML={activeSection.entryHTML} />
              )}
            </div>
          </div>
        }
        
        {
          images && images.length > 0 && 
          <div className={styles.imagesOuterWrapper}>
          <div className={styles.sectionTitle}>
            <img src={imagesIcon} alt="images" />
            <h5>Images</h5>
          </div>
          <div className={styles.imagesWrapper}>
            {
              images.map((image, idx) =>
                <div onClick={() => handleImg(idx)} key={idx} className={styles.imgDiv}>
                  <img src={image.fileUrl} alt={image.fileName} />
                </div>)
            }
          </div>
        </div>
        }

        {
          files && files.length > 0 && 
          <div className={styles.filesOuterWrapper}>
            <div className={styles.sectionTitle}>
              <img className={styles.file} src={filesIcon} alt="images" />
              <h5>Files</h5>
            </div>

            <div className={styles.filesWrapper}>
              {
                files?.map((file, idx) =>
                  <div onClick={() => openInNewTab(file.fileUrl)} className={styles.fileDiv}>
                    <p>{file.fileName}</p>
                    <div className={styles.download}></div>
                  </div>
                )
              }
            </div>
          </div>
        }
      </div>

    </div>
  )

}

export default Instructions;