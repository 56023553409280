import img1 from 'Assets/Icons/book_icon_blue.png'
import img2 from 'Assets/Icons/book_icon_green.png'
import img3 from 'Assets/Icons/book_icon_orange.png'
import img4 from 'Assets/Icons/book_icon_pink.png'

const icons = [ img1, img2, img3, img4 ];

const returnSectionIcon = (iconKey) => {
  const idx = iconKey % 4
  return icons[idx]
}

export default returnSectionIcon