import React from 'react';
import DOMPurify from 'dompurify';

import styles from './VotingEntry.module.scss';
import tick from 'Assets/Icons/tick_green_square.svg'
import { sendToApi } from 'API/API.service';
import store from 'storeConfig';
import { updateBoomerBucks } from 'Actions/dashDataActions';

const VotingEntry = ({ activeSection, setActiveSection, entryHTML, idx, read, showVotingModal, readingProgress, updateSectionKey, mobile, entryId, sectionIndex, activityId, toggleConf, addNextSet }) => {

  const handleVote = async () => {
    toggleConf(true)
    updateSectionKey("votingData", true, "voted")
    const res= await sendToApi(`activity/${activityId}/${sectionIndex}/vote/${entryId}`)
    store.dispatch(updateBoomerBucks(2))

    // if(res?.entries?.length&&!roundTwo){
      addNextSet(res)
    // }else{
    //   // updateSectionKey('nextSet', false, null, true);
    //   setActiveSection({...activeSection, nextSet: false, votingData:{...activeSection.votingData, entries:[]}})
    // }

  }
  return (
    <div className={`${styles.container} ${mobile&&styles.mobile}`}>

      <div className={styles.header}>
        <h4>Entry {idx+1}</h4>
        {read && <img src={tick} alt="tick" /> }
      </div>
      
      <div
        className={`${styles.entryText}`}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(entryHTML) }} /> 

      <div className={styles.buttons}>
        <div onClick={showVotingModal} className={`${styles.readBtn}`}>Read</div>
        <div onClick={handleVote} className={`${styles.voteBtn} ${readingProgress!==100 && styles.disabled}`}>Vote</div>
      </div>

    </div>
  )

}

export default VotingEntry;