import React, { useEffect, useRef } from 'react';
import ActivityRadio from 'Components/ClassDetails/ActivityRadio/ActivityRadio';
import { useSwipeable } from 'react-swipeable'

import styles from './ListViewModal.module.scss';

const ListViewModal = ({ closeModal, activities, selectedActivity, toggleActivity }) => {


  useEffect(()=>{
    setTimeout(()=>{contRef.current.classList.add(styles.show)},100)
  },[])

  const contRef = useRef()
  
  const handleSwipe = () => {
    contRef.current?.classList.remove(styles.show)
    setTimeout(()=>{closeModal()},300)
  }
  
  const handleToggle = (idx) => {
    toggleActivity(idx)
    handleSwipe()
  }

    const handlers = useSwipeable({
      onSwipedDown: () => handleSwipe(),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true
    });

  return (
    <div ref={contRef} className={styles.container}>

      <div className={styles.slideHandle} />

      <div className={styles.radios}>
        {
          activities.map((activity, idx) => 
            <ActivityRadio
              modal
              selectedActivity={selectedActivity}
              key={idx}
              toggleActivity={()=>handleToggle(idx)}
              activity={activity}
              idx={idx}/>
          )
        }
      </div>

      <div  {...handlers} className={styles.swipeCatcher} />
    </div>
  )

}

export default ListViewModal;