const appTitle = (state= 'loading', action) => {

  const { type, payload } = action

  switch(type){
    case 'SET_APP_TITLE': 
      console.log('payload')
      console.log(payload)
      return payload
    default:
        return state
  }
}

export default appTitle;