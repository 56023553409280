import React from 'react';
import logo from 'Assets/Logos/escribli_logo_white.svg'
import logout from 'Assets/Icons/logout_door_white.svg'
import chevron from 'Assets/Icons/chevron_white.svg'
import styles from './NavSynod.module.scss';
import { Link, NavLink } from 'react-router-dom';

const NavSynod = ({ dash }) => {

  return (
    <div className={styles.container}>
      {dash ? 
        <img src={logo} alt="Escribli Logo" /> 
      : 
        <Link to={"/dashboard"} className={styles.back}>
          <img src={chevron} alt="back" />
          <h5>Go back</h5>
        </Link>
      }
      <h4>St. Francis Church - Synod 2021</h4>
      <div className={styles.logoutBtn}>
        <img src={logout} alt="logout" />
        <NavLink to={"/logout"}>Logout</NavLink>
      </div>
    </div>
  )

}

export default NavSynod;