export const incomingMessage = ( message ) => {
  return{
    type: 'INCOMING_MESSAGE',
    payload: message
  }
}

export const incrementUnread = ( ) => {
  return{
    type: 'UNREAD_MESSAGE'
  }
}

export const setUnread = (count) => {
  return{
    type: 'SET_UNREAD',
    payload:count
  }
}