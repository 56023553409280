import React, { useEffect, useState } from 'react';
import Items from '../Items/Items';
import { Prompt } from 'react-router'

import styles from './EditBoomer.module.scss';

import boomerIcon from 'Assets/Icons/radioBoomer.svg'
import hairIcon from 'Assets/Icons/radioHair.svg'
import topIcon from 'Assets/Icons/radioTop.svg'
import bottomIcon from 'Assets/Icons/radioBottom.svg'
import petIcon from 'Assets/Icons/radioPet.svg'
import accessoryIcon from 'Assets/Icons/radioAccessory.svg'
import collectionsIcon from 'Assets/Icons/radioCollections.svg'
import saintIcon from 'Assets/Icons/radioSaint.svg'
import boomerIcon_selected from 'Assets/Icons/radioBoomer_selected.svg'
import hairIcon_selected from 'Assets/Icons/radioHair_selected.svg'
import topIcon_selected from 'Assets/Icons/radioTop_selected.svg'
import bottomIcon_selected from 'Assets/Icons/radioBottom_selected.svg'
import petIcon_selected from 'Assets/Icons/radioPet_selected.svg'
import accessoryIcon_selected from 'Assets/Icons/radioAccessory_selected.svg'
import collectionsIcon_selected from 'Assets/Icons/radioCollections_selected.svg'
import saintsIcon_selected from 'Assets/Icons/radioSaint_selected.svg'
import hanger_selected from 'Assets/Icons/hanger_white.svg'
import hanger from 'Assets/Icons/hanger_grey.svg'
import store_selected from 'Assets/Icons/store_white.svg'
import store from 'Assets/Icons/store_grey.svg'
import { useSelector } from 'react-redux';
import { useRef } from 'react';


const EditBoomer = ({ storeData, canvasRef, setBase64, localConfig, setLocalConfig, setStoreData, fallback, setFallback }) => {

  const [itemFilter, setItemFilter] = useState()
  const [view, setView] = useState('store')
  const [ variants, setVariants ] = useState(false)
  const userType = useSelector(state => state.userType)

  const itemRadios=[
    { img: boomerIcon, selected:boomerIcon_selected, value:"body" },
    { img: hairIcon, selected: hairIcon_selected, value:"hair" },
    { img: saintIcon, selected:saintsIcon_selected, value:"outfits" },
    { img: topIcon, selected: topIcon_selected, value:"top" },
    { img: bottomIcon, selected:bottomIcon_selected, value:"bottom" },
    { img: petIcon, selected:petIcon_selected, value:"pet" },
    { img: accessoryIcon, selected:accessoryIcon_selected, value:"accessory" },
  ]

  useEffect(()=>{
    setItemFilter(itemRadios[0])
  },[])

  useEffect(()=>{
    setVariants(null)
  },[itemFilter])

  useEffect(()=>{
    if(localConfig){
      setBase64(canvasRef.current.toDataURL("image/png").split(';base64,')[1])
    }
  },[localConfig])


  return !itemFilter ? null : (
    <div id={"edit_container"} className={styles.container}>

      <div className={styles.itemRadios}>
        {
          itemRadios.map((item,idx)=>

            !(userType!="Faith Journals"&&item.value=="outfits")&& //only render outfits tab for FJ users
            
              <label key={idx}>
                <input checked={itemFilter.value===item.value} onChange={()=>setItemFilter(item)} type="radio" name="itemRadio"/>
                <div className={styles.itemRadio}>
                  <img src={itemFilter.value===item.value ? item.selected : item.img} alt={item.value} />
                </div>
              </label>
          )
        }
      </div>

      <div className={styles.main}>
        <div onChange={e=> setView(e.target.value)} className={styles.sectionRadios}>
        <label>
          <input defaultChecked type="radio" name="sectionRadio" value={"store"} />
          <div className={styles.sectionRadio}>
            <img className={styles.radioIcon} src={view==="store"?store_selected:store}  alt="" />
            The Store
          </div>
        </label>
        <label>
          <input type="radio" name="sectionRadio" value={"closet"}/>
          <div className={styles.sectionRadio}>
            <img className={styles.radioIcon} src={view==="closet"?hanger_selected:hanger}  alt="" />
            Your Closet
          </div>
        </label>
        </div>
        <div className={styles.itemsDiv}>
          <Items  
            fallback={fallback}
            setFallback={setFallback}
            view={view}
            setStoreData={setStoreData}
            storeData={storeData}
            variants={variants}
            setVariants={setVariants}
            itemFilter={itemFilter}
            ownedAvatarItems={storeData.ownedAvatarItems}
            avatarItems={ storeData.allAvatarItems}
            canvasRef={canvasRef}
            localConfig={localConfig}
            setLocalConfig={setLocalConfig}
            setBase64={setBase64} />
        </div>
      </div>
      
    </div>
  )

}

export default EditBoomer;