import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import boomer from '../../../Assets/Boomers/boomermale.png'
import DashActivityBlock from './DashActivityBlock';
import { idxClass } from '../../../Utils/UI_elements/idxClass';

import styles from './DashClassBlock.module.scss';

const DashClassBlock = ({idx, count, item, item:{ classId, groupName, students, teachersImgUrl, activities, studentApproved, iconKey, actions }}) => {

  const history = useHistory()
  const [ isOpen, toggleOpen ] = useState(false)
  const [ display, toggleDisplay ] = useState(false)
  const [ actionCount, setActionCount ] = useState(0)

  useEffect(()=>{
    const offset = Math.floor(Math.random() * count) * 90
    setTimeout(()=>{ toggleDisplay(true) },offset)

    let localCount = 0;
    activities.forEach(i => localCount = localCount+i.pendingCount)
    setActionCount(localCount)

  },[])

  const handleNavigate = () => {
    if(!studentApproved)return
    history.push(`/class/${classId}`)
    localStorage.setItem('activeClass', JSON.stringify(item))
  }
  return (
    <div className={`${styles.container} ${isOpen && styles.open} ${display && styles.display} ${!studentApproved && styles.unconfirmed}`}>
      {(actions > 0 && studentApproved) && <div className={`${styles.actionCount} ${ isOpen && styles.hidden}`} /> }
      <div className={styles.inner}>
        
      <div className={styles.main}>
        <div className={styles.circleCont}>
          <div className={`${styles.circle} ${studentApproved ? styles[idxClass(iconKey)] : styles.grey }`} />
        </div>
        <div className={styles.content}>
          <div className={styles.topRow}>
            <h5>{groupName}</h5>
          </div>

            {
              !studentApproved ?

                <h5 className={styles.pending}>Pending approval</h5>

              :

                <div className={styles.people}>

                  <div className={styles.teachers}>
                    {
                      teachersImgUrl &&
                      <>
                        {
                          teachersImgUrl.length && <div className={styles.circleImg}>
                            {teachersImgUrl[0] ? <img src={teachersImgUrl[0]} alt="" /> : <div className={`${styles.placeholder} ${styles[idxClass(iconKey)]}`}></div>}
                          </div>
                        }
                        {
                          teachersImgUrl.length > 1 && <div className={styles.circleImg}>
                            {teachersImgUrl[1] ? <img src={teachersImgUrl[1]} alt="" /> : <div className={`${styles.placeholder} ${styles[idxClass(idx)]}`}></div>}
                          </div>
                        }
                        {
                          teachersImgUrl.length > 2 && <div className={styles.circleImg}>
                            {teachersImgUrl[2] ? <img src={teachersImgUrl[2]} alt="" /> : <div className={`${styles.placeholder} ${styles[idxClass(idx)]}`}></div>}
                          </div>
                        }

                      </>
                    }
                  </div>

                  <div className={styles.students}>
                    {
                      students &&
                      <>
                        <div className={styles.circles}>
                          {
                            students?.length && <div className={styles.circleImg}>
                              <img className={!students[0].avatarUrl && styles.placeholder} src={students[0].avatarUrl ? students[0].avatarUrl : boomer} alt="" />
                            </div>
                          }
                          {
                            students.length > 1 && <div className={styles.circleImg}>
                              <img className={!students[1].avatarUrl && styles.placeholder} src={students[1].avatarUrl ? students[1].avatarUrl : boomer} alt="" />
                            </div>
                          }
                          {
                            students.length > 2 && <div className={styles.circleImg}>
                              <img className={!students[2].avatarUrl && styles.placeholder} src={students[2].avatarUrl ? students[2].avatarUrl : boomer} alt="" />
                            </div>
                          }

                        </div>
                        {students.length > 3 && <div className={styles.dot}><p>+{students.length - 3}</p> </div>}
                      </>
                    }
                  </div>

                  {/* <div onClick={() => toggleOpen(!isOpen)} className={`${styles.chevron} ${isOpen && styles.open}`} /> */}
                </div>
            }

        </div>

        <div onClick={handleNavigate} className={styles.clickHandler} />
      </div>

      <div className={styles.activitiesDiv}>
        { activities.length === 0 ? 

            <div className={styles.placeholder}>
              <h5>No activities to display</h5>
            </div>

          :

            activities.sort((a,b) => b.pendingCount-a.pendingCount).map((activity,idx) => 
              <DashActivityBlock 
                activity={activity} 
                classId={classId}
                idx={idx} 
                count={activities.length} 
                isOpen={isOpen}
              />
            )

        }
      </div>
      </div>
    </div>
  )

}

export default DashClassBlock;