import React, { useCallback, useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import loader from 'Assets/Loaders/spinner_teal.svg'

import styles from './EntryTextEditor.module.scss';
import UploadBtn from "./UploadBtn";
import StudentFileDiv from "./StudentFileDiv";
import EntrySubmitBtn from "../EntrySubmitBtn/EntrySubmitBtn";
import { useDebounce } from "Utils/Hooks/useDebounce";
import { sendToApi } from "API/API.service";
import DOMPurify from "dompurify";
import WinningEntryBtn from "../WinningEntry/WinningEntryBtn";
import { stripTags } from "Utils/stripTags";


const EntryTextEditor = ({ updateSectionKey, privateMode, toggleModal, 
      setActiveSection, activityId, activeSection, forceWriting, setForceWriting, 
      activeSection:{ sectionStatus, entryHTML, sectionPhase, finalGrade, 
        grading, studentFiles, entryApproved, sectionId }, mobile, localPrivate, 
        setLocalPrivate, synod, sidebarOpen, toggleSidebar, 
        mobileQuillRef, toggleMobileEditor }) => {

  const [ processing, toggleProcessing ] = useState(false);
  const [ save, toggleSave ] = useState(false)
  const [ localText, setLocalText ] = useState(entryHTML)
  const [ confirmSaved, setConfirmSaved ] = useState(false)

  const handleSave = (confirm, input) => {
    sendToApi(`activity/${activityId}/${activeSection.sectionIndex}?&submit=false`, {entry:DOMPurify.sanitize(input ? input : localText)})

    if(confirm){
      setConfirmSaved(true)
      setTimeout(()=>{setConfirmSaved(false)},1500)
    }

  }

  const debounceSave = useCallback(useDebounce((localText)=>triggerSave(true, localText), 1500), []);

  const triggerSave = () => {
    toggleSave(true)
    toggleSave(false)
  }

  const handleChange = (val) => {
    setLocalText(val)
    debounceSave(localText)
  }

  const handlePrivateSlider = () => {
    setLocalPrivate(!localPrivate);
    sendToApi(`activity/${activityId}/toggle_private_mode`);
  }

  // this is only used if the section is in voting and the student 
  // has switched to writing to finish their entry which has not bee submitted yet
  const backToVoting = () => {
    // reset the forced writing
    setForceWriting(false)
    // make sure the sidebar is closed to avoid having a blank space on
    // the right of the page when we show the options to vote for
    toggleSidebar(false)
  }

  useEffect(()=>{
    if(save){
      handleSave(false, localText)
    }
  },[save])

  useEffect(()=>{
    setLocalText(activeSection.entryHTML)
  },[sectionId])
  
  const editorModules = {
    toolbar: 
      !mobile ? //non-responsive options
      [
        [{ 'font': [] }],
        ['bold', 'italic', 'underline'],
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]
      ]
      : //responsive options
      [
        [{ 'font': [] }],
        ['bold', 'italic', 'underline'],
        [{ 'align': [] }],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
  };

  return (
    <div 
    className={`${styles.container} ${synod&&styles.synod} ${entryApproved&&"approved"}`}>

      {
        mobile && 
        <div className={`${styles.top}`}>
          <div onClick={()=>toggleMobileEditor('remove')} className={`${styles.chevron}`} />      
            { 
              [1,2,6,8].indexOf(activeSection?.sectionPhase) !== -1 && 
              !activeSection.entryApproved && 
              !activeSection.storyStart &&
              <h5 onClick={()=>handleSave(true)} className={styles.saveBtn}>{confirmSaved?"Saved!":"Save"}</h5>
            }
        </div>
      }

      <div className={`${styles.wordCount} ${entryApproved && styles.uploadsHidden}`}>
        <h5>Words: {stripTags(localText).split(' ')[0] == '' ? 0 : stripTags(localText).split(' ').length}</h5>
      </div>

      {
        grading && Number(finalGrade) > 0 && 
        <div className={styles.gradeContainer}> 
          <h3>Grade</h3>
          <div className={styles.gradeCircle}>
            <h4>{finalGrade}</h4>
          </div>
        </div>
      }     

      <div className={`${styles.editorDiv} ${privateMode && `privateModeEntry ${styles.privateResponsive}`}`}>

        {(privateMode && !entryApproved && sectionStatus !== 8 ) && 
          <div className={styles.privateModeDiv}>

            <div onClick={handlePrivateSlider} className={styles.sliderDiv}>
              <div className={`${styles.slider} ${localPrivate && styles.active}`}>
                <div className={`${styles.sliderInner} ${localPrivate && styles.active}`} />
              </div>
            </div>

            <div className={styles.icon} />
            <p>Private mode</p>

            <div className={styles.tip}>
              <div className={styles.tooltip}>
                In private mode, educators can't read your entry
                (applicable for all sections of this activity).
              </div>
            </div>
          </div>
        }
        <ReactQuill
          ref={mobileQuillRef}
          className={"short"}
          readOnly={entryApproved}
          modules={editorModules}
          theme="snow"
          value={localText||""}
          placeholder={(entryApproved || [3,7,8,10].indexOf(sectionPhase) !==-1) ? "" : "Start writing your entry following the instructions"}
          onKeyUp={e=> {if(updateSectionKey)updateSectionKey('entryHTML', e.target.innerHTML)}}
          onChange={e=>handleChange(e)}>
        </ReactQuill>
        {
          !entryApproved &&
          <UploadBtn
            toggleProcessing={toggleProcessing}
            sidebarOpen={sidebarOpen}
            sectionPhase={sectionPhase}
            grading={grading}
            activeSection={activeSection}
            activityId={activityId}
            setActiveSection={setActiveSection}
            updateSectionKey={updateSectionKey} />
        }

        <div className={`${styles.bottom} ${(studentFiles?.length > 0 || processing || activeSection.winnerData) && styles.withFiles}`}>
          {
            ((studentFiles?.length > 0 || processing) && !activeSection.winnerData) ?

            <div className={styles.filesDiv}>
              {
                studentFiles?.map((i,idx) => 
                  <StudentFileDiv 
                    key={idx} 
                    idx={idx} 
                    item={i} 
                    activityId={activityId}
                    updateSectionKey={updateSectionKey}
                    setActiveSection={setActiveSection}
                    activeSection={activeSection} />
                  )
              }
              {
                processing &&
                  <div className={`${styles.fileDiv} ${styles.placeholder}`}>
                    <img src={loader} alt="" />
                  </div>
              }
            </div>

          :  activeSection.winnerData &&
            
            <WinningEntryBtn
              toggleModal={toggleModal}
              winnerData={activeSection.winnerData} />

          }


        <div className={styles.actionButtons}>
          { 
            !activeSection.entryApproved && 
            !activeSection.storyStart &&
            !mobile && 
            !forceWriting && 
            <h5 onClick={() => handleSave(true)} className={styles.saveBtn}>
              {confirmSaved?"Saved!":"Save"}
            </h5>
          }
          {
            forceWriting && 
            <h5 onClick={() => backToVoting()} className={styles.voteBtn}>
              Vote
            </h5>
          }
          <EntrySubmitBtn
            synod={synod}
            entryApproved={activeSection.entryApproved}
            inputHTML={localText} 
            activityId={activityId}
            updateSectionKey={updateSectionKey} 
            toggleModal={toggleModal}
            activeSection={activeSection} />
        </div>

      </div>
        

      </div>

    </div>
  )

}

export default EntryTextEditor;

