export const authRedirect = (logout) => {

  const hosts = {
    localhost: "localhost",
    testing: "write.sand.escribli",
    production: "write.escribli",
    sandFaith: "write.sand.faith-journals",
    prodFaith: "write.faith-journals"
  }

  const authRoutes = {
    localhost: "http://localhost:3011/auth/student",
    testing: "https://sand.escribli.com/auth/student",
    production: "https://escribli.com/auth/student",
    sandFaith: "https://sand.faith-journals.com/auth/student",
    prodFaith: "https://faith-journals.com/auth/student",
  }

  const logoutRoutes = {
    localhost: "http://localhost:3011/auth/student/logout",
    testing: "https://sand.escribli.com/auth/student/logout",
    production: "https://escribli.com/auth/student/logout",
    sandFaith: "https://sand.faith-journals.com/auth/student/logout",
    prodFaith: "https://faith-journals.com/auth/student/logout"
  }

  console.log("window.location")
  console.log(window.location)

  if (window.location.host.includes(hosts.localhost)) {
   window.location.href= logout ? logoutRoutes.localhost : authRoutes.localhost
  }
  
  if (window.location.host.includes(hosts.production)) {
   window.location.href= logout ? logoutRoutes.production : authRoutes.production
  }
  
  if (window.location.host.includes(hosts.testing)) {
   window.location.href= logout ? logoutRoutes.testing : authRoutes.testing
  }
  
  if (window.location.host.includes(hosts.sandFaith)) {
   window.location.href= logout ? logoutRoutes.sandFaith : authRoutes.sandFaith
  }
  
  if (window.location.host.includes(hosts.prodFaith)) {
   window.location.href= logout ? logoutRoutes.prodFaith : authRoutes.prodFaith
  }

}
