import React from 'react';

import styles from './JoinClassModal.module.scss';
import { useState } from 'react';
import { sendToApi } from 'API/API.service';
import loader from 'Assets/Loaders/spinner_teal.svg'
import store from 'storeConfig';
import { updateDashKey } from 'Actions/dashDataActions';

const JoinClassModal = ({ newModalRef, inputRef, toggleNewModal, setDashFeed, dashFeed, centered }) => {

  const [ codeInput, setCodeInput ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(false)

  const handleJoinClass = async () => {
    setError(false)
    setLoading(true)
    const data = await sendToApi(`add_class/${codeInput}`);
    // localStorage.setItem('dashRecall', true)
    console.log(data)
    setLoading(false)

    if(data.message){
      return setError(true)
    }

    setDashFeed({...dashFeed, classes:data})
    toggleNewModal('remove')
  }


  return (
    <div ref={newModalRef} className={`${styles.container} ${centered && styles.centered}`}>

     {
      loading &&
        <div className={styles.loaderOverlay}>
          <img src={loader} alt="spinner"/>
        </div>
      }

      <div onClick={()=>{toggleNewModal('remove'); setError(false)}} className={styles.close} />
      <p className={styles.grey}>Class code</p>
      <input onKeyUp={e=>{setCodeInput(e.target.value); if(error)setError(false)}} ref={inputRef} placeholder={"e.g A22334B"} autoFocus type="text"/>
      {error && <p className={styles.error}>code not recognized</p>
}
      <div onClick={handleJoinClass} className={`${styles.createBtn} ${codeInput === "" && styles.disabled}`}>
        <p>Join Class</p>
      </div>
    </div>
  )

}

export default JoinClassModal;