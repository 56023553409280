import React from 'react';
import send from 'Assets/Icons/send_white.svg'

import styles from './EducatorComments.module.scss';
import { useRef } from 'react';
import { sendToApi } from 'API/API.service';
import { useState } from 'react';

const CommentInput = ({ localComments, setLocalComments, activityId, sectionIdx, activity, setActivity, activeSection }) => {

  const inputRef = useRef()
  const [ activeBtn, toggleActiveBtn ] = useState(false)


  const handleSendComment = () => {
    
    toggleActiveBtn(false)
    if(inputRef.current.value === "") return false

    const payload = { createdOn: new Date(), text:inputRef.current.value,  isStudent: true  }
    setLocalComments([ ...localComments, payload ])
    setActivity({...activity, sections:[
      ...activity.sections.slice(0, sectionIdx-1),
      {...activeSection, comments:[payload, ...localComments]},
      ...activity.sections.slice(sectionIdx),
    ]})
    inputRef.current.value="";
    sendToApi(`activity/${activityId}/${sectionIdx-1}/comment`, payload)

  }


  const handleKeyUp = (e) => {
    toggleActiveBtn(e.target.value!=="")
    if(e.key === 'Enter') handleSendComment()
  }

  return (
    <div className={styles.commentInput}>
      <div className={`${styles.inner}`}>

      <div className={`${styles.inputContainer}`}>
        <textarea onKeyUp={handleKeyUp} ref={inputRef} />
      </div>
      <div  onClick={handleSendComment} className={`${styles.sendBtn} ${activeBtn && styles.active}`}>
        <p>Send</p>
        <img src={send} alt="send" />
      </div>
      </div>
    </div>
  )

}

export default CommentInput;