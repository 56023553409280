import React from 'react';
import closeIcon from 'Assets/Icons/close_square.svg'
import styles from './ClassmatesModal.module.scss';

const ClassmatesModal = ({ groupName, students, closeModal }) => {

  return (
    <div className={styles.container}>

      <img className={styles.close} onClick={closeModal} src={closeIcon} alt="close" />
      <h1>{groupName}</h1>
      <div className={styles.blocks}>
        {
          students.map((student, idx) => 
          <div key={idx} className={styles.block}>
            <img src={student.avatarUrl} alt="avatar" />
            <h5>{student.studentName}</h5>
          </div>)
        }
      </div>
    </div>
  )

}

export default ClassmatesModal;