import React, { useRef, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

import styles from './EntryModal.module.scss';
import closeIcon from 'Assets/Icons/close_square.svg'
import closeCross from 'Assets/Icons/close_cross.svg'
import { sendToApi } from 'API/API.service';

const EntryModal = ({ idx, closeModal, entries, markAsRead, sectionId, mobile, activityId, sectionIndex, activeSection }) => {

  const contRef = useRef()
  const [ currentEntry, setCurrentEntry ] = useState(entries[idx])
  const [ currentIdx, setCurrentIdx ] = useState(idx)

  const handleArrow = (direction) => {
    const newIdx = direction === 'right' ? currentIdx+1 : currentIdx-1;
    setCurrentIdx(newIdx)
  }

  useEffect(()=>{
    if(contRef.current){
      setTimeout(()=>{ contRef.current.classList.add(styles.fullSize) },100)
    }
  },[contRef])

  useEffect(()=>{
    setCurrentEntry(entries[currentIdx])
  },[currentIdx, entries])

  useEffect(()=>{
    markAsRead(sectionId, currentIdx)
    sendToApi(`activity/${activityId}/${sectionIndex}/seen/${currentEntry.entryId}`)
  }, [currentEntry, markAsRead, sectionId])

  return (
    <div ref={contRef} className={`${styles.container} ${mobile&&styles.mobile}`}>

      <img className={styles.close} onClick={closeModal} src={!mobile?closeIcon:closeCross} alt="close" />

      <h2>Entry {currentIdx+1}</h2>

      <div
        className={`${styles.entryText}`}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentEntry.entryHTML) }} /> 

      <div className={styles.buttons}>
        <div onClick={()=>handleArrow('left')} className={`${styles.prevBtn} ${currentIdx === 0 && styles.disabled}`}>{!mobile&&"Read "}previous</div>
        <div onClick={()=>handleArrow('right')} className={`${styles.nextBtn} ${currentIdx === entries.length-1 && styles.disabled}`}>{!mobile&&"Read "}next</div>
      </div>
    </div>
  )

}

export default EntryModal;