import React, { useRef, useEffect } from 'react';

import styles from './EntryTextEditor.module.scss';
import pdfIcon from 'Assets/Icons/pdf.svg'
import clipIcon from 'Assets/Icons/paperclip.svg'
import { sendToApi } from 'API/API.service';

const UploadBtn = ({ files, updateSectionKey, activityId, sectionPhase, grading, 
    activeSection, setActiveSection, toggleProcessing, sidebarOpen }) => {

  const uploadInput = useRef()

  const limitReached = activeSection.studentFiles.length > 4

  useEffect(() => {
    // re-render to move the button down on the page
  }, [sidebarOpen])

  const handleUploadFile = async (e) => {
    if(e.target.files.length){
      // tell the user we're uploading
      toggleProcessing(true)
      // append files to the form data 
      const formData = new FormData();
      formData.append('file', e.target.files[0], e.target.files[0].name);
      formData.append('name', 'image from student app'); 
      // send to the backend api
      const data = await sendToApi(`activity/${activityId}/save_file/${activeSection.sectionIndex-1}`, formData)
      // update local data
      setActiveSection({...activeSection, studentFiles:[...data]})
      updateSectionKey('studentFiles', data)
      // store.dispatch(updateActivitySection(activeSection.sectionIndex, {...activeSection, files:[...data]}))
      // stop the ui uploading feedback
      toggleProcessing(false)
    }
  }

  const fileLimitCheck = () => {
    if(activeSection.studentFiles.length>=5){return}
    else{uploadInput.current.click()}
  }

  return (
    <div className={`${styles.uploadBtn} ${activeSection.studentFiles.length && styles.withPics}`}>
      <div className={`${styles.uploadTooltip}  ${limitReached && styles.limitReached}`}>
        {
          !limitReached ? 
            <>
              <p>Upload pictures of your handwritten work in the following formats:</p>
              <p>.png, .jpg, .jpeg</p>
              <p><span>(maximum 5)</span></p>
            </>
            :
            <p>You have recahed the limit for uploaded files</p>
        }
      </div>
      <input type="file" name="upload" ref={uploadInput} onChange={handleUploadFile} accept=".png, .jpg, .jpeg "/> 
      <div onClick={fileLimitCheck} className={`${styles.uploadBtnInner}  ${limitReached && styles.limitReached} `}>
          <img src={clipIcon} alt="paperclip" />
          {/* <img src={pdfIcon} alt="pdf icon" /> */}
        </div> 
    </div>
  )

}

export default UploadBtn;