import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

import styles from './EntrySidebar.module.scss';

import Deadline from 'Components/UtilityComponents/Deadline/Deadline';
import ClosedButtons from './ClosedButtons/ClosedButtons';
import OpenButtons from './OpenButtons/OpenButtons';
import Instructions from './OpenContent/Instructions/Instructions';
import EducatorComments from './OpenContent/EducatorComments/EducatorComments';
import BreakoutRooms from './OpenContent/BreakoutRooms/BreakoutRooms';
import GrammarBot from './OpenContent/GrammarBot/GrammarBot';
import InstructionsModal from './InstructionsModal/InstructionsModal';

const EntrySidebar = ({ open, toggleSidebar, toggleModal, sidebarRef, activeSection, activity, setActivity, setActiveSection, inputHTML, synod }) => {

  const firstRender = useRef(true);
  const [ radVal, setRadVal ] = useState('instructions')
  const [ commentsHaveCascaded, setCommentsHaveCascaded ] = useState(false)

  useEffect(()=>{
    if(radVal==="grammar" && !activeSection.grammarReport) toggleSidebar(false)
    if(radVal==="friend" && !activeSection.breakoutRooms?.length) toggleSidebar(false)
  },[radVal, activeSection, toggleSidebar])

  useEffect(()=>{
    // upon select section, set open tab to 'comments' of there is a revision request
    const hasRevision = activeSection.actions?.filter(i=>i.kind==="revision").length;
    console.log(hasRevision)
    console.log("hasRevision")
    setRadVal(hasRevision ? "comments" : "instructions")
    
  },[ activeSection ])


  useEffect(()=>{ // open/close sidebar according to sidebarOpen state item
    const action = open ? 'add' : 'remove'
    sidebarRef.current?.classList[action](styles.open)
  },[open])

  useLayoutEffect(()=>{

    if (firstRender.current) { //prevent effects being triggered on page re-render
      firstRender.current = false;
      return;
    }

    if(!open){ //closing
      //educator comments cascade animation should be triggered only once
      //per opening of the sidebar, not every time the tab is selected
      //this key is set to true upon render of comments comp and false upon closing sidebar
      setCommentsHaveCascaded(false)
    } 

  },[open])

  const handleModal = () => {
    toggleModal(true,
      <InstructionsModal 
        synod={synod}
        initRadval = {radVal}
        activeSection={activeSection}
        inputHTML = {inputHTML}
        commentsHaveCascaded={true} 
        activity={activity}
        setActivity={setActivity}
        setActiveSection={setActiveSection}  />
    )
  }


  return (
    <div ref={sidebarRef} className={`${styles.container} ${styles.open}`}>

      <div className={styles.topBar}>
        { !synod ? 
          <Deadline open={open} endDate={activeSection.endDate == "open" ? "open" : new Date(activeSection.endDate)} />
        :
          <h4 className={styles.synodHeading}>Instructions</h4>
        
        }
        <div className={`${styles.controlBtns} ${synod && styles.synod}`}>
          <div onClick={handleModal} className={`${styles.expand}  ${open && styles.display}`}/>
          <div onClick={()=>toggleSidebar(false)} className={`${styles.closeArrow} ${open && styles.display}`}/>
        </div>
      </div>
      
      <ClosedButtons //<---DISPLAYED WHEN CLOSED
        toggleSidebar={toggleSidebar}
        open={open}
        activeSection={activeSection}
        setRadVal={setRadVal}
        radVal={radVal} />
 
      <div className={`${styles.openContent} ${open && styles.display}`}>{/*<---DISPLAYED WHEN OPEN*/}

        {
          !synod &&
            <OpenButtons
              activeSection={activeSection}
              setRadVal={setRadVal}
              open={open}
              radVal={radVal}
              inputName={"sidebarOption"} />
        }

          {
            open &&
              <div className={styles.openMain}>
                {
                  radVal === "instructions" ?
                    <Instructions synod={synod} activeSection={activeSection} />
                  
                  : radVal === "comments" ? 
                    <EducatorComments
                      comments={activeSection.comments}
                      activityId={activity.activityId}
                      activeSection={activeSection}
                      activity={activity}
                      setActivity={setActivity}
                      commentsHaveCascaded={commentsHaveCascaded}
                      setCommentsHaveCascaded={setCommentsHaveCascaded} />
                  
                  : radVal === "friend" && activeSection.breakoutRooms ? 
                    <BreakoutRooms  
                      breakoutRooms={activeSection.breakoutRooms}
                      activity={activity}
                      setActivity={setActivity} 
                      setActiveSection={setActiveSection}
                      activeSection={activeSection} />
                  
                  : radVal === "grammar" &&  activeSection.grammarReport?.report?.length ? 
                    <GrammarBot reportData={activeSection.grammarReport} />

                  : null
                }
              </div>
          }
      </div>

    </div>
  )

}

export default EntrySidebar;