import React from 'react';

import styles from './OpenButtons.module.scss';

import instructions from 'Assets/Icons/instructions.svg'
import instructions_hover from 'Assets/Icons/instructions_hover.svg'
import instructions_blue from 'Assets/Icons/instructions_blue.svg'

import grammar from 'Assets/Icons/grammar.svg'
import grammar_hover from 'Assets/Icons/grammar_hover.svg'
import grammar_blue from 'Assets/Icons/grammar_blue.svg'

import comment from 'Assets/Icons/comment.svg'
import comment_hover from 'Assets/Icons/comment_hover.svg'
import comment_blue from 'Assets/Icons/comment_blue.svg'

const OpenButtons = ({ isModal, inputName, noBorder, setRadVal, open, radVal, activeSection: { unreadComments, breakoutRooms, grammarReport } }) => {

  const beepAudio = new Audio('Sounds/beep_2.mp3')
  beepAudio.volume=0.35

  const beep = () => null
  // const beep = () => beepAudio.play()

  return (
    <div onChange={(e)=>setRadVal(e.target.value)} className={`${styles.openButtons} ${noBorder && styles.noBorder} ${isModal && styles.isModal } ${open && styles.display}`}>
    <label onClick={beep}>
      <input checked={radVal === "instructions"} onChange={()=>{return}} type="radio" name={inputName} value={"instructions"}/>
      <div className={styles.radioOption}>
        <div className={styles.icon}>
          <img className={styles.init} src={instructions} alt="instructions" />
          <img className={styles.hover} src={instructions_hover} alt="instructions" />
          <img className={styles.selected} src={instructions_blue} alt="instructions" />
        </div>
        <h5>Instructions</h5>
      </div>
    </label>
    
    {
      grammarReport?.report?.length ?
      
        <label onClick={beep}>
          <input checked={radVal === "grammar"} onChange={()=>{return}} type="radio" name={inputName} value={"grammar"}/>
          <div className={styles.radioOption}>
            <div className={styles.icon}>
              <img className={styles.init} src={grammar} alt="grammar" />
              <img className={styles.hover} src={grammar_hover} alt="grammar" />
              <img className={styles.selected} src={grammar_blue} alt="grammar" />
            </div>
            <h5>Grammar</h5>
          </div>
        </label>

      : null
    }

    <label className={styles.wide} onClick={beep}>
      <input checked={radVal === "comments"} onChange={()=>{return}} type="radio" name={inputName} value={"comments"}/>
      <div className={styles.radioOption}>
        <div className={styles.icon}>
          <img className={styles.init} src={comment} alt="comments" />
          <img className={styles.hover} src={comment_hover} alt="comments" />
          <img className={styles.selected} src={comment_blue} alt="comments" />
          {unreadComments && <div className={styles.dot} />}
        </div>
        <h5>Teacher comments</h5>
      </div>
    </label>

    {
      !breakoutRooms?.length ? null :

        <label onClick={beep}>
          <input checked={radVal === "friend"} onChange={()=>{return}} type="radio" name={inputName} value={"friend"}/>
          <div className={styles.radioOption}>
            <div className={styles.icon}>

              <div className={`${styles.boomerCircle} ${breakoutRooms.length === 2 && styles.one}`}>
                <img src={breakoutRooms[0].boomerUrl} alt="boomer" />
              </div>

              {
                breakoutRooms.length === 2 &&
                <div className={`${styles.boomerCircle} ${styles.two}`}>
                  <img src={breakoutRooms[1].boomerUrl} alt="boomer" />
                </div>
              }

            </div>
            <h5>Friend's Entry</h5>
          </div>
        </label>
        
    }

  </div>
   

  )

}

export default OpenButtons;