import React, { useState, useRef } from 'react';

import styles from './BoomerBlock.module.scss';
import collapse from 'Assets/Icons/collapse.svg'
import openIcon from 'Assets/Icons/collapse_open.svg'
import { useSelector } from 'react-redux';
import JoinClassModal from '../JoinClassModal/JoinClassModal';
import { Link } from 'react-router-dom';

const BoomerBlock = ({ collapsed, toggleCollapsed, responsive, dashBase64, setDashFeed, dashFeed }) => {

  //The initial boomer base64 data is passed by props from the dashFeed state object
  //Upon editing avatar in the store, the new data is pushed to Redux store and used by this component on line 19

  const reduxBase64 = useSelector(state => state.dashData.base64)
  const newModalRef = useRef()
  const inputRef = useRef()
  const isImpersonate = localStorage.getItem('impersonate')

  const toggleNewModal = (input) => {
    newModalRef.current.classList[input](styles.showModal)
    if(inputRef.current && input==='add'){
      inputRef.current.value=""
      inputRef.current.focus()
    }
  }

  return (
    <div className={styles.column}>
      <div className={`${styles.container} ${responsive && styles.responsive}`}>
        {/* <img onClick={()=>toggleCollapsed({...collapsed, boomer:!collapsed.boomer})} className={styles.collapse} src={!collapsed.boomer?collapse:openIcon} alt="collapse" /> */}
        <img className={styles.boomer} src={`data:image/png;base64, ${!reduxBase64?dashBase64:reduxBase64}`} alt="boomer" />
        <div className={styles.circle}/>
      </div>
      <div className={styles.newGroupCont}>
        <div onClick={()=>toggleNewModal('add')} className={`${styles.newGroupBtn}`}>
          <div className={styles.plus} />
          <p>Join Class</p>
        </div>
        {
          isImpersonate == 'true' &&
            <Link to={"/logout"} className={styles.backToTeacher}>
              Back to teacher account
            </Link>
        }
        <JoinClassModal 
          setDashFeed={setDashFeed}
          dashFeed={dashFeed}
          inputRef={inputRef}
          toggleNewModal={toggleNewModal}
          newModalRef={newModalRef}/>
      </div>
    </div>
  )

}

export default BoomerBlock;