import React, { useEffect, useState } from 'react';
import { stripTags } from 'Utils/stripTags';

import styles from './Instructions.module.scss';

const WordbankItem = ({ word, inputHTML }) => {

  const [ isUsed, setIsUsed ] = useState(false)
  useEffect(()=>{
    if(inputHTML && stripTags(inputHTML.toLowerCase()).includes(word.toLowerCase())){
      setIsUsed(true)
    }else {
      setIsUsed(false)
    }
  },[inputHTML])

  return (
    <div className={`${styles.wordbankItem} ${isUsed && styles.used}`}>
      {word}
    </div>
  )

}

export default WordbankItem;