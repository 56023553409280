import React, { useState, useEffect, useRef } from 'react';
import { setGrammarFeedback } from 'Utils/UI_elements/autoFeedbackHTML';

import styles from './GrammarBot.module.scss';

const GrammarBot = ({ reportData:{ fullEntry, report} }) => {

  const contRef = useRef()
  const [ param, setParam ] = useState()
  const [ entryHTML, setEntryHTML ] = useState('');
  // const [ loading, setLoading ] = useState(false);

  useEffect(()=>{
    if(report?.length){
      setEntryHTML(setGrammarFeedback(fullEntry, report, param))
    }
  },[param, report, fullEntry])

  useEffect(()=>{
    setTimeout(()=>{ contRef.current.classList.add(styles.display) },100)
  },[])

  return (
    <div ref={contRef} className={styles.container}>
      <div className={styles.pills}>

        <label>
          <input type="radio" name="feedbackParam" />
          <div
            className={`${styles.row} ${styles.active}`}
            onClick={(e) => setParam('passive') }>
            <div className={styles.number}>{report.filter(i => i.type === 'passive').length}</div>
            <p>Passive Voice</p>
          </div>
        </label>

        <label>
          <input type="radio" name="feedbackParam" />
          <div className={`${styles.row}`} onClick={(e) => setParam('initial') }>
            <div className={styles.number}>{report.filter(i => i.type === 'initial').length}</div>
            <p>Initial Pronouns</p>
          </div>
        </label>

        <label>
          <input type="radio" name="feedbackParam" />
          <div className={`${styles.row}`} onClick={(e) => setParam('grammar') }>
            <div className={styles.number}>{report.filter(i => i.type === 'grammar').length}</div>
            <p>Grammar</p>
          </div>
        </label>
        
        <label>
          <input type="radio" name="feedbackParam" />
          <div className={`${styles.row}`} onClick={(e) => setParam('overused') }>
            <div className={styles.number}>{report.filter(i => i.type === 'overused').length}</div>
            <p>Overuse</p>
          </div>
        </label>

        <label>
          <input type="radio" name="feedbackParam" />
          <div className={`${styles.row}`} onClick={(e) => setParam('consistency') }>
            <div className={styles.number}>{report.filter(i => i.type === 'consistency').length}</div>
            <p>Consistency</p>
          </div>
        </label>

      </div>


      <div className={`${styles.entryText}`} style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: entryHTML}} />

    </div>
  )

}

export default GrammarBot;