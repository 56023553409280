
export const addDashData = ( object ) => {
  console.log('add dash data')
  console.log(object)
  return{
    type: 'ADD_DASH_DATA',
    payload: object
  }
}

export const updateDashKey = ( key, value ) => {
  console.log('update dash key')
  return{
    type: 'UPDATE_DASH_KEY',
    payload: {key, value}
  }
}

export const updateBoomerBase64 = ( string ) => {
  return{
    type: 'UPDATE_BOOMER_BASE64',
    payload: string
  }
}

export const updatePostPurchase = ( bool ) => {
  console.log('post purchase')
  return{
    type: 'UPDATE_POST_PURCHASE',
    payload: bool
  }
}

export const updateBoomerBucks = ( int ) => {
  console.log('update boomer bucks')
  return{
    type: 'UPDATE_BOOMER_BUCKS',
    payload: int
  }
}