import React, { useEffect, useRef } from 'react';

import styles from './ActivityBlock.module.scss';
import ActivitySectionsModal from '../ActivitySectionsModal/ActivitySectionsModal';
import returnSectionIcon from 'Utils/UI_elements/returnSectionIcon';


const ActivityBlock = ({ count, filter, classObj, setClassObj, toggleModal, activity, iconKey, activity:{ activityTitle, activityPhase, pendingCount, sections }}) => {

  const isInactive = [2,4,5].indexOf(activityPhase)===-1

  const textClass = 
    activityTitle.length > 30 ? "long" :
    activityTitle.length > 15 ? "medium" : "short"

  const type = 'white'   

  const contRef = useRef()
  
  
  useEffect(()=>{
    const offset = Math.floor(Math.random() * count) * 60

    setTimeout(()=>{
      contRef.current.classList.add(styles.display)
    },offset)
    
  },[filter])


  return (

    <div 
      id={`block${activity.activityId}`}
      ref={contRef}
      onClick={()=>toggleModal(true, <ActivitySectionsModal classObj={classObj} setClassObj={setClassObj} activity={activity} />)}
      className={` ${styles.container} ${isInactive && styles.inactive}  ${styles[type]}`}>

        {pendingCount>0 && <div className={styles.dot}>{pendingCount}</div>}
        
        <div className={styles.iconDiv}>
          <img src={returnSectionIcon(Number(iconKey))} alt={'iconKey'} />
        </div>
        <div className={styles.text}>
          <h5 className={textClass}>{activityTitle}</h5>
          <h6>{sections.length} sections</h6>
        </div>

    </div>
  )

}

export default ActivityBlock;