import React, { useEffect, useRef, useState } from 'react';
import {  Redirect, useParams } from 'react-router';

import styles from './ClassDetails.module.scss';
import { getFromApi } from 'API/API.service';
import MainLoader from 'Components/UtilityComponents/MainLoader/MainLoader';
import DetailsTopBar from './DetailsTopBar/DetailsTopBar';
import GridView from './GridView/GridView';
import ListView from './ListView/ListView';
import ViewRadios from './ViewRadios/ViewRadios';

const ClassDetails = ({ toggleModal }) => {

  const { classId } = useParams()
  const [ filter, setFilter ] = useState("current")
  const [ view, toggleView ] = useState("grid")
  const [ classObj, setClassObj ] = useState(null)
  const isEmpty = classObj && !classObj.activities?.length
  const sectionsModalRef = useRef(null)

  const getClassDetails = async () => {
    const res = await getFromApi(`class/${classId}`)
    console.log(res)
    setClassObj(res)
    
  }
  useEffect(()=>{
    getClassDetails()

    //trigger modal if the modalTrigger key exists in ls
    //this key will be present if navigating via the 'back' button in the studentEntry screen
    const modalTrigger = localStorage.getItem('modalTrigger')
    if(modalTrigger){
      setTimeout(()=>{
        document.getElementById(modalTrigger)?.click()
        localStorage.removeItem('modalTrigger')
      },500)
    }

  },[])
  

  useEffect(()=>{sectionsModalRef.current?.classList.remove(styles.show)},[filter])

  //reset filter when switching to grid view
  useEffect(()=>{ if(view==="list"){setTimeout(()=>{setFilter('current')},400)}},[view])
  
  return !classObj ?  <MainLoader /> : !classObj ? <Redirect to={"/dashboard"}/> : (

    <section className={styles.container}>

      <DetailsTopBar
        toggleModal={toggleModal} 
        classObj={classObj} 
        view={view}
        toggleView={toggleView}
        filter={filter}
        setFilter={setFilter} />

      <div className={styles.main}>
        
        {/* <ViewRadios
          responsive
          view={view}
          toggleView={toggleView} /> */}
        
        {
          view === 'grid' ? 

            <GridView 
              toggleModal={toggleModal}
              filter={filter}
              isEmpty={isEmpty}
              view={view}
              classObj={classObj}
              setClassObj={setClassObj} />

          :
              
            <ListView
              toggleModal={toggleModal}
              activities={classObj.activities.sort((a,b)=>b.pendingCount-a.pendingCount)}
              filter={filter}
              isEmpty={isEmpty}
              view={view} />
            
        }

      </div>

    </section>
    
  )

}

export default ClassDetails;

// if (phase===2) return 'Writing'
// if (phase===4||phase===5) return 'Voting'
// if (phase===1||phase===21) return 'Standby'
// if (phase===3||phase===7||phase===10||phase===8) return 'Completed'


// status === 0 ? 'Start Writing' :
//     status === 1 ? 'Start Writing' :
//     status === 2 ? 'Writing' :
//     status === 3 ? 'Start voting' :
//     status === 4 ? 'Voting' :
//     status === 5  ? 'Voted' :
//     status === 6 ? 'Submitted' :
//     status === 7 ? 'Reviewing' :
//     status === 8 ? 'Completed' :




// <div ref={sectionsModalRef} className={styles.sectionsModal}>
// <ActivitySectionsModal
//   classId={classId}
//   activity={selectedActivity}
//   toggleSectionsModal={toggleSectionsModal} />
// </div>
