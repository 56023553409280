const initialState = {incomingMessage:null, unreadCount:0};

const chatData = (state={...initialState}, action) => {

  const { type, payload } = action
  // const { type, payload } = action

  switch(type){
    case 'INCOMING_MESSAGE':
      return { 
        ...state, 
        incomingMessage: payload
      }
    case 'UNREAD_MESSAGE':
      console.log(state)
      return { 
        ...state, 
        unreadCount: state.unreadCount+1
      }
    case 'SET_UNREAD':
      return { 
        ...state, 
        unreadCount: payload
      }
    default:
      return state
  }
}

export default chatData;