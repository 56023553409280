import React from 'react';

import styles from './GridView.module.scss';
import face from 'Assets/Avatars/face_shocked.svg'
import { Link } from 'react-router-dom';
import ActivityBlock from '../ActivityBlock/ActivityBlock';

const GridView = ({ filter, view, isEmpty, toggleModal, classObj, setClassObj }) => {

  const count = classObj.activities.length
  const currentActivities = classObj.activities.filter(i => !i.archived)
  const archivedActivities =  classObj.activities.filter(i => i.archived)
  // const currentActivities = classObj.activities.filter(i => ([2, 4, 5, 1, 21].indexOf(i.activityPhase) !== -1 && !i.archived))
  // const archivedActivities =  classObj.activities.filter(i => ([3, 7, 10, 8].indexOf(i.activityPhase) !== -1 && !i.archived))


  return ( 
    <> 
      {/* //responsive version below this div tag */}
      <div className={`${styles.activityBlocks} ${view==="list"&&styles.hide} ${isEmpty&&styles.centered}`}> 
        { 

              isEmpty ?
                <div className={styles.noActivities}>
                  <img src={face} alt="search boomer" />
                  <h3>Oops, it seems there's no activities to show yet</h3>
                  <Link to={"/"} className={styles.goBackBtn}>Go back to dashboard</Link>
                </div>


          :

              filter === 'current' ?
                
                  currentActivities.length ?

                    currentActivities.map(
                    (activity, idx) => <ActivityBlock
                      classObj={classObj}
                      setClassObj={setClassObj}
                      iconKey={idx}
                      count={count}
                      key={idx}
                      activity={activity}
                      toggleModal={toggleModal}
                      filter={filter} />
                    )

                  : <h6 className={styles.emptyTxt}>No active activities found</h6>
                  

          :

              filter === 'archived' ?

                  archivedActivities.length ? 

                    archivedActivities.map(
                      (activity, idx) => <ActivityBlock
                        classObj={classObj}
                        setClassObj={setClassObj}
                        iconKey={idx}
                        count={count}
                        key={idx}
                        activity={activity}
                        toggleModal={toggleModal}
                        filter={filter} />
                    )

                  : <h6 className={styles.emptyTxt}>No archived activities found</h6>


          :
          
              filter === 'classmates' ? <h6 className={styles.emptyTxt}>No classmates found</h6>

          : null

        }
      </div>

      <div className={styles.responsive}>
        <h4>Activities</h4>
          {
                
            currentActivities.length ?

              currentActivities.map(
              (activity, idx) => <ActivityBlock
                classObj={classObj}
                setClassObj={setClassObj}
                iconKey={idx}
                count={count}
                key={idx}
                activity={activity}
                toggleModal={toggleModal} />
              )

            : <h6 className={styles.emptyTxt}>No active activities found</h6>
            
          }

        {/* <h4>Completed</h4>
          {
                
            archivedActivities.length ?

              archivedActivities.map(
              (activity, idx) => <ActivityBlock
                classObj={classObj}
                setClassObj={setClassObj}
                iconKey={idx}
                count={count}
                key={idx}
                activity={activity}
                toggleModal={toggleModal} />
              )

            : <h6 className={styles.emptyTxt}>No complete active activities found</h6>

          } */}
      </div>
    </>

  )

}

export default GridView;