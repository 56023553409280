import React, { useEffect, useRef, useState } from 'react';
import { getFromApi } from 'API/API.service';

import MainLoader from 'Components/UtilityComponents/MainLoader/MainLoader';
import EditBoomer from './EditBoomer/EditBoomer';
import ProfileSettings from './ProfileSettings/ProfileSettings';
import ProfileSidebar from './ProfileSidebar/ProfileSidebar';

import styles from './Profile.module.scss';
import Canvas from './Canvas/Canvas';
import { useSelector } from 'react-redux';

const Profile = () => {

  const [ storeData, setData ] = useState(null)
  const [ showList, toggleList ] = useState(false)
  const [localConfig, setLocalConfig] = useState({})
  const [view, setView ] = useState('boomer')
  const [comparisonRef, setComparisonRef ] = useState(null)
  const [ base64Canvas, setBase64 ] = useState("")
  const [ fallback, setFallback ] = useState(null)
  const canvasRef = useRef()
  const reduxPostPurchase = useSelector(state => state.dashData.postPurchase)

  const getData = async () => setData(await  getFromApi(`store_items`))

  useEffect(()=>{ 
    if(!storeData){getData()} 
    // if(window.innerWidth<661){
    //   setView('settings');
    // }
  },[])

  const initiateLocal = () => {
    const dataObject = {
      avatarBody: {id:storeData.allAvatarItems.find(i=>i.id===parseInt(storeData.avatarJsons.body.body_id))?.id||108},
      items:[...storeData.avatarJsons.items]
    }
    setLocalConfig(dataObject)
    setComparisonRef(dataObject)
  }

  useEffect(()=>{ 
    if(storeData&&!reduxPostPurchase){ 
      initiateLocal() 
    }
   },[storeData])

  const toggleView = (input) => {
    initiateLocal()
    setTimeout(()=>{setView(input)},100)
  }


  return !storeData ? <MainLoader /> : (

    <>
      {/* <h5 onClick={()=>toggleList(!showList)} className={styles.toggle}>{showList?'show canvas':'show list'}</h5> */}
      {

        !showList ?
          <div className={`${styles.container} ${view==='boomer'&&styles.boomer}`}>

            <ProfileSidebar toggleView={toggleView} view={view} />
            <div className={styles.resTopBar}>

              <div className={styles.viewRadios}>
                <label>
                  <input onChange={() => toggleView('boomer')} checked={view === 'boomer'} type="radio" name="viewRadio" />
                  <div className={styles.viewRadio}>
                    Your Avatar
                  </div>
                </label>

                <label>
                  <input onChange={() => toggleView('settings')} checked={view === 'settings'} type="radio" name="viewRadio" />
                  <div className={styles.viewRadio}>
                    Settings
                  </div>
                </label>
              </div>
              
            </div>

            <div className={styles.main}>
              {
                view === "boomer" ? 

                  <EditBoomer 
                    view={view}
                    setStoreData={setData}
                    localConfig={localConfig}
                    setLocalConfig={setLocalConfig}
                    fallback={fallback}
                    setFallback={setFallback}
                    storeData={storeData}
                    canvasRef={canvasRef}
                    setBase64={setBase64} /> 
              :

                <ProfileSettings user={storeData.user} />
              }

            </div>

            <Canvas
              updateData={(input)=>{setData({...storeData, avatarJsons:input})}}
              comparisonRef={comparisonRef}
              localConfig={localConfig}
              canvasRef={canvasRef}
              base64Canvas={base64Canvas}
              view={view}
              fallback={fallback}
              storeData={storeData}/>
              

          </div>

        :
          <>
            <div className={styles.grid}>
              {storeData.allAvatarItems.map(item => 
                <div className={styles.listItem}>
                  <img src={item.url[0]} alt="" />
                  <p>ID: {item.id}</p>
                  <p>type: {item.type}</p>
                  <p>xOffset: {item.xOffset}</p>
                </div>
              )}
            </div>
          </>
      }
    </>

  )

}

export default Profile;


  {/* {base64Canvas.current && <img src={`data:image/png;base64, ${base64Canvas.current}`} alt="boomer" />} */}