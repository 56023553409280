import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import styles from './Nav.module.scss';
import homeIcon from 'Assets/Icons/home.svg'
import chatIcon from 'Assets/Icons/chat-sml.svg'
import calendarIcon from 'Assets/Icons/calendar.svg'
import logoutIcon from 'Assets/Icons/logout.svg'
import coins from 'Assets/Icons/coins.svg'
import ChatModal from 'Components/Chat/ChatModal';

const Nav = ({ toggleModal }) => {

  const {pathname} = useLocation()
  const boomerBase64 = useSelector(state => state.dashData.base64)
  const boomerBucksRedux = useSelector(state => state.dashData.boomerBucks)
  const unreadCount = useSelector(state => state.chatData.unreadCount)
  const user = JSON.parse(localStorage.getItem('user'))

  const isEditor = pathname === '/write'

  const handleChat = () => toggleModal(true, <ChatModal />, true )

  return (
    <div className={`${styles.navContainer} ${isEditor && styles.entry}`}>

      <div className={styles.navTop}>

        <div className={`${styles.userDetails} hideRes`}>
          <h6 className={styles.welcome}>Welcome,</h6>
          <h6 className={styles.welcome}>{user.studentName.split(' ')[0]}</h6>
          <div className={styles.coinsDiv}>
            <img src={coins} alt="coins" />
            <h6 className={styles.count}>{boomerBucksRedux ? boomerBucksRedux : user.boomerBucks}</h6>
          </div>
        </div>

        <NavLink 
          to={"/dashboard"} 
          className={`${styles.navLink} ${styles.borderBtm}`} activeClassName={styles.activeNav} >
          <img src={homeIcon} alt="home"/>
          <h5>HOME</h5>
        </NavLink>

        <div
          onClick={handleChat} 
          to={"/chat"} 
          className={`${styles.navLink} ${styles.borderBtm} hideRes`} activeClassName={styles.activeNav} >
          {unreadCount ? <div className={styles.unreadCount}><p>{unreadCount}</p></div>:null}
          <img src={chatIcon} alt="chat"/>
          <h5>CHAT</h5>
        </div>

        <NavLink 
          to={"/profile"} 
          id={styles.profile}
          className={`${styles.navLink}`} activeClassName={styles.activeNav} >
          <div className={styles.boomer}>
            <img className={`${styles.boomerImg} ${boomerBase64 && styles.large}`} src={!boomerBase64 ? '/avatarItems/108.png' : `data:image/png;base64, ${boomerBase64}`} alt="boomer"/>
            {/* <img className={styles.boomerImg} src={'/avatarItems/20.png'} alt="boomer"/> */}
          </div>
          <h5>PROFILE</h5>
        </NavLink>

      </div>


      <div className={styles.navBottom}>

        <NavLink 
          to={"/calendar"} 
          className={`${styles.navLink} ${styles.borderBtm} ${styles.borderLeftRes} ${styles.desktopOnly}`} 
          activeClassName={styles.activeNav} >
          <img src={calendarIcon} alt="calendar"/>
          <h5>CALENDAR</h5>
        </NavLink>

        <div 
          onClick={handleChat} 
          className={`${styles.navLink} ${styles.mobileOnly} ${styles.borderBtm} ${styles.borderLeftRes} ${styles.resChat}`} 
          activeClassName={`${styles.activeNav}`} >
          {unreadCount ? <div className={styles.unreadCount}><p>{unreadCount}</p></div>:null}
          <img src={chatIcon} alt="chat"/>
          <h5>CHAT</h5>
        </div>


        <NavLink 
          to={"/logout"}
          className={`${styles.navLink} ${styles.borderBtm}`} 
          activeClassName={styles.activeNav} >
          <img src={logoutIcon} alt="logout"/>
          <h5>LOGOUT</h5>
        </NavLink>


        <h6 className={'hideRes'}>Escribli</h6>

      </div>


    </div>
  )

}

export default Nav;