import styles from './Calendar.module.scss'

  export const eventClick = (info)  => {
    // console.log(info.event.extendedProps)
    const { activityIdx, classId } = info.event.extendedProps
    const frontUrl = localStorage.getItem('frontUrl')
    localStorage.setItem('sectionIdx', activityIdx)
    const URL = `${frontUrl}/class/${classId}`
    window.location = URL 
  }

  export const initCalendar = (classes, styles, setInitialEvents, setEvents, setClassLetters) => {

    const initEv = []; //events array to be populated
    const keyLetters = []; //letters array to be populated (used for the dot next to the class name in the sidebar)

    classes.forEach(item =>{

      const firstLetter = item.groupName.charAt(0);
      const keyLetter = 
        !keyLetters.includes(firstLetter) ? firstLetter //if 'A' does not exist in array, use:  A
        : !keyLetters.includes(`${firstLetter}${firstLetter}`) ? `${firstLetter}${firstLetter}` // AA
          : !keyLetters.includes(`${firstLetter}${firstLetter}${firstLetter}`) ? `${firstLetter}${firstLetter}${firstLetter}` // AAA
            : `${firstLetter}${firstLetter}${firstLetter}${firstLetter}`; // AAAA
      keyLetters.push(keyLetter);

      if(item.activities && item.activities!=='initial'){

        //sorting by pending count is necessary as the radios are sorted this way on the ListView screen, they must 
        //also be sorted here in order for the indexes to match so that click navigation takes us to the correpsonding section
        item.activities.sort((a,b)=>b.pendingCount-a.pendingCount).forEach((activity, idx) => {
          
          const liveSections = activity.sections?.filter(i => [1,21].indexOf(i.sectionPhase)===-1)
          const sectionTitle = liveSections[liveSections.length-1]?.sectionName !== null ? liveSections[liveSections.length-1]?.sectionName : 'section' ;
          const classKey = keyLetter.length> 1 ? `<p class=${styles.toThePower}>${keyLetter.charAt(0)}<sup>${keyLetter.length}</sup></p>` : keyLetter
  
          const payload = {
            title:activity.activityTitle,
            description:`${sectionTitle}`,
            id:activity.activityId,
            // start: new Date('9, 12, 21'),
            // end: new Date('9, 30, 21'),
            start: new Date(activity.activityStart),
            end: new Date(activity.activityDue),
            classId: item.classId,
            keyLetter: classKey,
            activityIdx:idx,
            activityId: activity.activityId,
            className: `${styles.event} ${styles[activity.activityType]} `,
          }
          
          initEv.push(payload)})
      }
    })
    setInitialEvents(initEv)
    setEvents(initEv)
    setClassLetters(keyLetters)
  }

  export const addDetailsElements = ({ event, el }) => {

    var newDiv = document.createElement('div')
    newDiv.innerHTML = `<p>${event._def.extendedProps.keyLetter}</p>`;
    newDiv.classList.add(styles.keyDot)
    el.appendChild(newDiv);

    if(el.children[0].classList[0] ==='fc-daygrid-event-dot'){return}

    const textDiv = el.children[0].children[0].children[0];
    var newSpan = document.createElement('span')
    newSpan.innerHTML = event._def.extendedProps.description;
    textDiv.appendChild(newSpan);
  };
