import React from 'react';
import { differenceInDays, isPast } from 'date-fns'

import styles from './PendingTasks.module.scss';
import calendarIcon from 'Assets/Icons/calendar2.svg'
import returnClassIcon from 'Utils/UI_elements/classIcon';
import SectionStatusPill from 'Components/ClassDetails/ActivitySectionsModal/SectionStatusPill/SectionStatusPill';
import { Link } from 'react-router-dom';
import { idxClass } from 'Utils/UI_elements/idxClass';
import SectionPhasePill from 'Components/UtilityComponents/SectionPhasePill/SectionPhasePill';

const PendingTasks = ({ tasks, tasksOpen }) => {


  const returnDateText = (endDate) => {

    const isFinished = isPast(new Date(endDate))
    const remainingDays = differenceInDays(new Date(endDate), new Date())
    const unitText = remainingDays!==1?'days':'day'
  
    return isFinished ? 'Finished' :
      remainingDays === 0 ? 'Ends tomorrow' : `${remainingDays} ${unitText} left`
  }


  return (
    <div className={`${styles.container} ${tasksOpen && styles.open}`}>
      {
        tasks?.map((task, idx)=>
          <Link to={`/write?activityId=${task.activityId}&s=${task.sectionIndex-1}`} key={idx} className={styles.taskRow}>
            <div className={styles.circleCont}>
              <div className={`${styles.circle} ${styles[idxClass(task.iconKey)]}`} />
            </div>
            <h5 className={styles.groupName}>{task.groupName}</h5>
            <div className={styles.left}>
              <div className={styles.text}>
                <p>{task.activityName}</p>
              </div>
                <div className={styles.chevron} />
            </div>
            <div className={styles.middle}>
              <div className={styles.book}>
                <h3>{task.sectionIndex}</h3>
              </div>
              <h3 className={styles.name}>{task.sectionName}</h3>
            </div>
            <div className={styles.pill}>
              {
                // [5,6,7,8].indexOf(task.sectionStatus) == -1 &&
                  <SectionPhasePill sectionStatus={task.sectionStatus} actions={[task.revisionPending?{kind:"revision"}:{kind:""}]}  />
              }
            </div>
          </Link>
        )
      }

    </div>
  )

}

export default PendingTasks;