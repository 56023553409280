import React, { useRef, useEffect, useState } from 'react';
import { getFromChatApi, sendToChatApi } from 'API/API.service';
import { isSameDay, isToday, isYesterday, format } from 'date-fns'

import styles from './ChatModal.module.scss';
import close from 'Assets/Icons/close_square.svg'
import arrow from 'Assets/Icons/arrow_teal.svg'
import loader from 'Assets/Loaders/spinner_teal.svg'
import { useSelector } from 'react-redux';
import { instancesInArray } from 'Utils/Array_funcs/instancesInArray';
import store from 'storeConfig';
import { setUnread } from 'Actions/chatDataActions';
import ChatMessageElement from './ChatMessageElement';
import { returnDefaultPic } from 'Utils/UI_elements/returnDefaultPic';


const ChatModal = ({ closeModal }) => {

  const inputRef = useRef()
  const innerRef = useRef()
  const chatsRef = useRef()
  const [ chatData, setChatData ] = useState(null)
  const [ activeChat, setActiveChat ] = useState(0)
  const boomerBase64 = useSelector(state => state.dashData.base64)
  const incomingMessage = useSelector(state => state.chatData.incomingMessage)

  const handleSelectChat = (input) => {

    if(window.innerWidth>660){
      setActiveChat(input)
    }
  }

  const handleSelectChatRes = (input) => {
    if(window.innerWidth<660){
      setActiveChat(input)
      chatsRef.current.classList.remove(styles.active)
    }
  }

  const getChats = async () => setChatData(await getFromChatApi(`student`))

  useEffect(()=>{ 

    getChats() 
    localStorage.setItem('chatModal', true)
    store.dispatch(setUnread(0))

    return () => localStorage.removeItem('chatModal')

  },[])

  //send message
  const handleSubmit = e => {
    e.preventDefault()

    const msgContent = inputRef.current.value
    const dataCopy = [...chatData]

    dataCopy[activeChat].messages.unshift({ content:msgContent, sentBy:2, timeStamp:new Date() })
    setChatData(dataCopy)
    sendToChatApi(`student/save`, {teacherId:chatData[activeChat].teacherId,content:msgContent})
    inputRef.current.value=""
    innerRef.current.scrollTo({top:innerRef.current.scrollHeight, behavior:"smooth"})

  }


  //mark messages as read upon switching chat
  useEffect(()=>{  
    if(chatData) {

      sendToChatApi(`student/mark_as_seen/${chatData[activeChat].teacherId}`)
      const dataCopy = [...chatData]
      dataCopy[activeChat].messages.forEach(i=>i.sentBy===1?i.seen=true:null)
      setChatData(dataCopy)

    }
  },[activeChat, chatData?.length])

  //live update upon receipt of transmission from websocket
  useEffect(()=>{  
    if(incomingMessage&&chatData) {
      const { teacher_name, body } = incomingMessage
      const teacherIdx = chatData.map(i=>i.teacherName).indexOf(teacher_name) 
      const dataCopy = [...chatData]
      dataCopy[teacherIdx].messages.unshift({ content:body, sentBy:1, seen: teacherIdx===parseInt(activeChat) })
      setChatData(dataCopy)
    }
  },[incomingMessage?.id])


  const renderUnread = (chat) => {
    const count = instancesInArray(chat.messages.filter(item => item.sentBy === 1), 'seen', false)
    return  count > 0 &&
      <div className={styles.unreadCount}> <p>{count}</p> </div>
  }

  const isNewDay = (d1, d2) => {
    if(d1&&d2) return !isSameDay(new Date(d1), new Date(d2))
  }

  const rendernewDayPill = (msg) => {
    return <div className={styles.dayPill}>
            <div className={styles.line} />
            <div className={styles.inner}>
              {
                  isToday(new Date(msg.timeStamp)) ? "Today"
                :
                  isYesterday(new Date(msg.timeStamp)) ? "Yesterday"
                :
                  format(new Date(msg.timeStamp), 'dd/MM/yyyy')
              }
            </div>
          </div>
  }
  

  return (
    <div className={styles.container}>

      <img
        onClick={closeModal}
        className={styles.close}
        src={close}
        alt="close" />

      <div ref={chatsRef} onChange={e=>handleSelectChat(e.target.value)} className={`${styles.chats} ${styles.active}`}>
        {
          !chatData ? 
              <div className={styles.loaderDiv}>
                <img src={loader} alt="spinner" />
                <p>Fetching chat data</p>
              </div>
          :
              chatData.map((chat, idx) => 
                <label key={idx}>
                  <input defaultChecked={idx===0} type="radio" name="selectedChat" value={idx}/>
                  <div onClick={e=>handleSelectChatRes(idx)} className={styles.chatRadio}>
                    <div className={styles.avatarDiv}>
                      {renderUnread(chat)}
                      <img src={chat.teacherAvatarUrl !== null ? chat.teacherAvatarUrl : returnDefaultPic(idx)} alt="avatar" />
                    </div>
                    <h4>{chat.teacherName}</h4>
                  </div>
                </label>
              )
        }

      </div>

      <div className={styles.messages}>
        {chatData &&
          <div onClick={() => chatsRef.current.classList.add(styles.active)} className={styles.resTopBar}>
            <img src={arrow} className={styles.arrow} alt="arrow" />
            <img className={styles.avatar} src={chatData[activeChat].teacherAvatarUrl ? chatData[activeChat].teacherAvatarUrl : returnDefaultPic(activeChat)} alt="teacher" />
            <h5>{chatData[activeChat].teacherName}</h5>
          </div>
        }
        <div ref={innerRef} className={styles.messagesInner}>
          {
            chatData &&
              chatData[activeChat]?.messages.map((msg,idx)=> {
                return <>
                        <ChatMessageElement
                          chatData={chatData}
                          activeChat={activeChat}
                          boomerBase64={boomerBase64}
                          key={idx}
                          idx={idx}
                          msg={msg} />
                            {idx === chatData[activeChat]?.messages.length-1&& rendernewDayPill(msg)}
                            {isNewDay(chatData[activeChat]?.messages[idx+1]?.timeStamp, msg.timeStamp)&&rendernewDayPill(msg)}
                        </>
              })
          }

        </div>

        <form onSubmit={e=>handleSubmit(e)} className={styles.inputDiv}>
          <div className={styles.messageInputDiv}>
            <input ref={inputRef} type="text" />
          </div>
          <button type="submit" className={styles.sendBtn}><span>Send</span></button>
        </form>

      </div>

    </div>
  )

}

export default ChatModal;