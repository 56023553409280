import React, { Component } from 'react';

import parse from 'html-react-parser';

import styles from './InlineHtml.module.scss';

const InlineHtml = ({ rawHtml = '' }) => {
  return rawHtml?.length ? 
    <div className={styles.container}>{parse(rawHtml)}</div>
    : null
}

export default InlineHtml;