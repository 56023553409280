import React, { useEffect, useState } from 'react';
import ItemRadio from './ItemRadio';
import styles from './Items.module.scss';
import VariantSelector from './VariantSelector/VariantSelector';
import nothing from 'Assets/Icons/nothing.png'
import nothing_white from 'Assets/Icons/nothing_white.png'
import { useSelector } from 'react-redux';


//function to extract the filename based on the URL
const returnFileName = (url) => url?.split('/')[url.split('/').length-1]


const Items = ({ avatarItems, localConfig, setLocalConfig, canvasRef, setBase64, itemFilter, variants, setVariants, view, ownedAvatarItems,storeData, setStoreData, fallback, setFallback }) => {

  let avatarBody = new Image();
  let avatarHair = new Image();
  let avatarTop = new Image();
  let avatarBottom = new Image();
  let avatarPet = new Image();
  let avatarAccessory = new Image();
  let avatarShoes = new Image();
  let avatarOutfit = new Image();
  let hair, top, bottom, pet, accessory, shoes, outfit
  let itemExists = localConfig.items.filter(i=>i.type===itemFilter.value)[0]
  const reduxPostPurchase = useSelector(state=>state.dashData.postPurchase)

  const [ itemsToShow, setItems ] = useState(avatarItems)

  useEffect(()=>{
    //hide variant selector when switching view
    setVariants(null)
  },[view])


  useEffect(()=>{ //update items to be displayed based on view and filter parameters
     
    if(view==="closet"&&itemFilter.value==="body"){
      //add all body items to available array when filtering by body in closet view
      setItems(avatarItems.filter(i=>i.type==="body"))
    }else{
      //otherwise display owned or un-owned items based on view
      setItems([]) //clear out array to avoid disappearing items bug
      setTimeout(()=>{ setItems(view==="store"?avatarItems:ownedAvatarItems?.filter(i=>i!==null)) },10)
    }

  },[itemFilter, view])


  //Update canvas according to data in localConfig object
  const updateCanvas = () => { 

    if(localConfig?.avatarBody){
      
      avatarBody.src = `/avatarItems/${localConfig.avatarBody.id}.png`
      avatarBody.onload = () => { drawCanvas() }

      hair = localConfig.items.filter(i=>i.type==='hair')[0]
      top = localConfig.items.filter(i=>i.type==='top')[0]
      bottom = localConfig.items.filter(i=>i.type==='bottom')[0]
      pet = localConfig.items.filter(i=>i.type==='pet')[0]
      accessory = localConfig.items.filter(i=>i.type==='accessory')[0]
      shoes = localConfig.items.filter(i=>i.type==='shoes')[0]
      outfit = localConfig.items.filter(i=>i.type==='outfits')[0]
      
      if(bottom) placeItem(bottom, avatarBottom, 'bottom')
      if(top) placeItem(top, avatarTop, 'top')
      if(hair) placeItem(hair, avatarHair, 'hair')
      if(accessory) placeItem(accessory, avatarAccessory, 'accessory')
      if(pet) placeItem(pet, avatarPet, 'pet')
      if(shoes) placeItem(shoes, avatarShoes, 'shoes')
      if(outfit) placeItem(outfit, avatarOutfit, 'outfits')
    }

  }

  //call updateCanvas when user changes localConfig
  useEffect(() => { updateCanvas() }, [localConfig]) 


  //place item on canvas
  const placeItem = (item, imgTag, string) => { 
    item = localConfig.items.filter(i=>i.type===`${string}`)[0]
    imgTag.src = `/avatarItems/${returnFileName(item?.url[item.tile-1])}`
    imgTag.onload = () => { drawCanvas() }
  }


  //render canvas, draw items on canvas
  const drawCanvas = () => {

    let canvas = canvasRef.current;
    let ctx = canvas.getContext("2d");
    canvas.width = 753;
    canvas.height = 900;
    ctx.fillStyle = "#ffffff00";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const drawItem = (imgTag, item) => {

      const xPos =  [131].indexOf(item?.id)!==-1 ? -1 : item?.xOffset
      const yPos =  item?.id===131 ? 12 : item?.yPos

      if(item?.type==="outfits"){
        //position calculations for outfits

        const outfitXpos = 
          item.id==142? 153 : 
          item.id==140? 144 : 
          item.id==143? 155 :
          item.id==154? 135 :
          item.id==152? 154 :
          150
          
        const outfitYpos = 
          item.id==144? 40 : 
          item.id==140? 45 : 
          item.id==143? 32 :
          35

        const outfitWidth = 
          item.id==142? 438 :
          item.id==143? 438 :
          item.id==152? 435 :
          item.id==154? 465 :
          450
        
        ctx.drawImage(
          imgTag, 
          outfitXpos,
          outfitYpos,
          outfitWidth,
          680,
        )
        

      } else {
        //generic positioning calc for all other items
        ctx.drawImage(
          imgTag, 
          (canvas.width/2)-(imgTag.width/2)+ parseInt(item?xPos-3:-4), 
          ((canvas.height/2)-(avatarBody.height/2)) + parseInt(item?yPos:0)
        )
      }
    }

    drawItem(avatarBody)
    drawItem(avatarBottom, bottom)
    drawItem(avatarTop, top)
    drawItem(avatarHair, hair)
    drawItem(avatarShoes, shoes)
    drawItem(avatarOutfit, outfit)
    drawItem(avatarAccessory, accessory)
    drawItem(avatarPet, pet)

    setBase64("loading")
    setTimeout(()=>{
      setBase64(canvasRef.current.toDataURL("image/png").split(';base64,')[1])
    },100)

  } 


  //if user selects an un-owned item in the store, save the current localConfig in fallback state
  //to be used to revert back to once finished previewing
  useEffect(()=>{
    if(variants&&view==="store") changeItem(itemFilter.value, variants, 1, true)

    //revert to fallback data when closing variants preview without purchaing
    if(!variants&&fallback&&!reduxPostPurchase){
      setLocalConfig(fallback)
      setFallback(null)
    }

  },[variants])


  //update localConfig based on user input via ItemRadio components
  const changeItem = ( type, newItem, tile, preview ) => {

    //enclosed copy of localConfig to be mutated and refered to within this function
    let prevConfig = {...localConfig}

    //update fallback object if previewing an item
    if(preview)setFallback(prevConfig)

    //if saint outfit is equipped, remove it before previewing/adding new item 
    //(unless item is a pet or an accessory)
    const outfitIdx = localConfig.items.map(i=>i.type).indexOf("outfits");
    // if(outfitIdx !== -1 && type!=="outfits" && ["pet","accessory"].indexOf(newItem.type)==-1){
    if(outfitIdx !== -1 && type!=="outfits") {
      prevConfig={...prevConfig, items:[]}
    }
    
    if(type=="body"){
      return setLocalConfig({...localConfig, avatarBody:newItem})
    }
      
    const index = localConfig.items.map(i=>i.type).indexOf(type)

    if(index!==-1){ //updatig existing item
      const newArray = [...prevConfig.items]
      if(tile)newItem.tile=tile
      newArray[index]=newItem
      setLocalConfig({...prevConfig, items:newArray})

    } else { //equiping new item
      if(tile)newItem.tile=tile
      setLocalConfig({...prevConfig, items:type!="outfits" ? [...prevConfig.items, newItem]:[newItem]})
    }
  }


  const removeItem = (input) => {
    const items = localConfig.items
    const itemIdx = items.map(i=>i.type).indexOf(!input ? itemFilter.value : input)
    setLocalConfig({...localConfig, items:[...items.slice(0, itemIdx), ...items.slice(itemIdx + 1)]})
  }

  //click hanlder varies depending on type of item
  const handleClick = (type, item) => {  
    // const outfitIdx = localConfig.items.map(i=>i.type).indexOf("outfits");
    // console.log(outfitIdx)
    
    // if(outfitIdx !== -1 && type!=="outfits"){
    //   console.log("outfitIdx")
    //   setFallback(localConfig)
    //   setLocalConfig({...localConfig, items:[]})
    //   console.log(localConfig)
    // }
    
    if(type==="body") return changeItem(type, item)
    setVariants(item)
  }

  return !itemsToShow || !itemsToShow.length ? null : (

      <div className={styles.itemsCont}>
        {
          !variants ? // initial view of all itmes

          itemsToShow.map((item, idx) =>
             item.type===itemFilter.value &&
                <ItemRadio
                  view={view}
                  ownedAvatarItems={ownedAvatarItems}
                  noPrice={view==="closet"||item.type==="body"}
                  localConfig={localConfig}
                  count={itemsToShow.filter(item=>item.type===itemFilter.value).length}
                  returnFileName={returnFileName}
                  idx={idx}
                  selected={false}
                  itemName={`${itemFilter.value}Radio`}
                  clickHandler={() => {handleClick(itemFilter.value, item)}}
                  // clickHandler={() => console.log(item)}
                  item={item} />
            )

          : // view of color variants for selected item

              <VariantSelector
                fallback={fallback}
                setFallback={setFallback}
                storeData={storeData} 
                setStoreData={setStoreData}
                boomerBucks={storeData.boomerBucks}
                purchase={view==="store"}
                localConfig={localConfig}
                setLocalConfig={setLocalConfig}
                avatarItems={avatarItems}
                itemFilter={itemFilter}
                returnFileName={returnFileName}
                setVariants={setVariants}
                changeItem={changeItem}
                item={variants} />

        }


      {   !variants && 
          view === "closet" && 
          itemFilter.value !== "body" && 
            <label className={styles.nothingLabel}>
              <input  type="radio" checked={!itemExists} />
              <div onClick={removeItem} className={`${styles.itemDiv} ${styles.nothing}`}>
                <img src={itemExists?nothing:nothing_white} alt="nothing" />
                <h5>NONE</h5>
              </div>
            </label>
      }

    </div>

  )

}

export default Items;








      //previously necessary offset-correction logic for selected avatar items 
      //offsets updated on DB, leaving this here just in case I need the IDs and offsets at any point

        // //some trousers appear to have incorrect xOffset vaules. 
        // //adjust xOffset for just these items using their IDs.
        // [32,35,61].indexOf(item?.id)!==-1? item?.xOffset-3 :

        // //also a large number of tops are poorly aligned. xOffset adjusted for those items.
        // [86,90,89,88,87,64,62,60,30,28,27,26,25,24,23,22].indexOf(item?.id)!==-1? item?.xOffset-5

        // //if ID not listed in above arrays, use the key from the back
        // : item?.xOffset