import { createConsumer } from '@rails/actioncable';
import { incomingMessage, incrementUnread } from 'Actions/chatDataActions';
import store from 'storeConfig';

export const defineConsumer = (token) => {

  const backUrl = localStorage.getItem('backUrl')
  const wsURL = backUrl.replace(/http/i, "ws"); // replace 'http' + 'https' with 'ws' + 'wss'

  const URL = `${wsURL}/cable?from=students&token=${token}`

  if(token!==undefined){return createConsumer(URL)}

}


export const initiateSocket = () => {

  const user = JSON.parse(localStorage.getItem('user'))

  const consumer = defineConsumer(localStorage.getItem('chatterToken'))

  console.log({
    channel: "StudentChannel",
    chatter_id: localStorage.getItem('chatterId'),
    username: user.studentName
  })

  consumer.subscriptions.create({
    channel: "StudentChannel",
    chatter_id: localStorage.getItem('chatterId'),
    username: user.studentName
  }, {
    connected: () => console.log('socket connected'),
    disconnected: () => console.log('socket disconnected'),
    received: data => handleMessage(data)
  })

}

const handleMessage = (data) => {

  const prevID = localStorage.getItem('msgID')

  if(prevID && prevID===data.message.id) return

  
  console.log('emission')
  console.log(data)
  
  if(localStorage.getItem('chatModal')){
    store.dispatch(incomingMessage(data.message))
    
  } else {
    store.dispatch( incrementUnread() )
  }
  
  localStorage.setItem('msgID', data.message.id)
  
}

