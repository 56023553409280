import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import styles from './DashSynod.module.scss';
import QuestionsTable from './QuestionsTable';

const DashSynod = () => {
  const location = useLocation()

  useEffect(()=>{
    console.log(window.location.host)
  },[])

  return (
    <div className={styles.container}>
      <div className={styles.tableDiv}>
        <QuestionsTable />
      </div>
    </div>
  )

}

export default DashSynod;