import React, { useEffect, useRef } from 'react';

import CommentElement from './CommentElement';

import styles from './EducatorComments.module.scss';
import bubble from 'Assets/Icons/comment_hover.svg'
import closeCross from 'Assets/Icons/close_cross.svg'
import CommentInput from './CommentInput';
import { useState } from 'react';

const EducatorComments = ({
    comments,
    commentsHaveCascaded,
    setCommentsHaveCascaded,
    activeSection,
    activityId,
    mobile,
    commentsRef,
    activity,
    setActivity
  }) => {

  const [ localComments, setLocalComments ] = useState(comments)
  const contRef = useRef()

  useEffect(()=>{
    contRef.current?.classList.remove(styles.display)
    setTimeout(()=>{ contRef.current?.classList.add(styles.display) },100)
    setTimeout(()=>{
      setCommentsHaveCascaded(true)
    },800)

  },[comments, setCommentsHaveCascaded])

  return (
    <div ref={contRef} className={`${styles.container} ${mobile && styles.mobile}`}>

      {
        !mobile &&

          <div className={styles.heading}>
            <img src={bubble} alt="speech bubble" />
            <h6>Educator Comments</h6>
          </div>
      }



      <div ref={mobile ? commentsRef : null} className={styles.commentsDiv}>
        {
          localComments.length ? 

            localComments.map((i, idx) => 
              <CommentElement
                key={idx}
                idx={idx}
                text={i.text}
                teacherName={i.teacherName}
                isStudent={i.isStudent}
                createdOn={i.createdOn}
                activeSection={activeSection}
                noAnim={commentsHaveCascaded}
                status={i.status}
                comments={comments} />)

          :
            <h5 className={styles.noComment}>No comments yet</h5>
        }
      </div>

      <CommentInput
        activityId={activityId}
        activeSection={activeSection}
        sectionIdx={activeSection.sectionIndex}
        setLocalComments={setLocalComments}
        localComments={localComments}
        activity={activity}
        setActivity={setActivity} />

    </div>
  )

}

export default EducatorComments;