import React, { useEffect, useRef } from 'react';
import { sendToApi } from '../../../API/API.service';
import { useSwipeable } from 'react-swipeable'

import styles from './ActivitySectionsModal.module.scss';

import close from 'Assets/Icons/close_square.svg'
import SectionList from '../SectionList/SectionList';

const ActivitySectionsModal = ({ closeModal, activity, classObj, noObject, setClassObj, viewAll, handleSelect, synod }) => {

  const contRef = useRef()
  let actionsCount = 0
  activity.sections.forEach(section => actionsCount+=section.actions.length)

  const isInactive = [2,4,5].indexOf(activity.activityPhase)===-1
  const count = activity.sections.filter(i=>[1,21].indexOf(i.sectionPhase)===-1).length
  const type = actionsCount > 6 || actionsCount == 0 ? 'blue' : 'white'  

  const markAsSeen = async () => {
    if(noObject)return
    const res = await sendToApi(`activity/${activity.activityId}/seen`)

    console.log(res)
    
    const activityIdx = classObj.activities.findIndex(i => i.activityId === activity.activityId )
    setClassObj({
      ...classObj,
      activities:[
        ...classObj.activities.slice(0,activityIdx),
        res,
        ...classObj.activities.slice(activityIdx+1),
      ]
      // [...arr2.slice(0,idx),666,...arr2.slice(idx+1)]
    })

  }
  useEffect(()=>{
    if (!viewAll) markAsSeen()
    setTimeout(()=>{contRef.current.classList.add(styles.show)},100)
  },[])

  const handleSwipe = () => {
    contRef.current?.classList.remove(styles.show)
    setTimeout(()=>{closeModal()},300)
  }

    const handlers = useSwipeable({
      onSwipedDown: () => handleSwipe(),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true
    });


  return activity && (
    <div ref={contRef} className={`${styles.container}`}>


      <img
        onClick={closeModal}
        className={styles.close}
        src={close}
        alt="close" />

        <div className={styles.inner}>

          <div className={styles.slideHandle} />

          <div className={`${styles.activityBlock} ${isInactive && styles.inactive}  ${styles[type]}`}>
              {/* <div className={styles.colorTab} /> */}
              <p className={styles.sections}>{count} {`section${count>1?"s":""}`}</p>
              <div className={styles.iconDiv} />
              <h5 >{activity.activityTitle}</h5>
          </div>
          
          <div className={styles.listDiv}>
            <SectionList synod={synod} closeModal={closeModal} handleSelect={handleSelect} viewAll={viewAll} activityId={activity.activityId} sections={activity.sections} modal />
          </div>

          <div  {...handlers} className={styles.swipeCatcher} />
        </div>


    </div>
  )

}

export default ActivitySectionsModal;