import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'

import styles from './Calendar.module.scss';
import { eventClick, addDetailsElements, initCalendar } from './calendarFuncs';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

const Calendar = ( ) => {

const history = useHistory()
const env = useSelector(state => state.env)
const [ events, setEvents ] = useState()
const [ classes, setClasses ] = useState([])
const [ initialEvents, setInitialEvents ] = useState([])
const [ classLetters, setClassLetters ] = useState(['A','B']); 

useEffect(()=>{ 
  const dashFeed = JSON.parse(localStorage.getItem('dashFeed'))
  if(!dashFeed) return history.push('/dashboard')
  setClasses(dashFeed.classes)
  initCalendar(dashFeed.classes, styles, setInitialEvents, setEvents, setClassLetters) 
},[])


  const handleClassFilter = (input) => {
    if(input === "all"){
      setEvents(initialEvents)
    } else {
      setEvents(initialEvents.filter(item => item.classId === parseInt(input)))
    }
  }

  const handleArrow = (input) => {
    const arrowBtn = input === 'next' ?
      document.getElementsByClassName('fc-next-button')[0] : document.getElementsByClassName('fc-prev-button')[0];
    arrowBtn.click()
  }

  return (
    <div className={styles.container}>

      <div className={styles.inner}>

        <div className={`${styles.leftBar}`}>
          <h2>Filter by class</h2>

          <div className={styles.radios}>

          <div className={styles.radiosInner} onChange={(e)=>{handleClassFilter(e.target.value)}}>
            <label>
              <input defaultChecked type="radio" name="classSelect" value="all"/>
              <div className={styles.classRadio}>
                <div className={styles.circleCont}>
                  <div className={styles.circle}></div>
                </div>
                <h3>All classes</h3>
              </div>
            </label>
              {classes && classes.length ? 
                classes.map((item, index) =>{
                  return(
                    <label>
                      <input type="radio" name="classSelect" value={item.classId}/>
                      <div className={styles.classRadio}>
                        <div className={styles.circleCont}>
                          <div className={styles.circle}></div>
                        </div>
                        <h3>{item.groupName}</h3>
                        <div className={styles.keyLetter}>
                          {classLetters && classLetters[index] ? 
                            classLetters[index].length> 1 ? 
                            <p className= {styles.toThePower}>{classLetters[index].charAt(0)}<sup>{classLetters[index].length}</sup></p> 
                              : <p> {classLetters[index]} </p>
                          :null}
                        </div>
                      </div>
                    </label>
                  )
                })
              :null}
          </div>
          </div>

        </div>
        <div className={styles.calendarDiv}>

          <div className={styles.arrows}>
            <div onClick={()=>{handleArrow('prev')}} className={styles.left}></div>
            <div onClick={()=>{handleArrow('next')}} className={styles.right}></div>
          </div>


              <div className={styles.legend}>
                <div className={styles.legendItem}>
                  <div className={`${styles.dot} ${styles.yellow}}`}></div>
                  <h5>Assignment</h5>
                </div>
                <div className={styles.legendItem}>
                  <div className={`${styles.dot} ${styles.blue}}`}></div>
                  <h5>Journal</h5>
                </div>
                <div className={styles.legendItem}>
                  <div className={`${styles.dot} ${styles.green}}`}></div>
                  <h5>Story</h5>
                </div>
              </div>
          

            <FullCalendar
              events= {events}
              plugins={[ dayGridPlugin ]}
              initialView="dayGridMonth"
              height='680px'
              eventClick={(info)=>eventClick(info, env)}
              fixedWeekCount={false}
              dayHeaderFormat={{weekday: 'long'}}
              displayEventTime={false}
              dayMaxEventRows={3}
              eventDidMount =  {addDetailsElements}
            />
        </div>

      </div>
      
    </div>
  )
}

export default Calendar;