import React from 'react';
import { Link } from 'react-router-dom';

import styles from './NoAccessError.module.scss';

const NoAccessError = () => {

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <h3>Unable to display activity</h3>
        <div className={styles.bodyText}>
          <div className={styles.icon}/>
          <div className={styles.text}>
            <p>You do not appear to have access to this activity.</p>
          </div>
        </div>
        <Link to={"/dashboard"} className={styles.dashBtn}>
          Go to dashboard
        </Link>
      </div>
    </div>
  )

}

export default NoAccessError;