import React, { useState } from 'react';
import { useEffect } from 'react';
import Masonry from 'react-masonry-css';
import { Redirect } from 'react-router';

import BoomerBlock from './BoomerBlock';
import styles from './DashboardPopulated.module.scss';
import DashClassBlock from './DashClassBlock';
import PendingTasks from './PendingTasks';

const DashboardPopulated = ({ dashFeed:{ classes, pendingTasks, base64 }, setDashFeed, dashFeed  }) => {

  const [ tasksOpen, toggleOpen ] = useState(false)


  return !classes ? <Redirect to={"/dashboard"} /> : (
    <div className={styles.container}>


      <BoomerBlock
        dashBase64={base64}
        dashFeed={dashFeed}
        setDashFeed={setDashFeed} />

      <div className={styles.main}>

        <div className={`${styles.classes}`}>
            <Masonry
            breakpointCols={{
              default: 3,
              1400: 2,
              780: 1,
            }}>
              
              {
                classes.sort((a,b)=>b.studentApproved-a.studentApproved).map((item, idx) => <DashClassBlock count={classes?.length} idx={idx} item={item} key={idx} /> )
              }
            </Masonry>
          </div>
        {pendingTasks.length > 0 &&
          <div className={`${styles.pendingTasks} ${tasksOpen && styles.open}`}>
            <div className={styles.text}>
              <h2>Tasks</h2>
              <div className={styles.numberCircle}>{pendingTasks.length}</div>
            </div>
            <PendingTasks tasksOpen={tasksOpen} tasks={pendingTasks} />
            <div onClick={() => toggleOpen(!tasksOpen)} className={styles.viewAllBtn}>{!tasksOpen ? "View all tasks" : "View less"}</div>
          </div>
        }

      </div>

    </div>
  )

}

export default DashboardPopulated;
