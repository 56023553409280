import React from 'react';

import styles from './ActivityRadio.module.scss';

const ActivityRadio = ({ defaultChecked, activity: { activityTitle, sections, activityId, pendingCount }, idx, toggleActivity, modal, selectedActivity}) => {

  let actionsCount = 0
  sections.forEach(section => actionsCount+=section.actions.length)

  const type = pendingCount > 0 ? 'white' : 'blue'   

  return (
    <label className={` ${modal&&styles.modal}`} key={idx}>
      <input  onChange={()=>toggleActivity(idx)} defaultChecked={defaultChecked} type="radio" name={!modal?"activity":"activityModal"} value={idx} />
      <div onClick={()=>localStorage.setItem('sectionIdx', idx)} className={`${styles.radioDiv} ${styles[type]}`}>
        {type==='white'&&pendingCount>0 && <div className={styles.numberDot}><p>{pendingCount}</p></div> }
        <div className={styles.iconDiv} />
        <h5>{activityTitle}</h5>
      </div>
    </label>
  )

}

export default ActivityRadio;