import React, { useEffect, useRef } from 'react';
import { changeItemIndex } from 'Utils/Array_funcs/changeItemIndex';
import { Carousel } from '3d-react-carousal'

import styles from './CarouselModal.module.scss';
import closeIcon from 'Assets/Icons/close_circle.svg'
import spinner from 'Assets/Loaders/spinner_white_fast.svg'
import { useSwipeable } from 'react-swipeable'

const CarouselModal = ({ closeModal, images, idx }) => {
  
  const contRef = useRef()
  const loaderRef = useRef()

  //put selected image at index zero
  let ordered = changeItemIndex(images, idx, 0)

  //hack necessay for weird bug when images array has exactly 2 images - double the array to 4 items
  ordered = ordered.length === 2 ? [ordered[0], ordered[1], ordered[0], ordered[1]] : ordered


  const handlers = useSwipeable({
    onSwipedLeft: () =>  document.getElementsByClassName("slider-right")[0].click(),
    onSwipedRight: () =>  document.getElementsByClassName("slider-left")[0].click(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });


  const keyListener = (e) => {
    console.log(e)
    switch(e.key){
      case 'ArrowRight':
        document.getElementsByClassName("slider-right")[0].click()
        break
      case 'ArrowLeft':
        document.getElementsByClassName("slider-left")[0].click()
        break
      case 'Escape':
        closeModal()
        break
      default: return
    }
  }
  
  useEffect(()=>{

    window.addEventListener('keydown', keyListener, false )

    setTimeout(()=>{
      
      if(loaderRef.current)loaderRef.current.classList.remove(styles.display)
      if(contRef.current)contRef.current.classList.add(styles.display)
      
      if(images.length===1){
        //hide arrows for single-img arrays
        const leftArrow =  document.getElementsByClassName("slider-left")[0]
        const rightArrow =  document.getElementsByClassName("slider-right")[0]
        if(leftArrow)leftArrow.style.opacity="0"
        if(rightArrow)rightArrow.style.opacity="0"
      }
      
    },1000)

     return () => window.removeEventListener('keydown', keyListener, false )

    // eslint-disable-next-line
  },[])
  
  let slides = ordered.map((i, idx)=> <img style={{pointerEvents:"none"}} src={i} alt={idx+1} />);

  return (
    <>
      <div {...handlers} ref={contRef} className={styles.container}>
        <img onClick={closeModal} className={styles.closeIcon} src={closeIcon} alt="close" />
        <Carousel slides={slides}/>
      </div>

      <div ref={loaderRef} className={`${styles.loaderDiv} ${styles.display}`}>
        <img className={styles.spinner} src={spinner} alt="spinner" />
      </div>
    </>
  )

}

export default CarouselModal;