import React, { useEffect } from 'react';
import { useState } from 'react';

import styles from './ProfileSettings.module.scss';
import loader from 'Assets/Loaders/spinner_teal.svg'
import tick from 'Assets/Icons/tick_green_circle.svg'
import { useRef } from 'react';
import { addAnimation } from 'Utils/UI_elements/addAnimation';
import { sendToApi } from 'API/API.service';

const ProfileSettings = ({ user: {firstName, lastName, username, grade, accessCode, uniqLoginUrl}}) => {

  const [ confirmed, setConfirmed ] = useState(false)
  const [ changePassword, setChangePassword ] = useState(false)
  const [ error, setError ] = useState(null)
  const [ showConf, setShowConf ] = useState(null)
  const contRef = useRef()
  const loaderRef = useRef()
  const oldPasswordRef = useRef()
  const oldPasswordRow = useRef()
  const passwordRef = useRef()
  const confirmRef = useRef()
  const inputsRef = useRef()
  const changeRef = useRef()
  const errorRef = useRef()

  const handleSave = async () =>{
    
    const {message} = await sendToApi(`change_password?oldPassword=${!accessCode?oldPasswordRef.current.value:accessCode}&newPassword=${passwordRef.current.value}`)
    loaderRef.current.classList.remove(styles.hide)

    setTimeout(() => {
      if (message === "success") {
        setTimeout(() => { loaderRef.current.classList.remove(styles.hide) }, 30)
        setConfirmed(true)
        setTimeout(() => { setChangePassword(false) }, 3500)

      } else {
        contRef.current.scrollBy({ top: contRef.current.scrollHeight, behavior: "smooth" })
        loaderRef.current.classList.add(styles.hide)
        setError(message)
        passwordRef.current.value = ""
        confirmRef.current.value = ""
        if(oldPasswordRef.current)oldPasswordRef.current.value = ""
      }
    }, 1000)
  }

  useEffect(()=>{

    setError(null)
    if(changePassword){
        changeRef.current.classList.add(styles.showChange)
    }

  },[changePassword])

  useEffect(()=>{
    if(error){
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

  },[error])

  const submitPassword = () => {
    
    setConfirmed(false)

    if(!accessCode&&oldPasswordRef.current.value===""){
      addAnimation(oldPasswordRow, 'shakeSlow', 600)
      setError('Please enter your current password')
    }
    else if(passwordRef.current.value===""||confirmRef.current.value===""){
      addAnimation(inputsRef, 'shakeSlow', 600)
      setError('Please complete all fields')
    }
    else if(passwordRef.current.value!==confirmRef.current.value){
      addAnimation(inputsRef, 'shakeSlow', 600)
      setError('Passwords do not match')
    }
    else if(passwordRef.current.value.length<5){
      addAnimation(inputsRef, 'shakeSlow', 600)
      setError('Passwords must be 5 characters or more')
    }
    else{
      handleSave()
    }


  }

  const handleCopy = (e) => {
    setShowConf(true);
    e.preventDefault();    
    setTimeout(()=>{setShowConf(false)},2000)
    navigator.clipboard.writeText(uniqLoginUrl)
}

  return (
    <div id={"cont"} ref={contRef} className={styles.profileSettings}>
      <h2>My Details</h2>
      <div className={`${styles.row}`}>
        <label className={styles.half}>
          <p>First Name</p>
          <input readOnly type="text" value={firstName} />
        </label>
        <label className={styles.half}>
          <p>Last Name</p>
          <input readOnly type="text" value={lastName}/>
        </label>
      </div>

      <div className={`${styles.row}`}>
        <label className={styles.three}>
          <p>Username/email</p>
          <input readOnly type="text" value={username} />
        </label>
        <label className={styles.one}>
          <p>Grade</p>
          <input readOnly type="text" value={grade} />
        </label>
      </div>
      {/* {
        accessCode &&
          <div className={`${styles.row}`}>
            <label className={styles.full}>
              <p>Access code</p>
              <input readOnly type="text" value={accessCode} />
            </label>
          </div>
      } */}

      <div className={`${styles.row}`}>
        <label className={styles.full}>
          <p>Login link <span>Use this link to log directly in to your account</span> </p>

          <div className={styles.inputDiv}>
            <p>{uniqLoginUrl}</p>
            
            <div onClick={handleCopy} className={styles.copyDiv}>
              <p>{!showConf ? "Copy link" : "Copied!"}</p>
            </div>

          </div>
        </label>
      </div>

      {
        !changePassword ?

          <p onClick={()=>setChangePassword(true)} className={styles.changePassword}>{!accessCode ? "Change password" : "Setup password"}</p>

        :

          <div ref={changeRef} className={styles.change}>

           {
            !accessCode &&
              <div ref={oldPasswordRow} className={`${styles.row}`}>
                <label className={styles.full}>
                  <p>Old password</p>
                  <input onChange={() => setError(null)} ref={oldPasswordRef} autoFocus className={styles.editable} type={"password"} />
                </label>
              </div>
            }
            <div ref={inputsRef} className={styles.inputs}>
              <div className={`${styles.row}`}>
                <label className={styles.full}>
                  <p>New password <span>(minimum 5 characters)</span></p>
                  <input onChange={() => setError(null)} ref={passwordRef} className={styles.editable} type={"password"} />
                </label>
              </div>
              <div className={styles.row}>
                <label className={styles.full}>
                  <p>Repeat password</p>
                  <input onChange={() => setError(null)} ref={confirmRef} className={styles.editable} type={"password"} />
                </label>
              </div>
            </div>


            <div className={styles.buttons}>
              <p onClick={() => setChangePassword(false)} className={styles.cancelBtn}>Cancel</p>
              <div onClick={submitPassword} className={styles.confirmBtn}>Save new password</div>
            </div>
            {error && <p ref={errorRef} className={styles.error}>{error}</p>}



            <div ref={loaderRef} className={`${styles.loadingOverlay} ${confirmed&&styles.confirmed} ${styles.hide}`}>

              {
                !confirmed ? 
                  <>
                    <img src={loader} alt="loader" />
                    <h6>loading</h6>
                  </>
                :
                  <>
                    <img className={styles.tick} src={tick} alt="loader" />
                    <h6>Password succesfully {!accessCode?"changed":"setup"}!</h6>
                  </>
                
              }
            </div>
          </div>
      }

    </div>
  )

}

export default ProfileSettings;