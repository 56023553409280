import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

import styles from './SubmitEntryModal.module.scss';
import submitGreen from 'Assets/Icons/submit_entry_green.png'
import submitBlue from 'Assets/Icons/submit_entry_blue.png'
import coins from 'Assets/Icons/coins.svg'
import closeIcon from 'Assets/Icons/close_square.svg'
import { sendToApi } from 'API/API.service';
import { Link } from 'react-router-dom';
import store from 'storeConfig';
import { updateBoomerBucks } from 'Actions/dashDataActions';

const SubmitEntryModal = ({ synod, closeModal, activeSection:{sectionStatus}, staticPhase, toggleBtnZindex, updateSectionKey, sectionIndex, activityId, inputHTML }) => {

  const [ stage, setStage ] = useState(1)

  const isResubmission = [ 6, 7, 8 ].indexOf(staticPhase) !== -1 

  const icon = stage === 1 ? submitBlue : submitGreen

  const mainText_header = 
    stage === 1 && !isResubmission ? "Submit entry?" :
    stage === 1 && isResubmission ? "Re-submit entry?" : 
    "Entry submitted!" //phase 2 - confirmation
    
  const mainText_action =
    !isResubmission ? "submit" : "re-submit"

  const handleClose = () => {
    toggleBtnZindex('remove')
    closeModal()
  }

  useEffect(()=>{//cancel autoSave timer upon opening modal in order to avoid double-saving
    localStorage.setItem('noAutoSave', true) 
    setTimeout(()=>{localStorage.removeItem("noAutoSave")},10000) //remove key from LS after 10s

    console.log(sectionStatus)
  },[])

  useEffect(()=>{
    console.log('mount')
    console.log(sectionStatus)
    console.log(isResubmission)
    return()=>console.log('unmount')
  },[sectionStatus])

  const handleSubmit = () => {

    setStage(2) //modal UI stage (show confirmation stage)

    updateSectionKey('entryHTML', inputHTML)
    updateSectionKey('sectionStatus', 6) //'submitted'
    updateSectionKey('submitted', true) //'submitted'

    if(!isResubmission){store.dispatch(updateBoomerBucks(10))}

    sendToApi(`activity/${activityId}/${sectionIndex}?submit=true`, {entry:DOMPurify.sanitize(inputHTML)})
    
  }

  return (

    <div className={`${styles.container}`}>

      <img className={styles.close} onClick={handleClose} src={closeIcon} alt="close" />

      <div className={styles.images}>
        {/* <img className={styles.boomer} src={boomer} alt="boomer" /> */}
        <img className={styles.icon} src={icon} alt="icon" />
      </div>

      <h2>{mainText_header}</h2>

      {

        stage === 1 ? 

          <h5>Are you sure you want to {mainText_action} your entry?</h5>

        : stage === 2 && !isResubmission && !synod && //conf stage of modal, first submission of entry, not Synod user
          
          <div className={styles.coinsMessage}>
            <div className={`${styles.row}`}>
              <h5>You earned</h5>
              <img src={coins} alt="coins" />
              <h5>2!</h5>
            </div>
            <p>You will earn 10 more once your entry is approved.</p>
          </div>
        

      }

      <div className={`${styles.buttons} ${stage === 2 && styles.hideRes} ${stage === 2 && isResubmission && styles.hide}`}>
        {
          stage === 1 ?

            <>
              <div onClick={handleSubmit} className={styles.btnOne}> Submit <span className={styles.hideRes}>my entry</span> </div>
              <div onClick={handleClose} className={styles.btnTwo}> Not yet </div>
            </>

          : 
            
              !synod ?
            
                <Link to={"/profile"} className={`${styles.btnOne} ${styles.hideResBtn}`}> Hit the store </Link>
              :
                <div onClick={()=>closeModal()} className={`${styles.btnOne} ${styles.hideResBtn}`}>OK</div>
        }
      </div>

    </div>

  )

}

export default SubmitEntryModal;





// phase === 0 ? 'Start Writing' :
// phase === 1 ? 'Start Writing' :
// phase === 2 ? 'Writing' :
// phase === 3 ? 'Start voting' :
// phase === 4 ? 'Voting' :
// phase === 5  ? 'Voted' :
// phase === 6 ? 'Submitted' :
// phase === 7 ? 'Reviewing' :
// phase === 8 ? 'Completed' : ''
