import React from 'react';

import styles from './MainLoader.module.scss';
import loader from 'Assets/Loaders/loader-dots.svg'

const MainLoader = ({ noNav }) => {

  return (
    <div className={`${styles.loaderWrapper} ${noNav && styles.noNav}`}>
      <img className={styles.loader} src={loader} alt="loader" />
    </div>
  )

}

export default MainLoader;