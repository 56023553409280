import React, { useEffect, useState } from 'react';
import { getFromApi } from 'API/API.service';
import { initiateSocket } from 'API/Socket/socket';
import store from 'storeConfig';
import MainLoader from 'Components/UtilityComponents/MainLoader/MainLoader';
import { setUnread } from 'Actions/chatDataActions';
import { addDashData, updateBoomerBase64 } from 'Actions/dashDataActions';
import { useSelector } from 'react-redux';

import styles from './Dashboard.module.scss';
import DashboardNoClasses from './DashboardNoClasses/DashboardNoClasses';
import DashboardPopulated from './DashboardPopulated/DashboardPopulated';

const Dashboard = () => {

  const [ dashFeed, setDashFeed ] = useState(null)  
  const reduxBase64 = useSelector(state=>state.dashData.base64)

  useEffect(()=>{
    if(dashFeed&&!reduxBase64){
      console.log('one')
      store.dispatch(updateBoomerBase64(dashFeed.base64))
    }
    if(dashFeed) {
      console.log('two')
      initiateSocket();
      if(!localStorage.getItem('unreadCountSession')){
        store.dispatch(setUnread(dashFeed.unreadChatCount))
        localStorage.setItem('unreadCountSession',true)
      }
    }
  },[dashFeed])

  const getDashFeed = async () => {
    const res = await getFromApi('dashboard')
    localStorage.setItem('dashFeed', JSON.stringify(res))
    setDashFeed(res)
    store.dispatch(addDashData(res))
    localStorage.setItem('blockDashRecall', true)
    setTimeout(()=>{localStorage.removeItem('blockDashRecall')},7000)
  }

  useEffect(()=>{

    const localStorageDashFeed = localStorage.getItem('dashFeed')

    if(localStorageDashFeed && localStorageDashFeed !== 'undefined'){
      // console.log(localStorageDashFeed)
      // console.log(localStorageDashFeed==='undefined')
      // console.log(localStorageDashFeed===undefined)
      const lsFeed = JSON.parse(localStorageDashFeed)
      return setDashFeed(lsFeed)
    }
    // eslint-disable-next-line
  },[])


  useEffect(()=>{

    console.log('fetch dash feed')
    getDashFeed()
      
    // eslint-disable-next-line
  },[])


  return !dashFeed ? <MainLoader /> : (

    <section className={styles.dashboardContainer}>

      { dashFeed.classes?.length ? 

          <DashboardPopulated 
            getDashFeed={getDashFeed}
            dashFeed={dashFeed}
            setDashFeed={setDashFeed}/> 
        
        : 
        
          <DashboardNoClasses
            dashFeed={dashFeed}
            setDashFeed={setDashFeed} /> 
          
      }

      
      
    </section>
  )

}

export default Dashboard;