import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../ClassDetails.module.scss';
import arrow from 'Assets/Icons/arrow_blue_left.svg';
import ViewRadios from '../ViewRadios/ViewRadios';
import ClassmatesModal from '../ClassmatesModal/ClassmatesModal';

const DetailsTopBar = ({ classObj:{ groupName, students }, filter, setFilter, view, toggleView, toggleModal }) => {

  const showClassmatesModal = () => toggleModal(true, <ClassmatesModal students={students} groupName={groupName} /> )
  return (
    <div className={`${styles.topBar} ${view === 'grid' && styles.tall}`}>

        <div className={styles.titleBar}>
          <Link to={"/"}><img className={styles.arrow} src={arrow} alt="arrow" /></Link>

          <h3>{groupName}</h3>
          {
          (students?.length && typeof(students) !== 'string') &&
          <div className={styles.classMates}>
            <div style={{width:`${students.length * 35}px`, minWidth: '48px', maxWidth: `135px`}} className={styles.avatars}>
              {
                students?.map((student, idx) =>
                  idx <= 3 &&
                    <div onClick={showClassmatesModal} style={{left:`-${15*idx}px`, zIndex:`${students.length - idx}`}}>
                      <img src={student.avatarUrl} alt="avatar" key={idx} />
                      <div className={styles.tooltip}>{student.studentName}</div>
                    </div>
                )
              }
            </div>
              {
                students.length > 4 ?
                  <div 
                  onClick={showClassmatesModal}
                   className={styles.moreStudents}>
                    <span className={styles.circle}>+{students.length - 4}</span>
                    <span className={styles.oval}>view all</span>
                  </div>
                :
                  <div 
                  onClick={showClassmatesModal}
                   className={`${styles.moreStudents} ${styles.viewAll}`}>
                    <span className={styles.oval}>view all</span>
                  </div>
              }
          </div>
        }

          <div className={styles.right}>
            {/* <ViewRadios view={view} toggleView={toggleView} /> */}
          </div>

        </div>

      {
        view === 'grid' &&
          <div className={styles.radios}>
            <label>
              <input onChange={e=>setFilter(e.target.value)} checked={filter==="current"} type="radio" name="filter" value="current" />
              <div className={styles.filterRadio}> <h6>Active</h6> </div>
            </label>
            <label>
              <input onChange={e=>setFilter(e.target.value)} checked={filter==="archived"} type="radio" name="filter" value="archived" />
              <div className={styles.filterRadio}> <h6>Archived</h6> </div>
            </label>

          </div>
      }
      </div>
  )

}

export default DetailsTopBar;