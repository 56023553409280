const userType = (state= "", action) => {

  const { type, payload } = action

  switch(type){
    case 'SET_USER_TYPE': 
      return payload
    default:
        return state
  }
}

export default userType;