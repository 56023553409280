import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import returnSectionIcon from 'Utils/UI_elements/returnSectionIcon';

import styles from './DashActivityBlock.module.scss';

const DashActivityBlock = ({ count, idx, isOpen, classId, activity:{ activityTitle, sections, activityId, pendingCount } }) => {

  const contRef= useRef()

  const idxToLocalStorage = () => localStorage.setItem('sectionIdx', idx)

  useEffect(()=>{
    
    const offset = Math.floor(Math.random() * (count < 5 ? count : 4)) * 100

    setTimeout(()=>{
      contRef.current?.classList[isOpen?'add':'remove'](styles.display)
    },offset)
    
  },[isOpen])

  return (
    <Link onClick={idxToLocalStorage} ref={contRef} to={`/class/${classId}`} className={styles.container}>
      <div className={styles.iconDiv}>
        <img src={returnSectionIcon(idx)} alt="book icon" />
      </div>
      <div className={styles.text}>
        <h5>{activityTitle}</h5>
        <h6>{sections.length} sections</h6>
      </div>

      {pendingCount > 0 && <div className={styles.pendingDot}><p>{pendingCount}</p></div>}
    </Link>
  )

}

export default DashActivityBlock;