import { getFromApi } from 'API/API.service';
import React, { useEffect, useState } from 'react';
import QuestionRow from './QuestionRow';
import loader from 'Assets/Loaders/spinner_teal.svg'

import styles from './QuestionsTable.module.scss';
import MainLoader from 'Components/UtilityComponents/MainLoader/MainLoader';

const QuestionsTable = () => {

  const [ activityData, setActivityData ] = useState(null)
  const getClassDetails = async () => {
    const res = await getFromApi(`activity/${localStorage.getItem('defaultActivityId')}`)
    setActivityData(res)
  }

  useEffect(()=>{
    getClassDetails()
  },[])

  return (
    <div className={`${styles.container} ${activityData && styles.border}`}>

      {
        !activityData ? 

          <div className={styles.loaderDiv}>
            <img src={loader} />
          </div>

        :

        <>
          <div className={styles.header}>
            <div className={styles.column}>
              <h5>{activityData.activityTitle}</h5>
            </div>
            <div className={styles.column}>
              <h5>Words</h5>
            </div>
            <div className={styles.column}>
              <h5>Actions</h5>
            </div>
          </div>
          {
            activityData?.sections.map((section, idx) => 
              <QuestionRow rowData={section} idx={idx} key={idx} activityId={activityData.activityId} />
            )
          }
        </>
      }
    </div>
  )

}

export default QuestionsTable;