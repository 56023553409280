import React, { useEffect, useRef, useState } from 'react';
import Instructions from 'Components/StudentEntry/EntrySidebar/OpenContent/Instructions/Instructions';
import EntryTextEditor from 'Components/StudentEntry/EntryTextEditor/EntryTextEditor';
import EntryTopBarResponsive from './EntryTopBarResponsive/EntryTopBarResponsive';

import styles from './StudentEntryResponsive.module.scss';
import EducatorComments from 'Components/StudentEntry/EntrySidebar/OpenContent/EducatorComments/EducatorComments';
import BreakoutRooms from 'Components/StudentEntry/EntrySidebar/OpenContent/BreakoutRooms/BreakoutRooms';
import StudentVoting from 'Components/StudentEntry/StudentVoting/StudentVoting';
import { stripTags } from 'Utils/stripTags';
import ReactQuill from 'react-quill';
const wrapper = document.getElementById("bodyWrapper")

const StudentEntryResponsive = ({ hideVotingComp, privateMode, activeSection, 
  setActiveSection, localPrivate, setLocalPrivate, toggleModal, updateSectionKey, 
  markAsRead, activity, forceWriting, setForceWriting, sidebarOpen }) => {

  const [ view, toggleView ] = useState('instructions')
  const [ inView, setInview ] = useState('instructions')
  const [ isVoting, toggleVoting ] = useState([1,2,6,7,8].indexOf(activeSection.sectionPhase) === -1 && !activeSection.votingData.voted)
  const contRef = useRef()
  const instructionsRef = useRef()
  const entryRef = useRef()
  const commentsRef = useRef()
  const mobileQuillRef = useRef()
  const editorRef = useRef()

  const handleScroll = () => {
    if(activeSection.storyStart)return
    if(instructionsRef.current.getBoundingClientRect().bottom > 300 && inView!== "instructions") setInview('instructions')
    if((entryRef.current.getBoundingClientRect().top < 200)&& (contRef.current.scrollTop < (contRef.current.scrollHeight - contRef.current.offsetHeight))) setInview('entry')
    if((contRef.current.scrollTop >= (contRef.current.scrollHeight - contRef.current.offsetHeight))  && inView!== "comments") setInview('comments')
  }

  useEffect(()=>{
    console.log(activeSection.storyStartContents)
    let yOffset = 0
    if(view == "entry") yOffset = entryRef.current.getBoundingClientRect().top
    if(view == "comments") yOffset = contRef.current?.scrollHeight
    contRef.current?.scrollTo({top: yOffset, behavior: 'smooth'});
  },[view])

  const toggleMobileEditor = action =>{
    editorRef.current?.classList[action](styles.show);
    if(action=="add")mobileQuillRef.current.focus()
  }


  const gradeClass =
    !activeSection.finalGrade || activeSection.finalGrade == 0 ? 'hidden' :
    activeSection.finalGrade>0&&activeSection.finalGrade <2 ? 'one' :
    activeSection.finalGrade>=2&&activeSection.finalGrade <3 ? 'two' :
    activeSection.finalGrade>=3&&activeSection.finalGrade <4 ? 'three' :
    activeSection.finalGrade===4 ? 'four' : null

  useEffect(()=>{
    toggleVoting([1,2,6,7,8].indexOf(activeSection.sectionPhase) === -1 && !activeSection.votingData.voted)
  },[activeSection?.sectionPhase])

  const handleOpen = (input) => {
    if(input){toggleView(input)}
  }

  return (
    <div className={styles.container}>

        <EntryTopBarResponsive
          activity={activity}
          isVoting={isVoting}
          updateSectionKey={updateSectionKey}
          toggleView={toggleView}
          setInview={setInview}
          inView={inView}
          view={view}
          localPrivate={localPrivate}
          activeSection={activeSection}
          toggleModal={toggleModal} />

          <div className={`${styles.spacer}`} />

        {
          !isVoting || forceWriting ?


            // view === 'entry' ?
            <div ref={contRef} onWheel={handleScroll} className={`${styles.sections}`} >

              {
                activeSection.storyStart ? 
                  <textarea className={`${styles.storyStart}`}>
                    {activeSection.storyStartContents}
                  </textarea>
                  :
                  <>
                    <div ref={instructionsRef}>
                      <Instructions
                        mobile
                        closeMobile={()=>handleOpen(false)}
                        activeSection={activeSection}/>
                    </div>

                    <div className={`${styles.break}`} />

                    <div ref={entryRef} className={`${styles.entryPreview}`}>
                      <div className={`${styles.topRow}`}>

                        <div className={`${styles.grade} ${styles[gradeClass]}`}>
                          <h6>Grade</h6>
                          <div className={`${styles.gradeDiv}`}>
                            <h5>{activeSection.finalGrade}</h5>
                          </div>
                        </div>

                        <div className={`${styles.wordCount}`}>
                          <h5>Words: {stripTags(activeSection.entryHTML).split(' ')[0] == '' ? 0 : stripTags(activeSection.entryHTML).split(' ').length}</h5>
                        </div>
                      </div>

                      <div className={`${styles.textDiv}`}>
                        <ReactQuill
                          readOnly
                          modules={{toolbar:false}}
                          theme="snow"
                          value={activeSection.entryHTML}
                          placeholder={activeSection.sectionPhase > 2 ? "No entry submitted": "Write your entry"}>
                        </ReactQuill>
                      </div>
                      <div onClick={()=>toggleMobileEditor("add")} className={`${styles.editBtn}`}>Edit</div>
                    </div>

                    <div className={`${styles.break}`} />

                    <EducatorComments
                      comments={activeSection.comments}
                      activityId={activity.activityId}
                      closeMobile={()=>handleOpen(false)}
                      commentsHaveCascaded={false}
                      setCommentsHaveCascaded={()=>{return}}
                      activeSection={activeSection}
                      commentsRef={commentsRef}
                      mobile />


                  {/* : view === 'friend' && */}
                    {/* {
                      activeSection.breakoutRooms &&
                        <BreakoutRooms
                          mobile
                          breakoutRooms={activeSection.breakoutRooms}
                          activity={activity}
                          setActivity={setActivity}
                          setActiveSection={setActiveSection}
                          activeSection={activeSection} />
                    } */}
                  </>
              }
              
            </div>

          :

            <StudentVoting
              mobile
              setActiveSection={setActiveSection}
              activeSection={activeSection}
              activity={activity}
              toggleModal={toggleModal}
              hideVotingComp={hideVotingComp}
              markAsRead={markAsRead}
              updateSectionKey={updateSectionKey}
              setForceWriting={setForceWriting}
            />
        }

      <div ref={editorRef} className={`${styles.editorDiv}`}>
        <EntryTextEditor
          mobile
          mobileQuillRef={mobileQuillRef}
          toggleMobileEditor={toggleMobileEditor}
          localPrivate={localPrivate}
          setLocalPrivate={setLocalPrivate}
          updateSectionKey={updateSectionKey}
          privateMode={privateMode}
          activityId={activity.activityId}
          activeSection={activeSection}
          toggleModal={toggleModal}
          setActiveSection={setActiveSection}
          forceWriting={forceWriting}
          setForceWriting={setForceWriting}
          sidebarOpen={sidebarOpen} />
      </div>

    </div>
  )

}

export default StudentEntryResponsive;