import React from 'react';
import { openInNewTab } from "Utils/UI_elements/openInNEwTab";

import styles from './EntryTextEditor.module.scss';
import fileIcon from 'Assets/Icons/file_teal.svg'
import eyeIcon from 'Assets/Icons/eye_blue.svg'
import closeIcon from 'Assets/Icons/close_circle.svg'
import { sendToApi } from 'API/API.service';

const isImageFile = file =>  ['.png', '.jpg', 'jpeg'].indexOf(file.fileUrl.slice(file.fileUrl.length-4)) !== -1

const StudentFileDiv = ({ item, idx, activeSection, activeSection:{studentFiles}, activityId, setActiveSection, updateSectionKey }) => {

  const hanleDelete = () => {
    const newFiles = [
      ...studentFiles.slice(0, idx),
      ...studentFiles.slice(idx+1),
    ]
    setActiveSection({...activeSection, studentFiles: newFiles})
    updateSectionKey('studentFiles', newFiles)
    sendToApi(`activity/${activityId}/delete_file/${activeSection.sectionIndex-1}?url=${item.fileUrl}`)
  }

  return (
    <div className={`${styles.fileDiv} ${!isImageFile(item) && styles.pdfFile}`}>
      {
        isImageFile(item) ? 
          <img src={item.fileUrl} alt="image" className={styles.imageFile} />
        :
          <>
            <img src={fileIcon} alt="image" className={styles.pdfFile} />
            <p>file.pdf</p>
          </>
      }
      <div className={styles.hoverDiv}>
        <img onClick={hanleDelete} src={closeIcon} alt="delete" className={styles.close}/>
        <div onClick={()=> openInNewTab(item.fileUrl)} className={styles.viewBtn}>
          <img src={eyeIcon} alt="eye" />
          <p>View</p>
        </div>
      </div>
    </div>
  )

}

export default StudentFileDiv;