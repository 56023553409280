import React from 'react';
import { formatTimePassed } from 'Utils/Calculation_funcs/formatTimePassed';  
import { returnDefaultPic } from 'Utils/UI_elements/returnDefaultPic';


import styles from './ChatModal.module.scss';

const ChatMessageElement = ({ msg, boomerBase64, chatData, activeChat}) => {
  
  const teacherImg = chatData[activeChat].teacherAvatarUrl !== null ? chatData[activeChat].teacherAvatarUrl : returnDefaultPic(activeChat)
  
  return (
    <div className={`${styles.msgContainer} ${msg.sentBy!==1?styles.student:styles.teacher}`}>
      <img src={
          msg.sentBy!==1?`data:image/png;base64,${boomerBase64}`: teacherImg} alt="" />
      <div className={styles.msgDiv}>
          {msg.content}
          {msg.timeStamp ? <p>{formatTimePassed(msg.timeStamp)} ago</p>:<p>{formatTimePassed(new Date())} ago</p>}
      </div>
    </div>
  )

}

export default ChatMessageElement;