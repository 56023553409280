
export const idxClass = idx =>
  [0,4,8,12,16,20,24,28,32,36,40].indexOf(idx) !== -1 ? 'one' :
  [1,5,9,13,17,21,25,29,33,37,41].indexOf(idx) !== -1 ? 'two' :
  [2,6,10,14,18,22,26,30,34,38,42].indexOf(idx) !== -1 ? 'three' : 
  [3,7,11,15,19,23,27,31,35,39,43].indexOf(idx) !== -1 ? 'four' : null



  // const idxClass = 
  //   [0,4,8,12,16,20,24,28].indexOf(idx) !== -1 ? styles.one :
  //   [1,5,9,13,17,21,25,29].indexOf(idx) !== -1 ? styles.two :
  //   [2,6,10,14,18,22,26,30].indexOf(idx) !== -1 ? styles.three :
  //   [3,7,11,15,19,23,27,31].indexOf(idx) !== -1 ? styles.four : null
  
