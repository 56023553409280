import React, { useRef } from 'react';

import styles from './DashboardNoClasses.module.scss';
import face from 'Assets/Avatars/face_shocked.svg'

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import JoinClassModal from '../JoinClassModal/JoinClassModal';

const DashboardNoClasses = ({ dashFeed, setDashFeed }) => {

  const newModalRef = useRef()
  const inputRef = useRef()
  const boomerBase64 = useSelector(state => state.dashData.base64)
  const user = JSON.parse(localStorage.getItem('user'))

  const toggleNewModal = (input) => {
    if(newModalRef.current) newModalRef.current.classList[input](styles.showModal)
    if(inputRef.current && input==='add'){
      inputRef.current.value=""
      inputRef.current.focus()
    }
  }

  useEffect(()=>{
    console.log(user)
  },[])

  return (
    <div className={styles.noClassesInnerWrapper}>
      <img src={face} alt="face" />
      <h5>It seems that you are not enrolled in</h5>
      <h5>any groups yet, let's join one</h5>
      <div className={styles.newGroupCont}>
        <div onClick={()=>toggleNewModal('add')} className={`${styles.newGroupBtn}`}>
          <div className={styles.plus} />
          <p>Join Class</p>
        </div>
        <JoinClassModal 
          centered
          setDashFeed={setDashFeed}
          dashFeed={dashFeed}
          inputRef={inputRef}
          toggleNewModal={toggleNewModal}
          newModalRef={newModalRef}/>
      </div>
    </div>
  )

}

export default DashboardNoClasses;