import React from 'react';
import { useHistory } from 'react-router';

import styles from './QuestionRow.module.scss';
import StatusPillSynod from './StatusPillSynod';

const QuestionRow = ({ idx, activityId, rowData:{ sectionId, sectionName, wordsCount, sectionStatus }}) => {

  const history = useHistory()
  const isSubmitted = [6,7].indexOf(sectionStatus) !== -1;

  const handleClick = () => {
    localStorage.setItem('sectionId', sectionId);
    history.push(`/write?activityId=${activityId}&s=${idx}`)
  }


  return (
    <div onClick={handleClick} className={`${styles.container} ${isSubmitted && styles.submitted}`}>
      <div className={styles.column}>
        <div className={styles.number}>{idx+1}</div>
        <p className={styles.title}>{sectionName}</p>
      </div>
      <div className={styles.column}><p className={styles.count}>{wordsCount}</p></div>
      <div className={styles.column}>
        <StatusPillSynod sectionStatus={sectionStatus} />
      </div>
    </div>
  )

}

export default QuestionRow;