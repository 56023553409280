import React from 'react';

import styles from './SectionPhasePill.module.scss';
import tick from 'Assets/Icons/tick_green_circle.svg'

const SectionPhasePill = ({ wordsCount, sectionStatus, actions, isStoryStart, isApproved }) => {

  const isRevision = actions.findIndex(i => i.kind==="revision") !== -1
  const isWrite = actions.findIndex(i => i.kind==="write") !== -1
  const isVote = actions.findIndex(i => i.kind==="vote") !== -1
  const isApprovedAction = actions.findIndex(i => i.kind==="approved") !== -1

  const renderDot = () => {
    if(isRevision){
      return [false,"false"].indexOf(actions.find(i=>i.kind==="revision").seen) !== -1
    } else if (isWrite) {
      return [false,"false"].indexOf(actions.find(i=>i.kind==="write").seen) !== -1
    } else if (isVote) {
      return [false,"false"].indexOf(actions.find(i=>i.kind==="vote").seen) !== -1
    }else if (isApprovedAction) {
      return [false,"false"].indexOf(actions.find(i=>i.kind==="approved").seen) !== -1
    }
  }

  const variety = 
    isRevision && [0,1,2,6,7].indexOf(sectionStatus) !== -1 ?   'pink' :
    [0,1].indexOf(sectionStatus) !== -1 ? 'orange' :
    [2].indexOf(sectionStatus) !== -1 ? 'orangeTwo' :
    [3,4].indexOf(sectionStatus) !== -1 ? 'purple' :
    [5,6,7].indexOf(sectionStatus) !== -1 ? 'opaque' :
    [8].indexOf(sectionStatus) !== -1 && wordsCount == 0 ? 'noWrite' : null

  const text = 
    isRevision && [0,1,2,6,7].indexOf(sectionStatus) !== -1 ? 'Revision' :
    [0,1].indexOf(sectionStatus) !== -1 ? 'Write' :
    [2].indexOf(sectionStatus) !== -1 ? 'Finish Writing' :
    [3,4].indexOf(sectionStatus) !== -1 ? 'Vote' : 
    [5].indexOf(sectionStatus) !== -1 ? 'Voted' : 
    [6,7].indexOf(sectionStatus) !== -1 ? 'Submitted' :
    [8].indexOf(sectionStatus) !== -1 && wordsCount == 0 ? 'You didn\'t write' : null

  return isStoryStart ? null : 

      isApproved && [3,4,5].indexOf(sectionStatus) === -1 ?

        <div className={styles.approved}>
          <h6>Approved</h6>
          <img src={tick} alt="tick" />
        </div>

      :
    
        <div className={`${styles.sectionStatusPill}`}>
          {renderDot() && <div className={styles.dot} />}
          <h4 className={`${styles[variety]}`}>{text}</h4>
        </div>
    

}

export default SectionPhasePill;



//     status === 0 ? 'Start Writing' :
//     status === 1 ? 'Start Writing' :
//     status === 2 ? 'Writing' :
//     status === 3 ? 'Start voting' :
//     status === 4 ? 'Voting' :
//     status === 5  ? 'Voted' :
//     status === 6 ? 'Submitted' :
//     status === 7 ? 'Reviewing' :
//     status === 8 ? 'Completed' :






// import React from 'react';

// import styles from './SectionPhasePill.module.scss';

// const SectionPhasePill = ({ wordsCount, phase, revision, isStoryStart }) => {

//   const variety = 
//     revision && [0,1,2].indexOf(phase) !== -1 ?   'pink' :
//     [0,1,2].indexOf(phase) !== -1 ? 'orange' :
//     [3,4].indexOf(phase) !== -1 ? 'purple' :
//     [7].indexOf(phase) !== -1 && wordsCount > 0 ? 'opaque' :
//     [7].indexOf(phase) !== -1 && wordsCount === 0 ? 'noWrite' : null

//   const text = 
//     revision && [0,1,2].indexOf(phase) !== -1 ? 'Revision' :
//     [0,1,2].indexOf(phase) !== -1 ? 'Write' :
//     [3,4].indexOf(phase) !== -1 ? 'Vote' : 
//     [7].indexOf(phase) !== -1 && wordsCount > 0 ? 'Submitted' :
//     [7].indexOf(phase) !== -1 && wordsCount === 0 ? 'You didn\'t write' : null

//   return phase === 8 || isStoryStart ?  null : 
//     (
//       <div className={`${styles.sectionStatusPill}`}>
//         <h4 className={`${styles[variety]}`}>{text}</h4>
//       </div>
//     )

// }

// export default SectionPhasePill;



//     status === 0 ? 'Start Writing' :
//     status === 1 ? 'Start Writing' :
//     status === 2 ? 'Writing' :
//     status === 3 ? 'Start voting' :
//     status === 4 ? 'Voting' :
//     status === 5  ? 'Voted' :
//     status === 6 ? 'Submitted' :
//     status === 7 ? 'Reviewing' :
//     status === 8 ? 'Completed' :
