import React, { useEffect } from 'react';
import { authRedirect } from 'Utils/Redirects/authRedirect';

import MainLoader from 'Components/UtilityComponents/MainLoader/MainLoader';

const styles = {
  width: "100%",
  height: "100%",
}

const Logout = () => {

  useEffect(()=>{
    localStorage.clear()
    authRedirect(true)
  },[])

  return <div style={styles}> <MainLoader /></div>

}

export default Logout;