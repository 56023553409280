import React from 'react';

import styles from './StatusPillSynod.module.scss';

const StatusPillSynod = ({ sectionStatus }) => {

  const isSubmitted = [6,7].indexOf(sectionStatus) !== -1;

  const variety = 
    [0,1].indexOf(sectionStatus) !== -1 ? 'orange' :
    [2].indexOf(sectionStatus) !== -1 ? 'orangeTwo' :
    [6,7].indexOf(sectionStatus) !== -1 ? 'green' : null

  const text = 
    [0,1].indexOf(sectionStatus) !== -1 ? 'Write' :
    [2].indexOf(sectionStatus) !== -1 ? 'Finish Writing' :
    [6,7].indexOf(sectionStatus) !== -1 ? 'Submitted' : null

  return (
    <div className={`${styles.container} ${styles}`}>
      <h4 className={`${styles[variety]}`}>{text}</h4>
    </div>
  )

}

export default StatusPillSynod;