import React from 'react';
import { differenceInDays, isPast, format } from 'date-fns'

import styles from './Deadline.module.scss';
import calendar from 'Assets/Icons/calendar2.svg'

const Deadline = ({ endDate, open, fixedSize }) => {

  const isFinished = isPast(new Date(endDate))
  const remainingDays = differenceInDays(new Date(endDate), new Date())
  const unitText = remainingDays!==1?'days':'day'

  const daysText = 
    isFinished ? format(new Date(endDate), 'MMM dd, yyyy') :
    // isFinished ? 'Finished' :
    remainingDays === 0 ? 'Ends tomorrow' : `${remainingDays} ${unitText} left`

  const textStyle =
    endDate === "open" ? "open" :
    isFinished ? 'finished' : 
    remainingDays > 19 ? 'twenty' :
    remainingDays > 9 ? 'ten' :
    remainingDays > 4 ? 'five' : 'two'

  return (
    <div className={`${styles.container} ${open && styles.open}`}>
      {!isFinished && <img src={calendar} alt="calendar" />}

      <h5 className={`
        ${styles[textStyle]} 
        ${!fixedSize && remainingDays === 0 && styles.small}
        ${open && styles.large}`
      }>
        {endDate === "open" ? "Open" : daysText}
      </h5>

    </div>
  )

}

export default Deadline;