import DropdownMenu from 'Components/UtilityComponents/DropdownMenu/DropdownMenu';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ className="Teacher's Class", activity: { activityId, activityTitle, sections, activityClassId }, defaultIdx, handleSelect, activeSection}) => {

  const history = useHistory()

const handleBack = () => {
  localStorage.setItem('modalTrigger', `block${activityId}`)
  history.goBack()
}


  return (
    <div className={styles.container}>

      <div onClick={handleBack} className={styles.backArrow} />

      <div className={styles.content}>

        <div className={styles.text}>
          <Link to={`/class/${activityClassId}`}>{className}</Link>
          <p>/</p>
          <p>{activityTitle}</p>
        </div>

        <DropdownMenu
          activeSection={activeSection}
          handleSelect={handleSelect}
          items={sections}
          defaultIdx={defaultIdx}
          width={"320px"} />
          
      </div>

    </div>
  )

}

export default Breadcrumb;