import React from 'react';

import styles from './ProgressBar.module.scss';

const ProgressBar = ({ progress, height }) => {

  return (
    <div className={styles.container} style={{height:`${height}px`, borderRadius:`${height/2}px`}}>
      <div className={styles.inner} style={{width:`${progress}%`, borderRadius:`${height/2}px`}} ></div>
    </div>
  )

}

export default ProgressBar;