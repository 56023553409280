import React from 'react';

import styles from './WinnerModal.module.scss';
import img1 from 'Assets/Icons/winner_first.svg';
import img2 from 'Assets/Icons/winner_second.svg';
import img3 from 'Assets/Icons/winner_third.svg';
import closeIcon from 'Assets/Icons/close_square.svg'
import { useEffect } from 'react';
import { sendToApi } from 'API/API.service';

const WinnerModal = ({ first, second, third, closeModal, activityId, unitNumber, updateSeen }) => {


  useEffect(()=>{
    sendToApi(`activity/${activityId}/${unitNumber}/rank_seen`)
    updateSeen()
  },[])

  return (
    <div className={styles.container}>
      <img onClick={closeModal} className={styles.close} src={closeIcon} alt="close" />
      <img src={first?img1:second?img2:img3} alt="medal" />
      <h1>{first?"You won!":`You finished ${second?"2nd":"3rd"}`}</h1>
      <p>Your entry was the {first?"most":`${second?"2nd":"3rd"} most`} voted<br/>entry in the class, congrats!</p>
    </div>
  )

}

export default WinnerModal;