import { combineReducers } from 'redux'

import dashData from './dashData';
import chatData from './chatData';
import modalContent from './modalContent';
import appTitle from './appTitle';
import userType from './userType';

const rootReducer = combineReducers({
  dashData,
  chatData,
  modalContent,
  appTitle,
  userType
})

export default rootReducer