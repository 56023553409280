import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode'

import { authRedirect } from 'Utils/Redirects/authRedirect';

import MainLoader from 'Components/UtilityComponents/MainLoader/MainLoader';
import store from 'storeConfig';
import { setAppTitle } from 'Actions/appTitleActions';
import { setUserType } from 'Actions/userType';

const Auth = ({ }) => {

  const location = useLocation()
  const history = useHistory()

  useEffect(()=>{

    //check URL for JWT
    console.log(queryString.parse(location.search))
    const {ft} = queryString.parse(location.search)
    console.log(ft)

    setTimeout(()=>{

      // no JWT present in URL
      if(!ft){ 
  
        // Save the attempted URL and redirect to login, URL then used on the next auth attempt
        localStorage.setItem('attempted_url', window.location)
  
        //dynamically redirect to static auth if JWT is not present
        return authRedirect(true)
  
      }
  
      console.log(jwt_decode(ft))
  
      //if JWT present - decode, save keys in LS
      const { t, user, chatUrl, frontUrl, imp=false, hasTeacherAccount=false } = jwt_decode(ft)
      
      localStorage.setItem('t', t)
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('backUrl', chatUrl)
      localStorage.setItem('frontUrl', frontUrl)
      localStorage.setItem('chatterId', user.chatterId)
      localStorage.setItem('chatterToken', user.chatterToken)
      localStorage.setItem('defaultActivityId', user.defaultActivityId)
      localStorage.setItem('defaultGroupId', user.defaultGroupId)
      localStorage.setItem('impersonate', hasTeacherAccount)
  
      store.dispatch(setAppTitle(user.userType))
      store.dispatch(setUserType(user.userType))
  
      if(imp){ localStorage.setItem('impersonate', true) }
    
  
      // redirect authenticated user to dash or previously attempted URL
      const attemptedUrl = localStorage.getItem('attempted_url')
  
      if(attemptedUrl){
        window.location = attemptedUrl
        localStorage.removeItem('attempted_url');

      } else {

        history.push('/dashboard')
      }
      
    },400)
    
  },[history, location.search])

  return <MainLoader noNav />

}

export default Auth;

