import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';

import Breadcrumb from './Breadcrumb/Breadcrumb';
import EntryTextEditor from './EntryTextEditor/EntryTextEditor';
import EntrySidebar from './EntrySidebar/EntrySidebar';
import StudentVoting from './StudentVoting/StudentVoting';
import WinnerModal from './WinnerModal/WinnerModal'
import StudentEntryResponsive from 'Components/StudentEntryResponsive/StudentEntryResponsive';

import styles from './StudentEntry.module.scss';
import { getFromApi } from 'API/API.service';
import { useLocation } from 'react-router';
import MainLoader from 'Components/UtilityComponents/MainLoader/MainLoader';
import ActivitySectionsModal from 'Components/ClassDetails/ActivitySectionsModal/ActivitySectionsModal';
import NoAccessError from './NoAccessError';
import { useLayoutEffect } from 'react';

const StudentEntry = ({ toggleModal, synod }) => {

  const sidebarRef = useRef()
  const location = useLocation()
  const {activityId, s} = queryString.parse(location.search)
  const [ activity, setActivity ] = useState(null)
  const [ defaultIdx, setDefaultIdx ] = useState(s||0)
  const [ activeSection, setActiveSection ] = useState(null)
  const [ sidebarOpen, toggleSidebar ] = useState(true)
  const [ localPrivate, setLocalPrivate ] = useState(false)
  const [ inputHTML, setInputHTML ] = useState()
  const [ forceWriting, setForceWriting] = useState(false)
  const getActivityFeed = async () => setActivity(await getFromApi(`activity/${activityId}`)) 
  
  useEffect(()=>{ 
    getActivityFeed()
  },[])

  const hideVotingComp = () => setActiveSection({...activeSection, sectionStatus: 5, sectionPhase: 5})


  useEffect(()=>{ 

    if(activity){ 
      setLocalPrivate(activity.privateActive)

      // //if navigating to specific section, its id will be in LS. This is used as the default
      // //activeSection in the useState below. Otherwise, index 0 is used
      // if(localStorage.getItem('sectionId')!== null) {
      //   const idx = activity?.sections.findIndex(i => i.sectionId == localStorage.getItem('sectionId'))
      //   setDefaultIdx( idx !== -1 ? idx : 0 )
      // }

      //set active section on receipt of respnonse from API
      if(activity.sections) setActiveSection(activity.sections[defaultIdx])
    }
    
  },[activity?.activityId, defaultIdx])


  useEffect(()=>{
    // populate initial inputHTML upon section change
    if(activeSection){
      // update the text
      setInputHTML(activeSection.entryHTML)
      // sidebar should be open by default
      toggleSidebar(activeSection?.sectionPhase !== 4 && !activeSection.storyStart)
      // make sure force writing is not set
      setForceWriting(false)
    }
  },[activeSection]) 

  useEffect(() => {
    // we default to the sidebar closed if the entry is in voting
    toggleSidebar(forceWriting)
  }, [forceWriting])

  useEffect(()=>{
    setActivity({...activity, privateActive:localPrivate})
  },[localPrivate]) 


  useLayoutEffect(()=>{
    if(activeSection && activeSection.entryRank && !activeSection.entryRankSeen){
      toggleModal(true, 
        <WinnerModal 
          activityId={activityId}
          unitNumber={activeSection.sectionIndex}
          first={activeSection?.entryRank==1} 
          second={activeSection?.entryRank==2} 
          third={activeSection?.entryRank==3} 
          updateSeen={()=> updateSectionKey( 'entryRankSeen', true)}
          closeModal={()=>toggleModal(false)} />
        )
    }
  },[activeSection]) 
  
  const markAsRead = (sectionId, entryIdx) => {
    const sectionIdx = activity.sections.map(i=>i.sectionId).indexOf(sectionId)
    const newSections = activity.sections
    newSections[sectionIdx].votingData.entries[entryIdx].read=true

    // if(!secondRound){
    //   setActivity({ ...activity, sections:newSections })

    // } else {
      const newEntries = [...activeSection.votingData.entries]
      newEntries[entryIdx].read=true
      setActiveSection({...activeSection, votingData:{...activeSection.votingData, entries:newEntries}})
    // }
  }

  const updateSectionKey = ( key, newValue, nestedKey, roundTwoVoting ) => {
    const sectionIdx = activity.sections.map(i=>i.sectionId).indexOf(activeSection.sectionId)
    const newSections = [...activity.sections]

    if(!nestedKey){
      newSections[sectionIdx][key]=newValue
    } else {
      newSections[sectionIdx][key][nestedKey]=newValue
    }

    if(roundTwoVoting){
      return setActiveSection({...activeSection, [key]:newValue})
    }

    setActivity({ ...activity, sections:newSections })
  }
  

  const handleSelect = (idx) => {

    localStorage.setItem('noAutoSave', true) 

    setTimeout(()=>{ setActiveSection(activity.sections[idx]) },150)

    setTimeout(()=>{localStorage.removeItem("noAutoSave")},3000) //remove key from LS after 3s

    localStorage.setItem('sectionIdx', idx)
  }


  const handleViewAll = () => {
    toggleModal(true,
      <ActivitySectionsModal
        viewAll
        synod={synod}
        handleSelect={handleSelect}
        closeModal={()=>toggleModal(false)}
        activity={activity} />)
  }

  return  activity?.message ? <NoAccessError /> : 
          !activity || !activeSection ? <MainLoader /> : (
    <>
      {/* ////RESPONSIVE VERSION BELOW THIS SECTION TAG//// */}
      <section className={`${styles.container} ${synod && styles.synod}`}>
        <div className={`${styles.main} ${sidebarOpen && styles.sml} ${( activity.privateMode && localPrivate )&& styles.privateEnabled}`}>
        
          {/* <div className={`${styles.privateOverlay} ${( activity.privateMode && localPrivate )&& styles.enabled}`} /> */}

          <div className={styles.topBar}>

            <div className={styles.left}>
              <Breadcrumb
                className={activity.activityClassTitle}
                defaultIdx={defaultIdx}
                activeSection={activeSection} 
                activity={activity}  
                handleSelect={handleSelect} />

              <div onClick={handleViewAll} className={styles.viewAllBtn}>
                <p>View all {!synod ? "sections" : "questions"}</p>
              </div>
            </div>
          </div>

          {
            [3,4,5].indexOf(activeSection?.sectionStatus) == -1 || forceWriting ?  //non-voting phases
            // activeSection.sectionStatus === 5 ? // post-voting stage
              <>
                {
                  activeSection.storyStart ?  
                    <textarea  className={styles.storyStartContainer} readOnly defaultValue={activeSection.storyStartContents} />
                    :
                    <EntryTextEditor
                      synod={synod}
                      localPrivate={localPrivate}
                      setLocalPrivate={setLocalPrivate}
                      updateSectionKey={updateSectionKey}
                      privateMode = {activity.privateMode}
                      activityId={activity.activityId}
                      activeSection={activeSection}
                      toggleModal={toggleModal}
                      sidebarOpen={sidebarOpen}
                      toggleSidebar={toggleSidebar}
                      setActiveSection={setActiveSection}
                      forceWriting={forceWriting}
                      setForceWriting={setForceWriting} />
                } 
              </>
              :  
              // only shown when the activity is in voting stage and the student has not voted
              <StudentVoting
                hideVotingComp={hideVotingComp}
                updateSectionKey={updateSectionKey}
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                activity={activity}
                toggleModal={toggleModal}
                markAsRead={markAsRead}
                setForceWriting={setForceWriting}/>
          }
        </div>

        {
          (activeSection?.sectionPhase !== 4 ||  // non-voting phases
          forceWriting) && // student is doing a late write to their entry
          <EntrySidebar
            synod={synod}
            inputHTML={inputHTML}
            setActiveSection={setActiveSection}
            activity={activity}
            setActivity={setActivity}
            open={sidebarOpen}
            sidebarRef={sidebarRef}
            activeSection={activeSection}
            toggleModal={toggleModal}
            toggleSidebar={toggleSidebar} />
        }
      </section>

      <StudentEntryResponsive
        setActivity={setActivity}
        privateMode = {activity.privateMode}
        activity={activity}
        toggleModal={toggleModal}
        sidebarOpen={sidebarOpen}
        markAsRead={markAsRead}
        updateSectionKey={updateSectionKey} 
        setActiveSection={setActiveSection}
        hideVotingComp={hideVotingComp}
        localPrivate={localPrivate}
        setLocalPrivate={setLocalPrivate}
        activeSection={activeSection}
        forceWriting={forceWriting}
        setForceWriting={setForceWriting} /> {/* hidden above 650px */}

    </>
  )

}

export default StudentEntry;