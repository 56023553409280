import React, { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable'

import styles from './StudentVoting.module.scss';
import face from 'Assets/Avatars/face_shocked.svg'
import ProgressBar from 'Components/UtilityComponents/ProgressBar/ProgressBar';
import VotingEntry from './VotingEntry';
import EntryModal from './EntryModal';
import VotedModal from '../Modals/VotedModal/VotedModal'
import { useWindowSize } from 'Utils/Hooks/useWindowSize';

const StudentVoting = ({ activeSection, setActiveSection, hideVotingComp, 
  activeSection: { votingData:{ voted, entries }, sectionId, sectionIndex }, 
  markAsRead, toggleModal, activity, updateSectionKey, mobile, setForceWriting }) => {

  const [ readingProgress, setReadingProgress ] = useState()
  const [ showConf, toggleConf ] = useState(false)
  const carouselRef = useRef()
  const { width } = useWindowSize()

  useEffect(()=>{
    const entriesCount = entries.length
    const entriesRead = entries.filter(i => i.read).length;
    const percentage = (entriesRead/entriesCount)*100
    setReadingProgress(percentage)
  },[activity, activeSection, entries])

  // useEffect(()=>{toggleConf(voted&&!entries?.length)},[sectionId])

  const showVotingModal = (idx) => {
    toggleModal(true, 
      <EntryModal
        activeSection={activeSection}
        activityId={activity.activityId}
        sectionIndex={activeSection.sectionIndex}
        mobile={mobile}
        idx={idx}
        entries={entries}
        markAsRead={markAsRead}
        sectionId={sectionId} /> )
  }

  const handleSwipe = (direction) => {

    if(!mobile)return

    const scrollOffset = (width/100)*80+10
    carouselRef.current.scrollBy({
      left:direction === 'left' ? scrollOffset : `-${scrollOffset}`,
      behavior:'smooth'
    })

  }

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });


  return (
    <div className={`${styles.container} ${showConf && styles.voted} ${mobile && styles.mobile}`}>

      {
        showConf ? //post-vote confirmation

            <VotedModal
            mobile={mobile}
            toggleConf={toggleConf}
            activeSection={activeSection} 
            hideVotingComp={hideVotingComp} />

        : // pre-vote entry elements

          !entries.length && !voted ?
             <div className={styles.noEntries}>
               <img src={face} alt="boomer" />
               <h6>There are not currently any entries ready for voting</h6>
             </div>

          :
          !entries.length && voted ?
             <div className={styles.noEntries}>
               <h6>You already voted on all entries</h6>
             </div>

          :
            <>
              <div className={styles.topBar}>

                <div className={styles.text}>
                  {/* <img className={styles.boomer} src={boomer} alt="boomer" /> */}
                  <h5>Click on any entry to read. After reading all entries. Click on your preffered entry to vote.</h5>
                </div>

                <div className={styles.progressDiv}>
                  <h6>Reading Progress</h6>
                  <ProgressBar height={15} progress={readingProgress} />
                </div>

              </div>


              {
                !mobile ?

                    <div className={styles.entries}>
                      {
                        entries.map((i,idx) => 
                            <VotingEntry
                              toggleConf={toggleConf}
                              sectionIndex={sectionIndex}
                              activityId={activity.activityId}
                              entryId={i.entryId}
                              mobile={mobile}
                              key={idx}
                              readingProgress={readingProgress}  
                              idx={idx}
                              read={i.read}
                              entryHTML={i.entryHTML}
                              updateSectionKey={updateSectionKey}
                              setActiveSection={setActiveSection}
                              activeSection={activeSection} 
                              showVotingModal={()=>showVotingModal(idx)}
                              addNextSet={(input)=>setActiveSection({...activeSection, votingData:input})}/> )
                      }
                    </div>

                : 
                    //mobile version requires an inner container for horizontal
                    // swipe carousel and an overlay to catch the swipe event
                    <>
                      <div {...handlers}  className={styles.overlay} />
                      {/* <div id={"elm"} ref={carouselRef} className={styles.entries}> */}
                        <div className={styles.entriesInner}>
                          {
                            entries.map((i,idx) => 
                                <VotingEntry
                                  toggleConf={toggleConf}
                                  mobile={mobile}
                                  key={idx}
                                  readingProgress={readingProgress}  
                                  idx={idx}
                                  sectionIndex={sectionIndex}
                                  activityId={activity.activityId}
                                  entryId={i.entryId}
                                  read={i.read}
                                  setActiveSection={setActiveSection}
                                  activeSection={activeSection} 
                                  entryHTML={i.entryHTML}
                                  updateSectionKey={updateSectionKey}
                                  showVotingModal={()=>showVotingModal(idx)}
                                  addNextSet={(input)=>setActiveSection({...activeSection,  votingData:input})}/> )
                          }
                        </div>
                      {/* </div> */}
                    </>
              }
            </>
      }
      {
        !activeSection.submitted ? 
          <div className={styles.finishWriting}
            onClick={() => setForceWriting(true)}>
            Finish Writing
          </div>
          : null
      }
    </div>
  )

}

export default StudentVoting;