import { authRedirect } from "Utils/Redirects/authRedirect";
import axios from 'axios'


export const getFromApi = async (query) => {
  
  const token = localStorage.getItem('t');
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/students/${query}`

  try {
    
    const { data } = await axios.get( url, {headers: { 'Authorization': token || '' } } )

    console.log(url)
    console.log(data)

    return data;
    
  } catch (error) {
    if(error.response && error.response.status===401){
      localStorage.removeItem('t')
      authRedirect(true)
    }
    console.log(error)
  }
}


export const sendToApi = async ( query, newData ) =>{

  const token = localStorage.getItem('t');
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/students/${query}`
  
  console.log(url)
  console.log(newData)
  console.log({headers: { 'Authorization': token || '' }})
  
  const { data } = await axios.post( url, newData, { headers: { 'Authorization': token || '' } } )

  console.log(data)

  return data
}



///////<--------- CHAT ENDPOINTS

export const getFromChatApi = async (query) => {
  
  const token = localStorage.getItem('t');
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/chat/${query}`

  try {
    
    const { data } = await axios.get( url, {headers: { 'Authorization': token || '' } } )

    console.log(url)
    console.log(data)
    // console.log({headers: { 'Authorization': token || '' }})

    return data.teachers;
    
  } catch (error) {
    if(error.response && error.response.status===401){
      localStorage.removeItem('t')
      authRedirect(true)
    }
    console.log(error)
  }
}

export const sendToChatApi = async ( query, newData ) =>{

  const token = localStorage.getItem('t');
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/chat/${query}`
  
  console.log(url)
  console.log(newData)
  
  const { data } = await axios.post( url, newData, { headers: { 'Authorization': token || '' } } )

  console.log(data)

  return data
}


