import React, { useRef, useState } from 'react';
import { useEffect } from 'react';

import styles from './DropdownMenu.module.scss';

const DropdownMenu = ({items, activeSection, handleSelect, width="fit-content", defaultIdx=0}) => {


  const [ selected, setSelected ] = useState({item:items[defaultIdx], idx:defaultIdx})
  const chevronRef = useRef()
  const dropRef = useRef()
  const woosh = new Audio('Sounds/woosh1.mp3')
  const woosh2 = new Audio('Sounds/woosh2.mp3')
  woosh.volume=0.2
  woosh2.volume=0.3

  const textStyle = {
    width:"100%",
    maxWidth:`calc(${width} - 60px)`,
    overflow:"hidden",
    textOverflow:"ellipsis",
    whiteSpace:"nowrap"
  }

  const handleChevron = (action) => {
    dropRef.current.classList[action](styles.open)
    chevronRef.current.classList[action](styles.open)    
  }
  
  const handlechange = (e) => {
    setSelected({
      item:items[parseInt(e.target.value)],
      idx:parseInt(e.target.value)
    })
    handleSelect(parseInt(e.target.value))
    dropRef.current.classList.remove(styles.open)
    chevronRef.current.classList.remove(styles.open)

    let location = new URL(window.location.href);
    location.searchParams.set('s', e.target.value);
    window.history.pushState(null, null, location.href);
  }

  useEffect(()=>{
    if(activeSection) {
      console.log('update')
      setSelected({idx:activeSection.sectionIndex-1, item:activeSection})
    }
  },[activeSection?.sectionId])


  return (
    <div onClick={()=>handleChevron('toggle')} className={styles.container} style={{width:`${width}`}}>
      
      <div className={styles.menuDiv}>
        <div className={styles.numberCircle}>
          <p>{parseInt(selected.idx)+1}</p>
        </div>
        <h6 style={textStyle}>{selected.item?.storyStart?"Story Start":selected.item?.sectionName}</h6>

        <div ref={chevronRef} className={styles.chevron} />
      </div>

      <div 
        onMouseLeave={()=>handleChevron('remove')} 
        onChange={handlechange} ref={dropRef} 
        className={`${styles.dropdownItems}`}>
        {
          items.map((item, idx) => 
            [1,21].indexOf(item.sectionPhase)===-1&&
            <label key={idx}>
              <input type="radio" name="selected" value={idx} />
              <div className={`${styles.itemRow} ${idx===selected.idx&&styles.selected}`} style={{width:`${width}`}}>
                <div className={styles.numberCircle}>
                  <p>{idx+1}</p>
                </div>
                <h6>{item.storyStart?"Story Start":item.sectionName}</h6>
                </div>
            </label>
          )
        }
      </div>

    </div>
  )

}

export default DropdownMenu;