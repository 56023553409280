import React from 'react';
import { useHistory } from 'react-router';
import EntrySubmitBtn from 'Components/StudentEntry/EntrySubmitBtn/EntrySubmitBtn';

import styles from './EntryTopBarResponsive.module.scss';

import arrow from 'Assets/Icons/chevron_white.svg'
import comments_white from 'Assets/Icons/message_white_hollow.svg'
import instructions_white from 'Assets/Icons/instructions_white_hollow.svg'
import pencil_white from 'Assets/Icons/pencil_white.svg'
import comments_blue from 'Assets/Icons/message_blue_hollow.svg'
import instructions_blue from 'Assets/Icons/instructions_blue_hollow.svg'
import pencil_blue from 'Assets/Icons/pencil_blue.svg'
import ActivitySectionsModal from 'Components/ClassDetails/ActivitySectionsModal/ActivitySectionsModal';

const EntryTopBarResponsive = ({
  view,
  inView,
  toggleView,
  toggleModal,
  setInview,
  isVoting,
  localPrivate,
  activity,
  activity:{
    activityTitle,
    activityClassTitle,
    activityClassId
  },
  activeSection:{
    storyStart,
    sectionName,
    sectionIndex,
    breakoutRooms,
    unreadComments
  }
}) => {

  const history = useHistory()
  // const hasBreakoutRoom = breakoutRooms.length
  const hasBreakoutRoom = false


  const handleChange = (str) => {
    setInview(str)
    toggleView(str)
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.navBar} ${storyStart && styles.align}`}>

        <div className={styles.topRow}>
          <img onClick={()=>history.push(`/class/${activityClassId}`)} src={arrow} alt="arrow" />
          <div className={`${styles.text}`}>
            <p>{activityClassTitle}/{activityTitle}</p>
            {
              !storyStart ?
                <h5>{sectionIndex}. {sectionName}</h5>
              :
                <h5>Story start</h5>
            }

          </div>
          <img src={arrow} alt="arrow" className={`${styles.down}`}
            onClick={()=>toggleModal(true, <ActivitySectionsModal noObject activity={activity} />)} />
          {/* <div className={styles.numberCircle}>
            <p>{1+1}</p>
          </div> */}
        </div>

          {!storyStart &&
            <div className={styles.bottomRow}>

              {
                !isVoting &&
                  <div className={styles.radios}>
                  <label>
                    <input checked={inView == "instructions"} type="radio" name="view" />
                    <div onClick={()=> handleChange("instructions")} className={`${styles.radioDiv} ${hasBreakoutRoom && styles.slim}`}>
                      <img className={styles.unselected} src={instructions_white} alt="instructions" />
                      <img className={styles.selected} src={instructions_blue} alt="instructions" />
                      <h6>Instructions</h6>
                    </div>
                  </label>
                    <label>
                      <input checked={inView == "entry"} type="radio" name="view" />
                      <div onClick={()=> handleChange("entry")} className={`${styles.radioDiv} ${(localPrivate&&inView == "entry") && styles.grey} ${hasBreakoutRoom && styles.slim}`}>
                        <img className={styles.unselected} src={pencil_white} alt="pencil" />
                        <img className={styles.selected} src={pencil_blue} alt="pencil" />
                        <h6>Entry</h6>
                      </div>
                    </label>
                    <label>
                      <input checked={inView == "comments"} type="radio" name="view" />
                      <div onClick={()=> handleChange("comments")} className={`${styles.radioDiv} ${hasBreakoutRoom && styles.slim}`}>
                        <div className={`${styles.imgDiv} ${unreadComments && styles.notification}`}>
                          <img className={`${styles.unselected} ${styles.bubble}`} src={comments_white} alt="comments" />
                          <img className={`${styles.selected} ${styles.bubble}`} src={comments_blue} alt="pencil" />
                        </div>
                        <h6 className={`${styles.comments}`}>Teacher comments</h6>
                      </div>
                    </label>
                    {
                      hasBreakoutRoom ?
                      
                        <label>
                          <input checked={view == "friend"} type="radio" name="view" />
                          <div onClick={()=> toggleView("friend")} className={`${styles.radioDiv}`}>
                            <img onClick={() => toggleView('friend')} className={styles.boomer} src={breakoutRooms[0].boomerUrl} alt="boomer" />
                            <h6>Friend's</h6>
                          </div>
                        </label>

                      : null
                    }
                  </div>
              }

            </div>
          }

      </div>
    </div>
  )

}



// <img onClick={() => toggleView('instructions')} src={instructions} alt="instructions" />
// <img onClick={() => toggleView('comments')} src={comments} alt="comments" />

export default EntryTopBarResponsive;