import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './Reducers'


const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['modalContent']
}

const initialState = {}
const middleware = [ thunk ]
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))
export const persistor = persistStore(store)

export default store