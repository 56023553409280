import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Deadline from 'Components/UtilityComponents/Deadline/Deadline';
import SectionPhasePill from '../../UtilityComponents/SectionPhasePill/SectionPhasePill';
import { isPast } from 'date-fns'

import styles from './SectionList.module.scss';
import trophy from 'Assets/Icons/trophy_dot.svg'
import bubble from 'Assets/Icons/speech_bubble.svg'

const SectionRow = ({ synod, closeModal, viewAll, idx, handleSelect, activityId, section: { sectionId, sectionIndex, sectionStatus, storyStart, sectionName, sectionDue, finalGrade, actions, wordsCount }}) => {

  const msgCount = actions.filter(i => i.kind==='comments'||i.kind==='revision').length
  const isApproved = actions.findIndex(i => i.kind==="approved") !== -1
  const hasWon = actions.findIndex(i => i.kind==="won") !== -1
  const isFinished = sectionStatus == 8;
  const history = useHistory()
  // write, vote, approved, grade, won, revision, comments

  const handleClick = () => {
    if(viewAll) {
      handleSelect(idx)
      closeModal()
    } else {
      // localStorage.setItem('sectionId', sectionId);
      history.push(`/write?activityId=${activityId}&s=${idx}`)
    }
  }

  return (
    <div onClick={handleClick} className={`${styles.sectionRow} ${synod && styles.synod} ${isFinished && styles.finished}`}>
      <div className={styles.column}>
        <div className={`${styles.numberDiv} ${isFinished && styles.finished}`}> <p>{sectionIndex}</p> </div>
        <h5>{storyStart?"Story Start":sectionName}</h5>
      </div>
      {
        !synod &&
          <div className={styles.column}>
            {!storyStart && <Deadline endDate={sectionDue} fixedSize={true} />}
          </div>
      }
      <div className={styles.column}>
        <h5>{wordsCount}</h5>
      </div>

      {
        !synod &&
          <div className={styles.column}>
            {finalGrade ?
                <p className={styles.grade}>{finalGrade}</p>
              :
                <p>-</p>
            }
          </div>

      }
      <div className={styles.column}>

        {/* message count icon */}
        {msgCount>0 && 
          <div className={styles.messages}>
            <img src={bubble} alt="speech bubble" />
            <div className={styles.count}><p>{msgCount}</p></div>
          </div>
        }
        
        {/* winning trophy icon */}
        {hasWon && <img className={styles.icon} src={trophy} alt="trophy" /> }


        {/* phase/revision pill */}
        <SectionPhasePill 
          isApproved={isApproved}
          wordsCount={wordsCount}
          sectionStatus={sectionStatus} 
          isStoryStart = {storyStart}
          actions={actions} />
        
    
        
        
      </div>
    </div>
  )

}

export default SectionRow;