import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './storeConfig'
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor } from 'storeConfig'
import App from './App';

ReactDOM.render(
  <Provider store={store}>
    <div className="appWrapper">
      <BrowserRouter>
        {/* <PersistGate loading={null} persistor={persistor}> */}
          <App />
        {/* </PersistGate> */}
      </BrowserRouter>
    </div>
  </Provider>,
  document.getElementById('root')
);