import React, { useRef } from 'react'

import styles from './EntrySubmitBtn.module.scss'
import party from 'Assets/Icons/party_horn.svg'
import SubmitEntryModal from '../Modals/SubmitEntryModal/SubmitEntryModal'

const EntrySubmitBtn = ({
  activeSection:{sectionStatus, sectionIndex, storyStart, submitted },
  activeSection,
  disabled,
  toggleModal,
  updateSectionKey,
  activityId,
  inputHTML,
  entryApproved,
  mobile,
  synod }) => {

  
  const btnRef = useRef()
  
  const buttonClass = submitted ? 'submit' : 'resubmit'

  const buttonText = submitted ? "Re-submit" :
    !mobile ? 'Submit and earn' : 'Submit'

  const resBttonText = 
    [6].indexOf(sectionStatus) !== -1 || submitted ? 'Re-submit' : "Submit"

  const toggleBtnZindex = (action) => btnRef.current.classList[action](styles.onTopOfModal)

  const handleSubmit = (e) => {
    if(!mobile){toggleBtnZindex('add')}
    toggleModal(true, 
      <SubmitEntryModal
        synod={synod}
        inputHTML={inputHTML}
        sectionIndex={sectionIndex}
        activeSection={activeSection}
        staticPhase={activeSection.sectionStatus}
        activityId={activityId}
        toggleBtnZindex={toggleBtnZindex}
        updateSectionKey={updateSectionKey} />
      )
  }


  return storyStart ? null : (

    <div ref={btnRef} className={styles.container}>
      {
        !entryApproved ?
          <div onClick={handleSubmit} className={`${styles.button} ${styles[buttonClass]} ${mobile&&styles.mobile} ${disabled && styles.disabled}`}>
            { ([0,1,2].indexOf(sectionStatus) !== -1 && !submitted && !mobile) &&
              <div className={`${styles.tooltip}`}>
                You will earn 2 coins for a submission and 10 more when your entry is approved
              </div>
            }
            <h5 className={styles.hideResText}>{buttonText}</h5>
            <h5 className={styles.showRes}>{resBttonText}</h5>
          </div>
          :
          <div className={`${styles.approvedDiv} ${mobile&&styles.mobile}`}>
            <p>Entry Approved!</p>
            <img className={styles.horn} src={party} alt="party" />
          </div>
      }
    </div>

  )
}

export default EntrySubmitBtn;
