import React, { useEffect, useRef, useState } from 'react';
import ActivityRadio from '../ActivityRadio/ActivityRadio';
import SectionList from '../SectionList/SectionList';
import face from 'Assets/Avatars/face_shocked.svg'

import styles from './ListView.module.scss';
import ListViewModal from './ListViewModal/ListViewModal';
import { Link } from 'react-router-dom';

const ListView = ({ activities, isEmpty, toggleModal }) => {
  
  
  //if arriving to this page by clicking on the dropdown activity items from the dash class element, the
  //section index will be in LS. We take this index and set is as the default selectedActivity upon load.
  const defaultIdx = Number(localStorage.getItem('sectionIdx'))
  const [ selectedActivity, setSelectedActivity ] = useState(activities[ defaultIdx&&defaultIdx!==undefined ? defaultIdx : 0 ]) //no idx in LS? select idx 0. 
  
  const [ showArrows, toggleArrows ] = useState(false)
  const contRef = useRef()
  const scrollRef = useRef()

  const toggleActivity=(idx)=>setSelectedActivity(activities[idx])

  const handleScroll = (offset) => {
    scrollRef.current.scrollBy({left:offset, behavior:"smooth"})
  }

  useEffect(()=>{

    setTimeout(()=>{
      contRef.current?.classList.remove(styles.hide)
    },10)

    setTimeout(()=>{
      localStorage.removeItem('sectionIdx')

    },3000)

  },[])

  useEffect(()=>{
    if(scrollRef.current){
      toggleArrows(scrollRef.current.scrollWidth > scrollRef.current.clientWidth)
    }
  },[scrollRef])

  const handleModal = () => {
    toggleModal(true, 
      <ListViewModal 
        selectedActivity={selectedActivity}
        activities={activities} 
        toggleActivity={toggleActivity} />
    )
  }


  return (
    <div ref={contRef} className={`${styles.listView} ${styles.hide} ${isEmpty&&styles.centered}`}>
      {

        isEmpty ?
          <div className={styles.noActivities}>
            <img src={face} alt="search boomer" />
            <h3>Oops, it seems there's no activities to show yet</h3>
            <Link to={"/"} className={styles.goBackBtn}>Go back to dashboard</Link>
          </div>


          :
          <>
            <h4 className={styles.titleRes}>Activities</h4>
            <div id={"outer"} className={styles.radiosSlider}>

              <div id={"inner"} ref={scrollRef} className={styles.inner}>


                {
                  showArrows &&
                  <>
                    <div onClick={() => handleScroll(-300)} className={styles.arrowLeft} />
                    <div onClick={() => handleScroll(300)} className={styles.arrowRight} />
                  </>
                }

                <div onClick={handleModal} className={styles.responsiveDots} />

                {activities.map((activity, idx) =>
                  <ActivityRadio
                    defaultChecked={defaultIdx == idx}
                    defaultIdx={defaultIdx}
                    key={idx}
                    toggleActivity={toggleActivity}
                    selectedActivity={selectedActivity}
                    activity={activity}
                    idx={idx} />
                )}

                {/* {activities.map((activity, idx) => 
            <ActivityRadio
              key={idx}
              toggleActivity={toggleActivity}
              activity={activity}
              idx={idx}/>
          )}

          {activities.map((activity, idx) => 
            <ActivityRadio
              key={idx}
              toggleActivity={toggleActivity}
              activity={activity}
              idx={idx}/>
          )} */}


              </div>


            </div>
            <div className={styles.list}>
              <SectionList activityId={selectedActivity?.activityId} sections={selectedActivity?.sections} />
            </div>
          </>
      }

    </div>
  )

}

export default ListView;