import React, { useRef, useState, useEffect } from 'react';
import DOMPurify from 'dompurify';

import styles from './BreakoutRooms.module.scss';
import closeCross from 'Assets/Icons/close_cross.svg'
import refresh from 'Assets/Icons/refresh.svg'
import { getFromApi } from 'API/API.service';
import loader from 'Assets/Loaders/spinner_teal.svg'

const BreakoutRooms = ({ breakoutRooms, mobile, closeMobile, activity, setActivity, activeSection, setActiveSection }) => {

  const { activityId, sections } = activity
  const { sectionIndex } = activeSection
  const contRef = useRef()
  const entryRef = useRef()
  const [ selectedRoom, setSelectedRoom ] = useState(breakoutRooms[0])
  const [ refreshing, setRefreshing ] = useState(false)

  const refreshBreakoutRoom = async () => {
    setRefreshing(true)
    const newData = await getFromApi(`activity/${activityId}/${sectionIndex}/room`)
    const newSections = [
      ...sections.slice(0, sectionIndex-1),
      {...activeSection, breakoutRooms:[...newData]},
      ...sections.slice(sectionIndex)
    ]
    console.log(newData)
    setActivity({...activity, sections:newSections})
    setActiveSection({...activeSection, breakoutRooms:[...newData]})
    setRefreshing(false)
    setSelectedRoom(newData[newData.findIndex(i => i.studentName===selectedRoom.studentName)])
  }

  useEffect(()=>{
    contRef.current?.classList.remove(styles.display)
    setTimeout(()=>{ contRef.current?.classList.add(styles.display) },100)
    setSelectedRoom(breakoutRooms[0])
  },[breakoutRooms])

  useEffect(()=>{
    entryRef.current?.classList.remove(styles.display)
    setTimeout(()=>{ entryRef.current?.classList.add(styles.display) },100)
  },[selectedRoom])

  return (
    <div ref={contRef} className={`${styles.container} ${mobile&&styles.mobile}`}>
      
      <div className={`${styles.radios} ${breakoutRooms.length>1 && styles.fullWidth}`} onChange={e=>setSelectedRoom(breakoutRooms[parseInt(e.target.value)])}>
        {
          breakoutRooms.map((i,idx)=> 
            <label key={idx}>
              <input defaultChecked={idx===0} type="radio" name="room" value={idx} />
              <div className={styles.radioOption}>
                <div className={`${styles.boomerCircle} ${styles.two}`}>
                  <img src={i.boomerUrl} alt="boomer" />
                </div>
                <h5>{i.studentName.split(' ')[0]}'s entry</h5>
              </div>
            </label>
          )
        }
        <div onClick={refreshBreakoutRoom} className={`${styles.refreshBtn} ${breakoutRooms.length>1 && styles.fullWidth}`}>
          <img src={refresh} alt="refresh"/>
          <p>Refresh</p>
        </div>
      </div>
        {
          refreshing ? 

          <div className={`${styles.loaderDiv}`}>
              <img src={loader} alt="loader" />
          </div>
        :
          <div
            ref={entryRef}
            className={`${styles.entryText} ${styles.display}`}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedRoom.entryHTML) }} /> 
        }

    </div>
  )

}

export default BreakoutRooms;