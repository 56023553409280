import React, { useEffect, useRef } from 'react';

import styles from './Items.module.scss';
import coins from 'Assets/Icons/coins.svg'
import hanger from 'Assets/Icons/hanger.svg'

const ItemRadio = ({ idx, count, item, item:{url, id, type, price }, itemName, clickHandler, selected, returnFileName, localConfig, tile, noPrice, noClick, ownedAvatarItems, view, variantSelector }) => {

  const contRef = useRef()
  const isOwned = ownedAvatarItems?.map(i=>i.id).includes(id)
  const currentVariant = type==='body' ? localConfig.avatarBody : localConfig.items.find(i => i.type === type)

  //isChecked value varies if component is displaying an item or the coloured variants of an item
  const isChecked = variantSelector ? 
    [id].indexOf(parseInt(currentVariant?.id))!==-1 &&idx+1===parseInt(currentVariant?.tile) : [id].indexOf(parseInt(currentVariant?.id))!==-1
  

  const containerClasses = `
    ${styles.itemDiv} 
    ${styles.doNotDisplay} 
    ${noPrice&&styles.noPrice} 
    ${isOwned&&styles.noPrice} 
    ${selected===idx&&styles.selected}
  `
  const labelClasses = `
    ${noClick&&styles.disable}
    ${view==='store'&&isOwned&&styles.disable}
  `

  useEffect(()=>{

    const offset = Math.floor(Math.random() * count) * 10

      setTimeout(()=>{
        contRef.current?.classList.remove(styles.doNotDisplay)
      },offset)
    
  },[])

  return (
      <label className={labelClasses} key={idx}>

        <input checked={isChecked}  type="radio" onChange={(()=>null)} onClick={clickHandler}  name={itemName} value={idx}/>

        <div ref={contRef} className={containerClasses}>

          {isOwned && view==="store" && <img className={styles.owned} src={hanger} alt="hanger" /> }

          <img className={styles.itemImg}  src={`/avatarItems/${returnFileName(url[!tile?0:tile-1])}`}  alt={returnFileName(url[!tile?0:tile-1])} />
          
          {
            !noPrice&&!isOwned &&
              <div className={styles.price}>
                <img src={coins} alt="coins" />
                <h5>{price}</h5>
              </div>
          }

        </div>

      </label>
  )

}

export default ItemRadio;