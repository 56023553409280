import React, { useState, useEffect } from 'react';
import { sendToApi } from 'API/API.service';
import CountUp from 'react-countup';
import _ from "lodash"

import styles from './Canvas.module.scss';
import coins from 'Assets/Icons/coins.svg'
import loader from 'Assets/Loaders/spinner_white_fast.svg'
import store from 'storeConfig';
import { updateBoomerBase64 } from 'Actions/dashDataActions';

const Canvas = ({ canvasRef, base64Canvas, storeData, view, localConfig, comparisonRef, updateData, fallback }) => {

  const [pendingChanges, setPendingChanges ] = useState(false)
  const [ saving, toggleSaving ] = useState(false)

  useEffect(()=>{
    if(localConfig&&comparisonRef){
      const bool =  _.isEqual(localConfig, comparisonRef)
      setPendingChanges(!bool)
    }
  },[localConfig])

  const handleSave = async () => {

    toggleSaving(true)
    const payload ={
      base64: base64Canvas,
      avatarJsons:{
        body: {body_id: localConfig.avatarBody.id},
        items:localConfig.items
      }
    }
    
    const {avatarJsons} = await sendToApi(`save_avatar`, payload)
    console.log(avatarJsons)
    updateData(avatarJsons)
    store.dispatch(updateBoomerBase64(base64Canvas))
    toggleSaving(false)
  }

  return (

      <div className={styles.canvasCont}>

      <canvas className={styles.hiddenCanvas} ref={canvasRef} />
      
      {
        base64Canvas === "loading" ?
          <img className={styles.loader} src={loader} alt="loader" />
        :
          <img className={`${styles.rendered} ${saving&&styles.saving}`} src={`data:image/png;base64, ${base64Canvas}`} alt="boomer" />

      }

      <div className={styles.oval}/>
      <div className={styles.circle}/>

        {
          // show user credit when on customisation screen
          view==="boomer" &&
            <div className={`${styles.bucks} ${saving&&styles.saving}`}>
              <h2>You have</h2>
              <img className={styles.coins} src={coins} alt="coins" />
              <CountUp duration={1.5} start={storeData.boomerBucks-20}  end={storeData.boomerBucks}  />
              <h2>to spend</h2>
            </div>
        }

        { 
          // show save button if pending changes
          pendingChanges && !fallback && 
            <div onClick={handleSave} className={`${styles.saveBtn} ${saving&&styles.saving} ${!saving && "pulse"}`}>{!saving?<>Save <span>changes</span></>:"Saving..."}</div>
        }

        { saving &&
          <img className={styles.savingLoader} src={loader} alt="loader" />
        }

    </div>

  )

}

export default Canvas;