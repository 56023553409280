import React from 'react';

import styles from './WinningEntry.module.scss';
import closeIcon from 'Assets/Icons/close_square.svg'
import trophy from 'Assets/Icons/trophy_yellow.svg'
import ReactQuill from 'react-quill';

const WinningEntryModal = ({ closeModal, winnerData: { winnerHTML, winnerAvatarUrl, winnerName = "Baloo Clydesdale"} }) => {

  return (
    <div className={styles.winningEntryModal}>
      <img className={styles.close} onClick={closeModal} src={closeIcon} alt="close" />
      <div className={styles.topBar}>
        <div className={styles.avatarDiv}>
          <img src={winnerAvatarUrl} alt="avatar" />
        </div>
        <div className={styles.text}>
          <h2>{winnerName}</h2>
          <div className={styles.trophyDiv}>
            <img src={trophy} alt="trophy" />
            <h5>winning entry</h5>
          </div>
        </div>
      </div>
      <div className={styles.editorDiv}>
        <ReactQuill
          readOnly
          modules={{ "toolbar": false }}
          value={winnerHTML} />
      </div>

    </div>
  )

}

export default WinningEntryModal;