import React from 'react';

import styles from './SectionList.module.scss';
import SectionRow from './SectionRow';

const SectionList = ({ synod, closeModal, sections, modal, activityId, viewAll, handleSelect }) => {

  return (
    <div className={styles.sectionList}>
      <div className={`${styles.header} ${synod && styles.synod}`}>
        <div className={styles.column}>
          <h5>Info</h5>
        </div>
        {
          !synod &&
            <div className={styles.column}>
              <h5>Date</h5>
            </div>
        }
        <div className={styles.column}>
          <h5>Words</h5>
        </div>
        {
          !synod &&
            <div className={styles.column}>
              <h5>Grade</h5>
            </div>
        }
        <div className={styles.column}>
          <h5>Actions</h5>
        </div>
      </div>

      <div className={`${styles.rowsDiv} ${!modal&&styles.main} ${modal&&styles.modal}`}>
        {
          sections?.map((section,idx)=> 
            [1,21].indexOf(section.sectionPhase)===-1&&
              <SectionRow synod={synod} closeModal={closeModal} handleSelect={handleSelect} viewAll={viewAll} activityId={activityId} key={idx} idx={idx} section={section} />
          )
        }
      </div>

    </div>
  )

}

export default SectionList;