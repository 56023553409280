import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import close from 'Assets/Icons/close_square.svg'
import styles from './VotedModal.module.scss';
import boomer from 'Assets/Boomers/boomer_girl_pink.svg'
import coins from 'Assets/Icons/coins.svg'
import icon from 'Assets/Icons/voted_blue.png'

const VotedModal = ({ mobile, toggleConf, activeSection, hideVotingComp }) => {

  const contRef= useRef()

  useEffect(()=>{
    if(contRef.current){
      contRef.current.classList.add(styles.display)
    }
    // eslint-disable-next-line
  },[])
  const handleClose = () => {
    if(!activeSection.votingData.entries.length){
      hideVotingComp()
    }
    toggleConf(false)
  }

  return (

    <div ref={contRef} className={`${styles.container} ${mobile&&styles.mobile}`}>

    <img
      onClick={()=>handleClose()}
      className={styles.close}
      src={close}
      alt="close" />

      <div className={styles.images}>
        {/* <img className={styles.boomer} src={boomer} alt="boomer" /> */}
        <img className={styles.icon} src={icon} alt="icon" />
      </div>

      <h2>Thank you for voting!</h2>

      <div className={styles.coinsMessage}>
        <h5>You earned</h5>
        <img src={coins} alt="coins" />
        <h5>2!</h5>
      </div>

      <div className={`${styles.buttons}`}>
        <Link to={'/profile'} className={styles.btnOne}> Hit the store </Link>
        {
          activeSection.votingData.entries.length ?
            <div onClick={()=>toggleConf(false)} className={styles.btnTwo}> Vote again </div>
          : null
        }
      </div>

    </div>

  )

}

export default VotedModal;