import React from 'react';

import styles from './ProfileSidebar.module.scss';
import avatarImg from 'Assets/Icons/profileAvatar.svg'
import settingsImg from 'Assets/Icons/profileSettings.svg'

const ProfileSidebar = ({ view, toggleView }) => {

  return (
    <div className={styles.profileSidebar}>

      <label>
        <input onChange={()=>toggleView('boomer')} checked={view==='boomer'} type="radio" name="viewRadio" />
        <div className={styles.viewRadio}>
          <img src={avatarImg} alt="boomer"/>
        </div>
      </label>

      <label>
        <input onChange={()=>toggleView('settings')} checked={view==='settings'} type="radio" name="viewRadio" />
        <div className={styles.viewRadio}>
          <img src={settingsImg} alt="settings"/>
        </div>
      </label>

    </div>
  )

}

export default ProfileSidebar;