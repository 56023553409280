const initialState = {postPurchase:false};

const dashData = (state={...initialState}, action) => {

  const { type, payload } = action
  // const { type, payload } = action

  switch(type){

    case 'ADD_DASH_DATA': 
      return { ...payload }

    case 'UPDATE_DASH_KEY': 
      return { ...state, [payload.key]:payload.value }

    case 'UPDATE_BOOMER_BASE64':
      return { ...state, base64: payload}

    case 'UPDATE_BOOMER_BUCKS':
      return { ...state, boomerBucks: state.boomerBucks+payload}

    case 'UPDATE_POST_PURCHASE':
      return {...state, postPurchase: payload}
      
    default:
      return state
  }
}

export default dashData;