import React, { useEffect, useRef } from 'react'
import { format } from 'date-fns'

import styles from './CommentElement.module.scss'
import warning from 'Assets/Icons/warning_red.svg'
import tick from 'Assets/Icons/tick_green_square.svg'

const localStorageUser = JSON.parse(localStorage.getItem('user'))

const CommentElement = ({ idx, text, status, createdOn, comments, noAnim, teacherName, isStudent }) => {

  const contRef = useRef()

  const factor = idx+1
  const offsetPixels = factor * 100
  const delay = idx*100

  const statusClass = 
    status === 'revision' ? styles.revisionNonSubmitted : 
    status === 'submitted' ? styles.revisionSubmitted :
    status === 'final' ? styles.final : null

  useEffect(()=>{

    if(contRef.current && !noAnim){
      contRef.current.classList.remove(styles.move)
      setTimeout(()=>{
        contRef.current?.classList.add(styles.move)
      },delay)
    }

  },[contRef, comments, delay, noAnim])

  return (
    <div  
      id={`div${idx}`} ref={contRef} 
      className={`${styles.container} ${isStudent&&styles.studentComment} ${noAnim && styles.move}`} 
      style={!noAnim ? {transform:`translateY(-${offsetPixels}px)`}:null}>

      <div className={`${styles.commentBubble} ${statusClass}`}>
        <div className={styles.dateTimeDiv}>
          <p className={styles.dateTime}>{format(new Date(createdOn), 'do MMMM yyyy')}</p>
          <p className={styles.dateTime}>{format(new Date(createdOn), 'hh:mm aaa')}</p>
        </div>
        <p className={styles.bodyText}>{text}</p>
        <p className={styles.teacherName}>{ ! isStudent ? teacherName : localStorageUser ? localStorageUser.studentName : "Me"}</p>
      </div>
      {
        ['revision','submitted'].indexOf(status) !== -1 &&
          <img className={styles.icon} src={status==='revision'?warning:tick} alt="icon" />
      }
      
    </div>
  )

}

export default CommentElement;