import React from 'react';

import styles from './ClosedButtons.module.scss';

import instructions from 'Assets/Icons/instructions.svg'
import instructions_hover from 'Assets/Icons/instructions_hover.svg'
import instructions_white from 'Assets/Icons/instructions_white.svg'

import grammar from 'Assets/Icons/grammar.svg'
import grammar_hover from 'Assets/Icons/grammar_hover.svg'
import grammar_white from 'Assets/Icons/grammar_white_blue.svg'

import comment from 'Assets/Icons/comment.svg'
import comment_hover from 'Assets/Icons/comment_hover.svg'
import comment_white from 'Assets/Icons/comment_white.svg'


const ClosedButtons = ({ open, radVal, toggleSidebar, setRadVal, activeSection:{ breakoutRooms, grammarReport } }) => {

  const handleChange = (input) => {
    if(radVal!==input) setRadVal(input)
    toggleSidebar(true)
  }

  const print = () => {console.log(radVal)}

  return (
    <div className={`${styles.closedButtons} ${!open && styles.display}`}>
    <label>
      <input checked={radVal==="instructions"} onChange={print} type="radio" name="sidebarOptionClosed" value={"instructions"}/>
      <div onClick={()=>handleChange("instructions")} className={styles.sidebarOption}>
        <div className={styles.icon}>
          <img className={styles.init} src={instructions} alt="instructions" />
          <img className={styles.hover} src={instructions_hover} alt="instructions" />
          <img className={styles.selected} src={instructions_white} alt="instructions" />
        </div>
        <h5>Instructions</h5>
      </div>
    </label>

    {
      grammarReport?.report?.length ?
        <label>
          <input checked={radVal==="grammar"} onChange={print} type="radio" name="sidebarOptionClosed" value={"grammar"}/>
          <div onClick={()=>handleChange("grammar")} className={styles.sidebarOption}>
            <div className={styles.icon}>
              <img className={styles.init} src={grammar} alt="grammar" />
              <img className={styles.hover} src={grammar_hover} alt="grammar" />
              <img className={styles.selected} src={grammar_white} alt="grammar" />
            </div>
            <h5>Grammar Bot</h5>
          </div>
        </label>

      : null
    }

    <label>
      <input checked={radVal==="comments"} onChange={print} type="radio" name="sidebarOptionClosed" value={"comments"}/>
      <div onClick={()=>handleChange("comments")} className={styles.sidebarOption}>
        <div className={styles.icon}>
          <img className={styles.init} src={comment} alt="comments" />
          <img className={styles.hover} src={comment_hover} alt="comments" />
          <img className={styles.selected} src={comment_white} alt="comments" />
        </div>
        <h5>Teacher comments</h5>
      </div>
    </label>

      {
        !breakoutRooms?.length ? null :

          <label>
            <input checked={radVal === "friend"} onChange={print} type="radio" name="sidebarOptionClosed" value={"friend"} />
            <div onClick={() => handleChange("friend")} className={styles.sidebarOption}>
              <div className={styles.icon}>

                <div className={`${styles.boomerCircle} ${breakoutRooms.length === 2 && styles.one}`}>
                  <img src={breakoutRooms[0].boomerUrl} alt="boomer" />
                </div>

                {
                  breakoutRooms.length === 2 &&
                  <div className={`${styles.boomerCircle} ${styles.two}`}>
                    <img src={breakoutRooms[1].boomerUrl} alt="boomer" />
                  </div>
                }

              </div>
              <h5>Friend's Entry</h5>
            </div>
          </label>
          
      }

  </div>
   

  )

}

export default ClosedButtons;