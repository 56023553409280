import React from 'react';

import styles from './WinningEntry.module.scss';
import trophy from 'Assets/Icons/trophy_yellow.svg'
import WinningEntryModal from './WinningEntryModal';

const WinningEntryBtn = ({ toggleModal, winnerData }) => {

  const handleModal = () => {
    toggleModal(true, <WinningEntryModal winnerData={winnerData} />)
  }

  return (
    <div onClick={handleModal} className={styles.winningEntryBtn}>
      <img src={trophy} alt="trophy" />
      <h5>Read <span>winning entry</span> </h5>
    </div>
  )

}

export default WinningEntryBtn;