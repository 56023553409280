
export const setGrammarFeedback = (inputString, infoArray, param) => {

  if(!infoArray.filter(i => i.type === param).length){
    
    return `<p>${inputString}</p>`

  } else {
    let outputArr = [];
    const filteredArray = []
    
    infoArray.filter(i => i.type === param).forEach((item, index) =>{
      if(index === 0 || item.startPos > infoArray.filter(i => i.type === param)[index-1].endPos){  //exclude overlapping items
        filteredArray.push(item)
        console.log(item)
      }
    });
  
    console.log(filteredArray)
    
    filteredArray.forEach((item, index) => {
  
      // if(index === 0 || item.startPos > filteredArray[index-1].endPos){ 
    
            let part1;
            let part2;
        
            const subStr = inputString.slice(item.startPos, item.endPos+1);
            const modArr = [];
            if(item.suggestions.length){item.suggestions.forEach(i =>{
              const modItem = `'${i}'`;
              modArr.push(modItem)
              })
            }
            const suggStr = modArr.length ? modArr.join(', ') : null;
            
            const newSubStr = 
            item.type === 'grammar'?
            `<span class="feedback auto_grammar">${subStr} <span class='tooltipWrapper'><span class='tooltip'>${item.hint} ${item.suggestions.length ? `<span class="sugg_grammar"><span class="suggHead">suggestions:</span>${suggStr}</span>` : ''}</span> </span></span>`
              
            :  item.type === 'consistency'?
            `<span class="feedback auto_consistency">${subStr} <span class='tooltipWrapper'><span class='tooltip'>${item.hint} ${item.suggestions.length ? `<span class="sugg_consistency"><span class="suggHead">suggestions:</span>${suggStr}</span>` : ''}</span> </span></span>`
      
            :  item.type === 'overused'?
            `<span class="feedback auto_overused">${subStr} <span class='tooltipWrapper'><span class='tooltip'>${item.hint} ${item.suggestions.length ? `<span class="sugg_overused"><span class="suggHead">suggestions:</span>${suggStr}</span>` : ''}</span> </span></span>`
              
            : item.type === 'passive'?
              `<span class="feedback auto_passive">${subStr} <span class='tooltipWrapper'><span class='tooltip'>${item.hint} ${item.suggestions.length ? `<span class="sugg_passive"><span class="suggHead">suggestions:</span>${suggStr}</span>` : ''}</span> </span></span>`
      
            :    item.type === 'initial'?
            `<span class="feedback auto_initial">${subStr} <span class='tooltipWrapper'><span class='tooltip'>${item.hint} ${item.suggestions.length ? `<span class="sugg_initial"><span class="suggHead">suggestions:</span>${suggStr}</span>` : ''}</span> </span></span>`
      
            :
              null
            ;
        
            if(index === 0){
              part1 = inputString.slice(0, item.startPos);
  
            } else {
              if(!item.startPos > filteredArray[index-1].endPos){
                //adjust starting index to account for ignored overlapping items
                part1 = inputString.slice(filteredArray[index-2].endPos+1, item.startPos);
  
              } else {
                part1 = inputString.slice(filteredArray[index-1].endPos+1, item.startPos);
  
              }
            }
        
            if(index === filteredArray.length-1){
              part2 = inputString.slice(item.endPos+1)
            } 
            
            outputArr.push(part1)
            outputArr.push(newSubStr)
            if(part2){outputArr.push(part2)}
        
        // } else { return null }
      
      }
    )
  
    return `<p style=position:relative;padding-bottom:30px >${outputArr.join('')}</p>`
  }

  
}


export const wordbankUsed = (wordbank, entry, string) => {

  const entArr = entry.toLowerCase().replace(/[^\w\s]|_/g, "").split(' ');
  const usedArr = [];

  wordbank.forEach(i => {
    if(entArr.includes(i.toLowerCase())){usedArr.push(i.toLowerCase())}
  })

  return string ? `${usedArr.length}/${wordbank.length}` : usedArr

}


export const wordbankUnused = (wordbank, entry) => {

  const entArr = entry.toLowerCase().replace(/[^\w\s]|_/g, "").split(' ');
  const unusedArr = [];

  wordbank.forEach(i => {
    if(!entArr.includes(i.toLowerCase())){unusedArr.push(i.toLowerCase())}
  })

  return unusedArr

}


